/*
* 업무구분: 신탁>고객상품>고객정보등록>개인고객등록
* 화 면 명: MSPTS100M
* 화면설명: 개인고객등록
* 접근권한: 
* 작 성 일: 2023.02.09
* 작 성 자: 지재호
* 파 일 명: TFCP10000
* ※ 기존 넥사크로 소스를 Vue화면으로 컨버팅. 기존 변수, dataSet , 함수 명은 가능하면 동일하게 사용함
* ※ 기존 로직의 흐름을 가능하면 사용하고 Vue에 맞지 않는 부분만 Vue 스타일로 변경하여 개발 진행함. 
* 수정일 : 2024-12-17 정수진  사유 : ASR241100895 / 개인고객등록화면 권유자/관리자/권유FC 입력 시 오류 팝업내용 수정 및 데이터 매핑 수정 
* 수정일 : 2025-03-18 정수진  사유 : ASR250300136 / 전자서식 호출 및 세팅시기 변경요청 
* 수정일 : 2025-04-08 정수진  사유 : ASR250301055 / 고객등록화면 알림톡발송기능 정상화 
* 수정일 : 2025-04-22 정수진  사유 : ASR250300203 / 신탁 마케팅 동의서 개정 사항 반영

해당 id 들은 사용하지 않음
chk_homeAddr, chk_homeTel, chk_homeFax, chk_homeCel, chk_offcAddr, chk_offcTel, chk_homeEmail, chk_etcAddr
*/
<template> 
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label> 실명번호 종류 </label>
              <mo-dropdown class="mw150" 
                :items="cmb_rcnoKindTc" 
                v-model="ds_search.rcnoKindTc" 
                :disabled="enabledList.cmb_rcnoKindTc==false"
                @input="fn_validErrRemoveRadio($event, 'cmb_rcnoKindTc')"
                ref="cmb_rcnoKindTc"
              />
            </div>
            <div class="wrap-input row">
              <label> 실명번호 </label>
              <mo-text-field 
                v-model="rcnoOne" 
                :disabled="enabledList.edt_rcno==false" 
                placeholder="앞 6자리"
                class="w80" 
                maxlength="6"
                type="number"
                clearable 
                @keyup="fn_SearchValid($event, 'edt_rcnoOne')" 
                @paste="fn_SearchValid($event, 'P')"
                ref="edt_rcnoOne"
              />
              <m-trans-key-input
                v-if="isMobile && isMtrans"
                class="w80"
                v-model="rcnoTwo"
                :disabled="enabledList.edt_rcno==false"  
                type="numberMax7"
                dialog="Y"
                start="1" 
                end="-1"
                :isClear="lv_isClear_rcnoTwo"
                @masked-txt="fn_SetMaskedTxtRcnoTwo"         
                ref="edt_rcnoTwo"
              />
              <mo-text-field
                v-else
                numeric 
                mask="#######"
                v-model="rcnoTwo"
                :disabled="enabledList.edt_rcno==false"  
                type="password" 
                placeholder="뒤 7자리" 
                class="w80"
                @keyup="fn_SearchValid($event, 'edt_rcnoTwo')" 
                ref="edt_rcnoTwo"
                clearable 
              />
            </div>

            <!-- 징구, 미징구 -->
            <div class="wrap-input row radio-check" v-show="lv_isProcess==true">
              <mo-radio-wrapper :items="list_rdo_sbmtYn" v-model="rdo_sbmtYn" disabled class="row"/>
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button primary size="small" v-if="lv_serverType==='local'" @click="report_test()">전자서식테스트</mo-button>          
              <mo-button @click="fn_init()" :disabled="enabledList.btn_init==false"> 초기화 </mo-button>
              <mo-button primary @click="fn_searchList" :disabled="enabledList.btn_searchList==false"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>
              
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-button wrap-fatca">
          <!-- <mo-button disabled class="fatca"> FATCA/CRS확인 </mo-button> -->
        </div>
      </ur-box-container>
      
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row justify-between">
          <h2 class="table-title"> 기본정보 </h2>
          <div class="wrap-input row" v-show="visibleList.cmb_modifyGb==true">
            <label> 정정구분 </label>
            <mo-dropdown class="mw150" 
              :items="list_cmb_modifyGb" 
              v-model="cmb_modifyGb"
              :disabled="enabledList.cmb_modifyGb==false"
              @input="fn_validErrRemoveRadio($event, 'cmb_modifyGb')"
              ref="cmb_modifyGb"
              placeholder="선택하세요" 
            />
          </div>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 고객명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" 
                      v-model="ds_detail.cnm" 
                      :disabled="enabledList.edt_cnm==false" 
                      ref="edt_cnm"
                    />
                  </div>
                </td>
                <th>
                  <span> 고객영문명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long"
                    v-model="ds_detail.csEnnm" 
                    :disabled="enabledList.edt_csEnnm==false" 
                    ref="edt_csEnnm"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 국적 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="cmb_natnNatC" 
                      v-model="ds_detail.natnNatC" 
                      :disabled="enabledList.cmb_natnNatC==false" 
                      @input="fn_validErrRemoveRadio($event, 'cmb_natnNatC')"
                      placeholder=" "
                      ref="cmb_natnNatC"
                   />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 거주국가 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :items="cmb_rsdnNatC" 
                      v-model="ds_detail.rsdnNatC" 
                      :disabled="enabledList.cmb_rsdnNatC==false" 
                      @input="fn_validErrRemoveRadio($event, 'cmb_rsdnNatC')"
                      placeholder=" "
                      ref="cmb_rsdnNatC"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 소득자구분 </span>
                </th>
                <td>
                  <mo-dropdown :items="cmb_incMnTc" 
                    v-model="ds_detail.incMnTc" 
                    :disabled="enabledList.cmb_incMnTc==false"
                    @click="fn_validErrRemoveRadio($event)"
                    @input="fn_validErrRemoveRadio($event, 'cmb_incMnTc')"
                    ref="cmb_incMnTc" 
                  />
                </td>
                <th>
                  <span class="emphasis"> 생계형구분 </span>
                </th>
                <td>
                  <mo-dropdown :items="cmb_lvlhTc" 
                    v-model="ds_detail.lvlhTc" 
                    :disabled="enabledList.cmb_lvlhTc==false" 
                    @input="fn_validErrRemoveRadio($event, 'cmb_lvlhTc')"
                    ref="cmb_lvlhTc" 
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 거주자여부 </span>
                </th>
                <td>
                  <mo-radio-wrapper :items="list_rdo_rsdnYn" 
                    v-model="ds_detail.rsdnYn" 
                    :disabled="enabledList.rdo_rsdnYn==false"  
                    ref="rdo_rsdnYn" class="row"
                  />
                </td>
                <th>
                  <span class="emphasis"> 성별 </span>
                </th>
                <td>
                  <mo-radio-wrapper :items="list_rdo_sexTc" 
                    v-model="ds_detail.sexTc" 
                    :disabled="enabledList.rdo_sexTc==false"
                    @input="fn_validErrRemoveRadio($event, 'rdo_sexTc')"
                    ref="rdo_sexTc" 
                    class="row"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 주연락처 </span>
                </th>
                <td>
                  <!-- rdo_mnCntcPlcTc -->
                  <mo-radio-wrapper 
                    :items="list_rdo_mnCntcPlcTc" 
                    v-model="ds_detail.mnCntcPlcTc" 
                    :disabled="enabledList.rdo_mnCntcPlcTc==false"
                    ref="rdo_mnCntcPlcTc" 
                    class="row"
                  />
                </td>
                <th>
                  <span> 생일 </span>
                </th>
                <td>
                  
                  <div class="wrap-input row">
                    <div class="picker row">
                      <mo-date-picker v-model="cal_btdt" 
                        :disabled="enabledList.rdo_dateTc==false"
                        :bottom="false" 
                      />
                    </div>
                    <div class="radio row">
                      <mo-radio-wrapper :items="list_rdo_dateTc" 
                      :disabled="enabledList.rdo_dateTc==false"
                      v-model="ds_detail.dateTc" 
                      ref="rdo_dateTc" 
                      class="row"
                    />
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-show="!(lv_isProcess==false && gNewMemberYn=='Y')"> <!-- 개별메뉴로 접속>조회 후 신규 고객일경우 해당 항목 비노출 -->
                <th>
                  <span class="emphasis"> 대리인여부 </span>
                </th>
                <td>
                  <mo-radio-wrapper 
                    :items="representativeType" 
                    v-model="isAgnt" 
                    :disabled="enabledList.rdo_representative==false"
                  />
                </td>
                <th>
                  <span class="emphasis"> 대리인 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="ds_detail01.agntCnm" placeholder=" " class="input-long" disabled />
                    <mo-button class="btn-representative" 
                      @click="fn_OpenPopup(10)" 
                      :disabled="enabledList.edt_agntNm==false||isAgnt=='N'||isAgnt==''" 
                    > 대리인 </mo-button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 신용동의 여부 계약체결 </span>
                </th>
                <td>
                  <mo-radio-wrapper :items="creditAgree"
                    :disabled="enabledList.rdo_cdinProfrCnsntYn==false" 
                    v-model="ds_detail.cdinProfrCnsntYn"
                    ref="rdo_cdinProfrCnsntYn" 
                  />
                </td>
                <th>
                  <span class="emphasis"> 마케팅 </span>
                </th>
                <td>
                  <mo-radio-wrapper 
                    :items="rdo_marketingAgree" 
                    v-model="ds_detail.mktnCnsntYn" 
                    :disabled="enabledList.rdo_mktnCnsntYn==false"
                    @input="rdo_mktnCnsntYn_onitemclick"
                    ref="rdo_mktnCnsntYn"
                  />
                </td>
              </tr>
              <tr v-if="ds_detail.mktnCnsntYn=='Y'">
                <th>
                  <span> 연락방식 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input wrap-switch row">
                    <div class="row">
                      <span class="title-switch wS"> 전화 </span>
                      <mo-switch v-model="mktSwtcTel" class="switch-t1" disabled/>
                    </div>
                    <div class="row">
                      <span class="title-switch wS"> 문자 </span>
                      <mo-switch v-model="mktSwtcSs" class="switch-t1" disabled/>
                    </div>
                    <div class="row">
                      <span class="title-switch"> E-mail </span>
                      <mo-switch v-model="mktSwtcEMail" class="switch-t1" disabled/>
                    </div>
                    <div class="row">
                      <span class="title-switch wS"> 우편 </span>
                      <mo-switch v-model="mktSwtcMail" class="switch-t1" disabled/>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row flex-sb">
          <h2 class="table-title"> 연락처정보 </h2>
          <span class="colorR text-xs" v-if="visibleList.sta_notice1 == true">자택 필수 입력해야 합니다.</span>
          <span class="colorR text-xs" v-if="visibleList.sta_notice11 == true">자택 또는 직장 주소는 필수 입력해야 합니다.</span>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            
            <tbody>
              <tr class="rowspan3">
                <th rowspan="3">
                  <span :class="homeEmphasis"> 자택주소 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="input-long" 
                      v-model="ds_detail.hmadPsno"
                      ref="hmadPsno"
                      disabled 
                    />
                    <mo-button class="btn-zip" @click="fn_OpenPopup(0, 'HOME')" 
                    :disabled="enabledList.btn_popupTpup95100_1==false" > 우편번호 </mo-button>
                    <mo-checkbox v-show='visibleList.chk_homeAddr' v-model="chk_homeAddr" ref="chk_homeAddr" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
                <th>
                  <span> 두낫콜(연락거부) </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-checkbox v-model="chk_doNotCallTel" disabled class="row"> 전화 </mo-checkbox>
                    <mo-checkbox v-model="doNotCallSms" disabled class="row"> 문자 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field disabled class="input-long" v-model="ds_detail.homePsnoAddr" ref ="edt_homePsnoAddr" />
                    <mo-text-field disabled class="input-address02" v-model="ds_detail.homeAddrBldnNo"/>
                  </div>
                </td>
                <th>
                  <span :class="eMailEmphasis"> E-mail 주소 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" 
                      :disabled="enabledList.edt_emailAddr==false"
                      v-model="ds_detail.emailAddr" ref="edt_emailAddr" />
                    <span class="ico-between-email"> @ </span>
                    <mo-text-field class="input-long" 
                      :disabled="enabledList.edt_domnNm==false"
                      v-model="ds_detail.domnNm" ref="edt_domnNm" />
                    <mo-checkbox v-show='visibleList.chk_homeEmail' v-model="chk_homeEmail" ref="chk_homeEmail" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field disabled class="input-long" v-model="ds_detail.homeRmnAddr" ref="etd_homeRmnAddr"/>
                  </div>
                </td>
                <th>
                  <span> Fax 번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long"
                      v-model="ds_detail.offcFaxArno" 
                      :disabled="enabledList.edt_offcFax==false"
                      type="number" 
                      maxlength="4"
                     />
                    <mo-text-field class="input-long"
                      v-model="ds_detail.offcFaxPofn"  
                      :disabled="enabledList.edt_offcFax==false"
                      type="number" 
                      maxlength="4"
                    />
                    <mo-text-field class="input-long" 
                      v-model="ds_detail.offcFaxSeq" 
                      :disabled="enabledList.edt_offcFax==false"
                      type="number" 
                      maxlength="4"
                    />
                    <mo-checkbox v-show='visibleList.chk_homeFax' v-model="chk_homeFax" ref="chk_homeFax" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 자택전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" type="number" maxlength="4"
                      :disabled="enabledList.edt_homeTelArno==false" 
                      v-model="ds_detail.homeTelArno"
                      ref="edt_homeTelArno"
                    />
                    <mo-text-field class="input-long" type="number" maxlength="4"
                      :disabled="enabledList.edt_homeTelPofn==false" 
                      v-model="ds_detail.homeTelPofn" 
                      ref="edt_homeTelPofn"
                    />
                    <mo-text-field class="input-long" type="number" maxlength="4"
                      :disabled="enabledList.edt_homeTelSeq==false"
                      v-model="ds_detail.homeTelSeq" 
                      ref="edt_homeTelSeq"
                    />
                    <mo-checkbox v-show='visibleList.chk_homeTel' v-model="chk_homeTel" ref="chk_homeTel" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 휴대폰 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown 
                      :items="list_cmb_mvphBusiMnNo" 
                      v-model="ds_detail.mvphBusiMnNo" 
                      :disabled="enabledList.cmb_mvphBusiMnNo==false"
                      @input="fn_validErrRemoveRadio($event, 'cmb_mvphBusiMnNo')"
                      placeholder=" "
                      ref="cmb_mvphBusiMnNo"
                    />
                    <mo-text-field class="input-long" type="number" maxlength="4"
                      v-model="ds_detail.mvphPofn" 
                      :disabled="enabledList.edt_mvphPofn==false"
                      @keyup="fn_validErrRemoveInput($event, 'edt_mvphPofn')"
                      ref="edt_mvphPofn" 
                    />
                    <mo-text-field class="input-long" type="number" maxlength="4"
                      v-model="ds_detail.mvphSeq" 
                      :disabled="enabledList.edt_mvphSeq==false"
                      @keyup="fn_validErrRemoveInput($event, 'edt_mvphSeq')"
                      ref="edt_mvphSeq"
                    />
                    <mo-checkbox v-show='visibleList.chk_homeCel' v-model="chk_homeCel" ref="chk_homeCel" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span :class="etcAddrEmphasis"> 신탁별도주소 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-text-field class="input-long"
                      v-model="g_edt_etcAddrTotal"
                      ref="edt_etcAddrTotal"
                      disabled
                    />
                    <mo-button class="btn-input" 
                    :disabled="enabledList.btn_popupTfcp10001 == false"                    
                    @click="fn_OpenPopup(0, 'ETC')"
                    > 입력 </mo-button>
                    <mo-checkbox v-show='visibleList.chk_etcAddr' v-model="chk_etcAddr" ref="chk_etcAddr" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row flex-sb">
          <h2 class="table-title"> 직업정보 </h2>
          <span class="colorR text-xs" v-show="visibleList.sta_notice2 == true">직장 필수 입력해야 합니다.</span>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            
            <tbody>
              <tr>
                <th>
                  <span :class="jobEmphasis"> 직장명 </span>
                </th>
                <td>
                  <mo-text-field class="input-long" 
                    v-model="ds_detail.offcNm"
                    :disabled="enabledList.edt_offcNm==false"
                    @keyup="fn_validErrRemoveInput($event, 'edt_offcNm')"
                    ref="edt_offcNm" />
                </td>
                <th>
                  <span> 부서명 </span>
                </th>
                <td>
                  <mo-text-field class="input-long" 
                    v-model="ds_detail.dpnm"
                    :disabled="enabledList.edt_dpnm==false"
                    ref="edt_dpnm"
                  />
                </td>
              </tr>
              <tr class="rowspan3">
                <th rowspan="3">
                  <span :class="jobEmphasis"> 직장주소 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      disabled class="input-long" 
                      v-model="ds_detail.offcAddrPsno" 
                      ref="offcAddrPsno"
                    />
                    <mo-button class="btn-zip" @click="fn_OpenPopup(0, 'JOB')"
                     :disabled="enabledList.btn_popupTpup95100_2==false" > 우편번호 </mo-button>
                    <mo-checkbox v-show='visibleList.chk_offcAddr' v-model="chk_offcAddr" ref="chk_offcAddr" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 보험직업구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown 
                      :items="cmb_insrJobTc" 
                      v-model="ds_detail.insrJobTc" 
                      :disabled="enabledList.cmb_insrJobTc==false"
                      @input="fn_validErrRemoveRadio($event, 'cmb_insrJobTc')"
                      placeholder=" "
                      ref="cmb_insrJobTc" 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field disabled class="input-long" v-model="ds_detail.offcPsnoAddr" ref="edt_offcPsnoAddr" />
                    <mo-text-field disabled class="input-address02" v-model="ds_detail.offcAddrBldnNo"/>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 직업/직종 보험직업코드 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" 
                      v-model="ds_detail.insrJobC" 
                      disabled
                      @keyup="fn_validErrRemoveInput($event, 'edt_mvphPofn')"
                      ref="edt_insrJobC" 
                    />
                    <mo-button class="btn-input" 
                      :disabled="enabledList.edt_insrJobC==false"
                      @click="fn_OpenPopup(6)"
                    > 보험코드 </mo-button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" 
                      disabled
                      v-model="ds_detail.offcRmnAddr" 
                      ref="edt_offcRmnAddr"
                    />
                  </div>
                </td>
                <th>
                  <span> 입사일자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker :bottom="false" class="input-long" 
                      v-model="cal_incoDate"
                      :disabled="enabledList.cal_incoDate==false"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 직위명 </span>
                </th>
                <td>
                  <mo-text-field class="input-long"
                    v-model="ds_detail.jobps"
                    :disabled="enabledList.edt_jobps==false"
                  />
                </td>
                <th>
                  <span :class="jobEmphasis"> 직장전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" type="number" maxlength="4" 
                      :disabled="enabledList.edt_offcTelArno==false"
                      v-model="ds_detail.offcTelArno"
                      @keyup="fn_validErrRemoveInput($event, 'edt_offcTelArno')"
                      ref="edt_offcTelArno"
                    />
                    <mo-text-field class="input-long" type="number" maxlength="4"
                      :disabled="enabledList.edt_offcTelPofn==false"
                      v-model="ds_detail.offcTelPofn"
                      @keyup="fn_validErrRemoveInput($event, 'edt_offcTelPofn')"
                      ref="edt_offcTelPofn"
                    />
                    <mo-text-field class="input-long" type="number" maxlength="4"
                      :disabled="enabledList.edt_offcTelSeq==false" 
                      v-model="ds_detail.offcTelSeq"
                      @keyup="fn_validErrRemoveInput($event, 'edt_offcTelSeq')"
                      ref="edt_offcTelSeq"
                    />
                    <mo-checkbox v-show='visibleList.chk_offcTel' v-model="chk_offcTel" ref="chk_offcTel" class="row"> 삭제 </mo-checkbox>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row flex-sb">
          <h2 class="table-title"> CDD관련추가항목(개인) </h2>
          <!-- 추가종합계좌개설 Or 신규종합계좌개설 --> <!-- btn_TFBM32000 -->
          <!-- <mo-button primary class="btn-addNewAccount" @click="btn_TFBM32000_onclick"> {{btn_TFBM32000_TXT}} </mo-button>  -->
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 입출금 거래목적 </span>
                </th>
                <td>
                  <mo-dropdown placeholder=" "
                    :items="cmb_ioAmtTrPpsC" 
                    v-model="ds_detail.ioAmtTrPpsC" 
                    @input="fn_validErrRemoveRadio($event, 'cmb_ioAmtTrPpsC')"
                    :disabled="enabledList.cmb_ioAmtTrPpsC==false"
                    ref="cmb_ioAmtTrPpsC"  
                  />
                </td>
                <th>
                  <span class="emphasis"> 계좌신규목적 </span>
                </th>
                <td>
                  <mo-dropdown placeholder=" " 
                    :items="cmb_accOpenPpsC"
                    v-model="ds_detail.accOpenPpsC" 
                    @input="fn_validErrRemoveRadio($event, 'cmb_accOpenPpsC')"
                    :disabled="enabledList.cmb_accOpenPpsC==false"
                    ref="cmb_accOpenPpsC" 
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start" v-show="gNewMemberYn=='Y' && !isSearchScrnCheck">
      <!-- <ur-box-container direction="column" alignV="start"> -->
        <div class="wrap-table-title row">
          <h2 class="table-title"> 신규종합계좌개설 </h2>
          <div class="wrap-input row align-end">
            <!-- 처음 기획안 프로세스에서는 신규종합계좌 무조건 가입, 개별에서는 옵션 
                 수정된 기획 프로세스, 개별 무조건 다 신규계좌 생성 -->
      <!--  <mo-checkbox class="ml0" v-model="chk_NoTacct" v-show="lv_isProcess==false"> 
              신규 종합계좌 미개설 
            </mo-checkbox>
            <span class="mb3">
              ※ 종합계좌 미개설 대상(대리인/사무처리담당자에 한함)
            </span>
             -->
          </div>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 비밀번호 </span>
                </th>
                <td>
                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    class="input-long"
                    v-model="ds_detail.tacPswd1"
                    :disabled="enabledList.edt_tacPswd1==false"  
                    type="numberMax4"
                    dialog="Y"
                    start="0" 
                    end="-1"
                    :isClear="lv_isClear_tacPswd1"
                    @masked-txt="fn_SetMaskedTxtTacPswd1"         
                    ref="edt_tacPswd1"
                  />
                  <mo-text-field 
                    v-else
                    type="password" maxlength="4"
                    v-model="ds_detail.tacPswd1" class="input-long" 
                    :disabled="enabledList.edt_tacPswd1==false"
                    @focus="edt_tacPswd1_onsetfocus"
                    @keyup="fn_validErrRemoveInput($event, 'edt_tacPswd1')"
                    ref="edt_tacPswd1"
                  />
                </td>
                <th>
                  <span class="emphasis"> 비밀번호 확인 </span>
                </th>
                <td>
                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    class="input-long"
                    v-model="ds_detail.tacPswd2"
                    :disabled="enabledList.edt_tacPswd2==false"  
                    type="numberMax4"
                    dialog="Y"
                    start="0" 
                    end="-1"
                    :isClear="lv_isClear_tacPswd2"
                    @masked-txt="fn_SetMaskedTxtTacPswd2"         
                    ref="edt_tacPswd2"
                  />
                  <mo-text-field 
                    v-else
                    type="password" maxlength="4"
                    v-model="ds_detail.tacPswd2" class="input-long"
                    :disabled="enabledList.edt_tacPswd1==false"
                    @focus="edt_tacPswd2_onsetfocus"
                    @keyup="fn_validErrRemoveInput($event, 'edt_tacPswd2')"
                    ref="edt_tacPswd2"
                    />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 가상계좌번호 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row account">
                    <mo-text-field v-model="ds_detail.tacno" disabled /> 
                    <mo-text-field v-model="ds_detail.bncrVtAcno" :disabled="enabledList.edt_bncrVtAcno==false" />
                    <mo-dropdown :items="list_cmb_bankCode" v-model="ds_detail.trstVaBkcd" placeholder=" " disabled />
                    <mo-text-field v-model="ds_detail.trstVtAcno" :disabled="enabledList.msk_trstVtAcno==false"  />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 권유자 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-decimal-field class="input-long" 
                      numeric mask="#####"
                      v-model="ds_detail.invtMnEmno"
                      :disabled="enabledList.edt_invtMnEmno==false"
                       @input="edt_onkeyupToGetName('invtMnEmno')"
                      maxlength="5"
                      ref="edt_invtMnEmno" 
                    />
                    <mo-button 
                      :disabled="enabledList.edt_invtMnEmno==false"
                      @click="fn_OpenMSPTS103P('00')"
                    > 사원찾기 </mo-button>
                    <mo-text-field v-model="ds_detail.invtMnEmnoNm" disabled ref="edt_invtMnEmnoNm" class="input-long" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 관리자 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-decimal-field class="input-long" 
                      numeric mask="#####"
                      v-model="ds_detail.mngrEmno" 
                      :disabled="enabledList.edt_mngrEmno==false"
                       @input="edt_onkeyupToGetName('mngrEmno')"
                      maxlength="5"
                      ref="edt_mngrEmno" 
                    />
                    <mo-button 
                      :disabled="enabledList.edt_mngrEmno==false"
                      @click="fn_OpenMSPTS103P('02')"
                    > 사원찾기 </mo-button>
                    <mo-text-field v-model="ds_detail.mngrEmnoNm" disabled ref="edt_mngrEmnoNm" class="input-long" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 권유FC </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-decimal-field class="input-long" 
                      numeric mask="########"
                      v-model="ds_detail.fnlDsgnEmno" 
                      :disabled="enabledList.edt_fnlDsgnEmno==false"
                      @input="edt_onkeyupToGetName('fnlDsgnEmno')"
                      maxlength="8"
                      ref="edt_fnlDsgnEmno" 
                    />
                    <mo-button 
                      :disabled="enabledList.edt_fnlDsgnEmno==false"
                      @click="fn_OpenPopup(7, 'fc')"
                    > 사원찾기 </mo-button>
                    <mo-text-field v-model="ds_detail.fnlDsgnEmnoNm" disabled ref="edt_fnlDsgnEmnoNm" class="input-long" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <!-- 조회의 경우 보이지 않는다 -->
      <ur-box-container v-show="!isSearchScrnCheck">
        <div class="wrap-button button-page-bottom row">
          <div class="left">
            <mo-button v-if="lv_isProcess"
              point size="large"
              @click="fn_GoNextPage()"
              :disabled="enabledList.btn_update==false"
            > 다음 </mo-button>
          </div>
          <mo-button size="large" :disabled="enabledList.btn_cancel==false" @click="fn_cancel"> 취소 </mo-button>
          <mo-button size="large" :disabled="enabledList.btn_update==false" @click="fn_update"> 수정 </mo-button>
          <mo-button primary size="large" :disabled="enabledList.btn_confirm==false" @click="fn_confirm"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

    <!-- popup100 우편번호 --> 
    <msp-fs-100p
      ref="popup100"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"      
      :popupObj="pPopup100Obj"
    ></msp-fs-100p>

    <!-- popup100 CDI 고객조회_P --> 
    <msp-ts-100p
      ref="popupCdi"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopupCdiObj"
    ></msp-ts-100p>

    <!-- popup101 보험코드 -->
    <msp-fs-101p
      ref="popup101"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack_Code"
      :popupObj="pPopup101Obj"
    ></msp-fs-101p>

    <!-- popup115 FC조회_P -->
    <msp-ts-115p
      ref="popup115"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup115Obj"
    ></msp-ts-115p>

    <!-- popup103 사원찾기 -->
    <msp-ts-103p
      ref="popup103"
      :popup103Obj="pPopup103Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup103-callback="fn_Popup103Back"
    ></msp-ts-103p>

    <!-- popup104 FATCA_P -->
    <msp-ts-104p
      ref="popup104"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup104Obj"
    ></msp-ts-104p>

    <!-- popup105 대리인 TPUP96000.xfdl -->
    <msp-ts-105p
      ref="popupDeputy"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopupDeputyObj"
    ></msp-ts-105p>

    <!-- popup109 마케팅 TFCP10002.xfdl -->
    <msp-ts-109p
      ref="popup109"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup109Obj"
    ></msp-ts-109p>

    <!-- 종합계좌번호 콤보화면 (무통장 생성시 사용 , 화면번호 1010 통장신규) -->
    <msp-ts-95150D
      ref="search"
      @ts-alert-popup="fn_AlertPopup"
      @ts-fn-init="fn_init"
      @ts-fn-search-list="fn_searchList"
      @ts-acno-call-back-func="acnoCallBackFunc"
      :ds_search="ds_search_1010"
      :ds_list="ds_list_1010"
      :main_page="`MSPTS100M`"
    ></msp-ts-95150D>

    <mspLoading v-if="lv_wait"
      style="z-index:20000 !important"
    ></mspLoading>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSDataSet from '~/src/ui/ts/dts/TSDataSet'         // dataSet 정리
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'    

  // 팝업
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import MSPFS100P from '~/src/ui/fs/MSPFS100P'             // 우편번호 (공통)
  import MSPTS100P from '~/src/ui/ts/MSPTS100P'             // CDI 고객조회_p
  import MSPFS101P from '~/src/ui/fs/MSPFS101P'             // 보험코드 (공통)
  import MSPTS103P from '~/src/ui/ts/MSPTS103P'             // 사원찾기 (신탁)
  import MSPTS115P from '~/src/ui/ts/MSPTS115P'             // FC조회
  import MSPTS104P from '~/src/ui/ts/MSPTS104P'             // FATCA조회
  import MSPTS105P from '~/src/ui/ts/MSPTS105P'             // 대리인
  import MSPTS109P from '~/src/ui/ts/MSPTS109P'             // 마케팅 동의 
  import MSPTS95150D from '~/src/ui/ts/MSPTS95150D'         // 통합계좌 
  
  //import MSPTS100P from '~/src/ui/ts/MSPTS100P'  // 우편번호 (공통)
  import MspLoading from "@/ui/_cmm/components/MspLoading"

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS100M",
    screenId: "MSPTS100M",
    components: {
      'ts-header': TSHeader,
      'ts-alert-popup': TSAlertPopup,
      'msp-fs-100p': MSPFS100P,
      'msp-ts-100p': MSPTS100P,
      'msp-fs-101p': MSPFS101P,
      'msp-ts-115p': MSPTS115P,
      'msp-ts-103p': MSPTS103P,
      'msp-ts-104p': MSPTS104P,
      'msp-ts-105p': MSPTS105P,
      'msp-ts-109p': MSPTS109P,
      'msp-ts-95150D': MSPTS95150D,
      'mspLoading': MspLoading,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.$nextTick(()=>{        
        // if( this.lv_isProcess ) { // 프로세스
        //   this.chk_NoTacct = false // 신규계좌 개설
        // } else { // 개별
        //   this.chk_NoTacct = true // 신규계좌 미개설
        // }
      })
      this.lv_serverType = process.env.NODE_ENV      
    },
    mounted() {
      this.fn_comCode()
      this.fn_afterFormOnload()
      this.fn_rcnoCheck() // 실명확인번호로 생일/성별 설정.
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        lv_serverType: '',
        lv_wait: false,
        
        // 보안키패드 관련
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
        lv_isClear_rcnoTwo: false,  // 실명번호 보안키패드 초기화 
        lv_isClear_tacPswd1: false, // 비밀번호 보안키패드 초기화 
        lv_isClear_tacPswd2: false, // 비밀번호2 보안키패드 초기화 
        lv_masked_rcnoTwo: '',      // 실명번호 (보안키패드 '*' 치환값)
        lv_masked_tacPswd1: '',     // 비밀번호 (보안키패드 '*' 치환값)
        lv_masked_tacPswd2: '',     // 비밀번호2 (보안키패드 '*' 치환값)
        // cal_btdt: '',  
        g_edt_etcAddrTotal : '',    // 신탁별도 주소. 
        sSvcID: '',                 // TOBE 프로젝트에선 btn_confirm 등에서 사용, callback 함수에선 사용 안함
        g_eaiId: '',                // TOBE 프로젝트에선 btn_confirm 등에서 사용, callback 함수에선 사용 안함
        vFocus: true,
        // 공통 객체
        pHeaderObj: {          
          title: '개인고객등록',
          type: 'custInfoReg',
          step: 1,
        },        
        sel_cmb_rcnoKindTc : '01',
        formList: [],         // 서식 폼 데이터 오브젝트 리스트
        transType: '',        // 확인 버튼 transation 타입, insert OR update
        rcnoOne: '',          // 실명번호 첫번째   
        rcnoTwo: '',          // 실명번호 두번째   
        gCsId: '',
        ghomeOffcGb: '',      // 자택(H)/직장(O) 우편번호 팝업 구분값.
        gEmpType: '',         // 사원찾기 팝업 구분(권유자(A) / 관리자(B) / FP(C))
        gNewMemberYn: 'Y',    // 신규고객여부
        gSbmtYn: '',          // fatca징구여부 
        rdo_sbmtYn: '',
        gUpdateIngYn: '',     // 수정진행여부(수정버튼 눌렀을 때 Y/아니면 N)
        chk_NoTacct: '',      // 신규종합계좌 미개설 여부 / flase: 개설, true: 미개설
        gErpYn: '',           // ERP여부
        gErpName: '',         // ERP Name
        cmb_modifyGb: '',     // 정정구분 수정 체크

        ds_detail: Object.assign({}, TSDataSet.ds_detail ),
        ds_detail01: Object.assign({}, TSDataSet.ds_detail01 ), // 대리인
        ds_deputyObj: Object.assign({}, TSDataSet.ds_deputyObj ), // 대리인 등록 팝업 결과 저장
        ds_search: Object.assign({}, TSDataSet.ds_search ),
        ds_search01: Object.assign({}, TSDataSet.ds_search01 ),
        ds_detailFatca: Object.assign({}, TSDataSet.ds_detailFatca ), // array
        ds_searchFatca: Object.assign({}, TSDataSet.ds_searchFatca ), // array
        ds_admission: Object.assign({}, TSDataSet.ds_admission ),
        ds_cuCust: Object.assign({}, TSDataSet.ds_cuCust ),
        ds_linkAcctDetail: Object.assign({}, TSDataSet.ds_linkAcctDetail ),
        ds_rdSearch: Object.assign({}, TSDataSet.ds_rdSearch ),
        ds_listFc: Object.assign({}, TSDataSet.ds_listFc ),
        ds_9998: Object.assign({}, TSDataSet.ds_9998 ),
        ds_detailBfc: Object.assign({}, TSDataSet.ds_detailBfc ),
        enabledList: Object.assign({}, TSDataSet.enabledList ),
        visibleList: Object.assign({}, TSDataSet.visibleList ), 
        ds_natnCd: Object.assign({}, TSDataSet.ds_natnCd ),
        ds_cdiList: [],                     // cdi 조회 결과값

        /**
         * 해당 3개 객체는 디비 조회 결과이고 화면에 직접적인영향을 주지않아서 초기값을 객체로 줄 필요가 없겠지만 일단 현상 유지.
         * ds_cdiId, ds_cdiChgNd, ds_cdiIdct po에서는 배열로 받음. 각자 콜백 함수에서 배열해체하여 객체로 하나만(index:0) 저장
         */
        ds_cdiId: Object.assign({}, TSDataSet.ds_cdiId ),
        ds_cdiChgNd: Object.assign({}, TSDataSet.ds_cdiChgNd ),
        ds_cdiIdct: Object.assign({}, TSDataSet.ds_cdiIdct ),
        /////////////////////////////////////////////////////////////////////
        // 기본정보 Table
        isAgnt: '',
        // Contents Model 
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(this),
        /**
         * 공통코드 selectbox data 
         */
        cmb_rcnoKindTc: [],    // 실명종류                 
        cmb_incMnTc: [],       // 소득자구분     
        cmb_lvlhTc: [],        // 생계형구분      
        cmb_insrJobTc: [],     // 보험직업구분        
        cmb_ioAmtTrPpsC: [],   // 입출금 거래목적         
        cmb_accOpenPpsC: [],   // 계좌신규목적          
        cmb_natnNatC: TSDataSet.cmb_natnNatC, // 국가코드
        cmb_rsdnNatC: TSDataSet.cmb_natnNatC, // 거주국가
        //this.cmb_natnNatC = TSDataSet.arrayObj1

        // 팝업 객체
        pAlertPopupObj: {}, // 공통 객체

        //fn_afterFormOnload 에서 초기화 하는 변수 모음
        msk_hmadPsno: '',     // mask우편번호 초기화  (자택) computed 예정
        msk_offcAddrPsno: '', // mask우편번호 초기화  (회사) computed 예정
        edt_etcAddrTotal: '', // 신탁기타주소 초기화

        // 팝업 객체
        pPopup100Obj: {},     // 우편번호
        pPopup101Obj: {},     // 보험코드
        pPopup103Obj: {},     // 사원찾기
        pPopup104Obj: {},     // fatca 
        pPopup109Obj: {},     // 마케팅 동의 팝업 데이터
        pPopup115Obj: {},     // fc 팝업 데이터
        pPopupCdiObj: {},     // cdi 팝업 데이터
        pPopupDeputyObj: {},  // 대리인 
        
        rstFtacaPopObj: {},   // fatca 팝업 완료후 저장할 객체

        // fatca 컨트롤 객체 96060.xdlf
        fatcaCntlObj: {
          t_trnstId: '',     // 서비스 id
          t_eaiId: '',       // eaiId
          gCsId: '',         // 고객ID
          gParentFileNm: '', // 부모화면파일명
          gCorpYn: '',       // 법인여부(N)
          rtnType: false ,   // fatca팝업에서 받은 리턴타입, 초기값 false
          ds_search: {
            csId: '',
            parentFileNm: '',
            corpYn: '',
          },
          ds_detail: {
            admkNm: '',
            clctnDate: '',
            clctnSeq: '',
            rebkAcno01: '',
            rebkAcno02: '',
            btdt: '',
            clctnMedTyp: '',
            taxtYn: '',
            trgpTc: '',
            mktnCnsntYn: '',
            sbmtYn: '',
            natnNatC: '',
            rsdnNatC: '',
            cno: '',
            csId: '',
            cnm: '',
            natnNatCNm: '',
            cdiNatC: '',
            homePsnoAddr: '',
            homeRmnAddr: '',
            hAddr: '',
            cono: '',
            offcPsnoAddr: '',
            offcRmnAddr: '',
            oAddr: '',
            homeTelArno: '',
            homeTelPofn: '',
            homeTelSeq: '',
            hTelno: '',
            offcTelArno: '',
            offcTelPofn: '',
            offcTelSeq: '',
            oTelno: '',
            rcno: '',
            mvphBusiMnNo: '',
            mvphPofn: '',
            mvphSeq: '',
            procCd: '',
          }
        },
        cdiSelectObj: {}, // fn_cdiSelect 에서 사용할 객체

        // 종합계좌 생성 [1010]
        ds_search_1010: {tacno:''}, // 종합계좌번호 search 값 
        ds_list_1010: Object.assign({}, TSDataSet.defaultDsList1010),     // 종합계좌번호 콤보화면(95150D 디비전에) search 후 담길 리턴 dataSet
        ds_detail_1010: Object.assign({}, TSDataSet.defaultDsDetail1010), // 계좌 생성시 보낼 dataSet

        checkDelete: false,

        // 주연락처에 따른 필수값 표시
        homeEmphasis: '',     // 자택
        jobEmphasis: '',      // 직장
        eMailEmphasis: '',    // email
        etcAddrEmphasis: '',  // 별도주소

        // 주연락처에 따른 수정시 삭제 체크값
        chk_homeAddr: false,  // 자택주소
        chk_homeTel: false,   // 자택전화번호
        chk_homeFax: false,   // FAX 번호
        chk_homeCel: false,   // 휴대폰
        chk_offcAddr: false,  // 직장주소
        chk_offcTel: false,   // 직장전화번호
        chk_homeEmail: false, // 이메일
        chk_etcAddr: false,   // 신탁별도주소
        stacno: ''            // 조회용 종합계좌번호
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      lv_isProcess() {
        return this.getStore('tsStore').getters.getState.isProcess
      },

      // 개인고객조회 화면 체크
      isSearchScrnCheck() {
        return this.getStore('tsStore').getters.getState.isSearchScrn
      },

      cal_incoDate: {
        set: function(param) {
          let repParam = param.replaceAll("-","")
          this.ds_detail.incoDate = repParam
        },
        get: function() {
          return TSCommUtil.gfn_dateReplace(1, this.ds_detail.incoDate)
        }
      },

      cal_btdt: {
        set: function(param) {
          let repParam = param.replaceAll("-","")
          this.ds_detail.btdt = repParam
        },
        get: function() {
          return TSCommUtil.gfn_dateReplace(1, this.ds_detail.btdt)
        }
      },
      chk_doNotCallTel: { // 두낫콜 : 전화
        set: function(param) {
          this.ds_detail.doNotCallTelYn = param == true ? 'Y' : 'N'
        },
        get: function() {
          return TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.doNotCallTelYn ) ) || this.ds_detail.doNotCallTelYn == 'N'  ?  false : true
        }
      },
      doNotCallSms: { // 두낫콜 : 문자
        set: function(param) {
          this.ds_detail.doNotCallSmsYn = param == true ? 'Y' : 'N'
        },
        get: function() {
          return TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.doNotCallSmsYn ) ) || this.ds_detail.doNotCallSmsYn == 'N'  ?  false : true
        }
      },
      
      mktSwtcTel: { // 마케팅 연락방식 : 전화
        set: function(after, before) {
          const boolChar = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( after ) ) || after == false ? 'N' : 'Y'
          this.ds_detail.mktnInvtTelYn = boolChar
        },
        get: function() {
          const bool = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.mktnInvtTelYn ) ) || this.ds_detail.mktnInvtTelYn == 'N'  ?  false : true
          return bool
        }
      },
      mktSwtcSs: { // 마케팅 연락방식 : 문자
        set: function(after, before) {
          const boolChar = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( after ) ) || after == false ? 'N' : 'Y'
          this.ds_detail.mktnInvtSsYn = boolChar
        },
        get: function() {
          const bool = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.mktnInvtSsYn ) ) || this.ds_detail.mktnInvtSsYn == 'N' ? false : true
          return bool
        }
      },
      mktSwtcEMail: { // 마케팅 연락방식 : E-mail
        set: function(after, before) {
          const boolChar = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( after ) ) || after == false ? 'N' : 'Y'
          this.ds_detail.mktnInvtEmailYn = boolChar
        },
        get: function() {
          const bool = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.mktnInvtEmailYn ) ) || this.ds_detail.mktnInvtEmailYn == 'N' ? false : true
          return bool
        }
      },
      mktSwtcMail: { // 마케팅 연락방식 : 우편
        set: function(after, before) {
          const boolChar = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( after ) ) || after == false ? 'N' : 'Y'
          this.ds_detail.mktnInvtMailYn = boolChar
        },
        get: function() {
          const bool = TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.mktnInvtMailYn ) ) || this.ds_detail.mktnInvtMailYn == 'N' ? false : true
          return bool
        }
      },
      
      // 추가 부분 시작
      list_cmb_modifyGb() {
        let rtn = []
        // 대리인 정정 , 대리인 위임내용정정 삭제요건 적용
        // rtn.push({value: '50', text: '대리인 정정'})
        // rtn.push({value: '60', text: '대리인 위임내용정정'})
        rtn.push({value: '70', text: '기타'})
        return rtn
      },
      list_cmb_mvphBusiMnNo() {
        let rtn = []
        rtn.push({value: '010', text: '010'})
        rtn.push({value: '011', text: '011'})
        rtn.push({value: '016', text: '016'})
        rtn.push({value: '017', text: '017'})
        rtn.push({value: '018', text: '018'})
        rtn.push({value: '019', text: '019'})
        return rtn
      },
      list_cmb_bankCode(){
        let rtn = []
        rtn.push({value: '020', text: '우리은행'})
        return rtn
      },
      // 추가부분 끝

      nameCategory() {
        let rtn = []
        rtn.push({value: '0', text: '주민등록번호'})
        rtn.push({value: '1', text: '여권번호'})
        return rtn
      },
      rcnoKindTcList() { // 실명번호 종류
        let rtn = []
        rtn.push({value: '01', text: '주민등록번호'})
        rtn.push({value: '04', text: '외국인등록번호'})
        rtn.push({value: '05', text: '국내거소신고번호'})
        rtn.push({value: '06', text: '여권번호등'})
        rtn.push({value: '07', text: '사업자등록번호'})
        rtn.push({value: '09', text: '고유번호'})
        return rtn
      },
      list_rdo_sbmtYn() {
        let rtn= []
        rtn.push({value: 'chk_sbmtYn', text: '징구'})
        rtn.push({value: 'chk_sbmtYn1', text: '미징구'})
        return rtn
      },
      collectType() {
        let rtn = []
        rtn.push({value: '0', text: '정정 01'})
        rtn.push({value: '1', text: '정정 02'})
        return rtn
      },
      list_rdo_rsdnYn() {
        let rtn = []
        rtn.push({value: 'Y', text: '예'})
        rtn.push({value: 'N', text: '아니오'})
        return rtn
      },
      list_rdo_sexTc() {
        let rtn = []
        rtn.push({value: '1', text: '남'})
        rtn.push({value: '2', text: '여'})
        return rtn
      },
      list_rdo_mnCntcPlcTc() {
        let rtn = []
        rtn.push({value: '1', text: '자택'})
        rtn.push({value: '2', text: '직장'})
        rtn.push({value: '3', text: 'E-mail'})
        rtn.push({value: '4', text: '별도주소'})
        return rtn
      },
      list_rdo_dateTc() {
        let rtn = []
        rtn.push({value: '01', text: '양'})
        rtn.push({value: '02', text: '음'})
        return rtn
      },
      representativeType() {
        let rtn = []
        rtn.push({value: 'Y', text: '예'})
        rtn.push({value: 'N', text: '아니오'})
        return rtn
      },
      creditAgree() {
        let rtn = []
        rtn.push({value: 'Y', text: '예'})
        return rtn
      },
      rdo_marketingAgree() {
        let rtn = []
        rtn.push({value: 'Y', text: '예'})
        rtn.push({value: 'N', text: '아니오'})
        return rtn
      },
      
      // 실명번호 두번째 검증 비교할값
      valid_rcnoTwo() {
        if ( this.isMobile && this.isMtrans ) { // 보안키패드 
          return this.lv_masked_rcnoTwo
        } else { // 보안키패드가 아닌경우
          return this.rcnoTwo
        }
      },

      /**
       * 비밀번호 첫번째 검증 비교할값
       * mobile 경우 치환된값, 아닌경우 평문으로 return 
       */
      valid_tacPswd1() {
        if ( this.isMobile && this.isMtrans ) { // 보안키패드 
          return this.lv_masked_tacPswd1
        } else { // 보안키패드가 아닌경우
          return this.ds_detail.tacPswd1
        }
      },

      /**
       * 비밀번호 두번째 검증 비교할값
       * mobile 경우 치환된값, 아닌경우 평문으로 return 
       */
      valid_tacPswd2() {
        if ( this.isMobile && this.isMtrans ) { // 보안키패드 
          return this.lv_masked_tacPswd2
        } else { // 보안키패드가 아닌경우
          return this.ds_detail.tacPswd2
        }
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      /**********************************************************************************
      * 종합계좌로 통장번호 조회
      **********************************************************************************/
      async searchBank ( pTacno ) {
        // 계좌번호 조회 
        let dsList08 = [] // 데이터 셋 초기화 
        let pParam = {
          "tacno": pTacno,  // 종합계좌번호
        }
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600083_S'
        this.eaiCommObj.params = pParam
        
        const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return false
        } 
        
        return resData.tfabnb 
      },

      /******************************************************************************
      * Function명 : fn_SetMaskedTxtRcnoTwo
      * 설명       : 보안키패드 체크, 실명번호 뒷자리
      ******************************************************************************/
      fn_SetMaskedTxtRcnoTwo(val) {
        this.lv_masked_rcnoTwo = val
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
          this.$refs['edt_rcnoTwo'].$el.classList.remove('error')
      },

      /******************************************************************************
      * Function명 : fn_SetMaskedTxtTacPswd1
      * 설명       : 보안키패드 체크, 비밀번호 첫번째
      ******************************************************************************/
      fn_SetMaskedTxtTacPswd1(val) {
        this.lv_masked_tacPswd1 = val
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
          this.$refs['edt_tacPswd1'].$el.classList.remove('error')
      },

      /******************************************************************************
      * Function명 : fn_SetMaskedTxtTacPswd2
      * 설명       : 보안키패드 체크, 비밀번호 두번째
      ******************************************************************************/
      fn_SetMaskedTxtTacPswd2(val) {
        this.lv_masked_tacPswd2 = val
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
          this.$refs['edt_tacPswd2'].$el.classList.remove('error')
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack_Code
       * 설명       : popup 컴포넌트에서 callback
       ******************************************************************************/
      fn_Popup_CallBack_Code(type, pData) {
        this.ds_detail.insrJobC     = pData.vjob_cd // 보험코드
        this.ds_detailBfc.insrJobC  = pData.vjob_cd // 보험코드
        this.fn_validErrRemoveRef(this.$refs['edt_insrJobC']) // css error 검출 삭제
      },

      /******************************************************************************
       * Function명 : fn_validErrRemoveInput
       * 설명       : input, mo-text-field 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemoveInput (event, el) {
        const value = event.currentTarget.value
        const _el = this.$refs[el].$el
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(value) ) )
          _el.classList.remove('error')
      },

      /******************************************************************************
       * Function명 : fn_validErrRemoveRef
       * 설명       : ref 값으로 , mo-text-field 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemoveRef (ref) {
        ref.$el.classList.remove('error')
      },

      /******************************************************************************
       * Function명 : fn_validErrRemoveInput
       * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemoveRadio (value, el) {
        const _el = this.$refs[el].$el
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(value) ) )
          _el.classList.remove('error')
      },

      /******************************************************************************
       * Function명 : fn_validErrRemove
       * 설명       : 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemove (value) {
        console.log(value)
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(el.currentTarget._value) ) )
          el.currentTarget.parentElement.classList.remove('error')
      },

      /***************************************************************************************
        이벤트에 따른 벨리데이션 분기 error css, 메시지 처리 함수
        param : 배열 오브젝트
        return : boolean / true : 정상 , flase : 체킹됨

        insert 이벤트에 맞는 벨리데이션 리스트를 생성후 fn_validate 호출
        fn_validate의 내부함수 localValid 리턴값을 리턴한다
      *****************************************************************************************/
      fn_isValidateCompList(type) {

        let validArray = []

        switch (type) {
          case 'insert_1':
            validArray = [
              { mValue: this.ds_detail.cnm, title: '고객명', ref:'edt_cnm' }, 
              { mValue: this.ds_detail.natnNatC, title: '국적', ref:'cmb_natnNatC' }, 
              { mValue: this.ds_detail.rsdnNatC, title: '거주국가', ref:'cmb_rsdnNatC' }, 
              { mValue: this.ds_detail.incMnTc, title: '소득자구분', ref:'cmb_incMnTc' }, 
              { mValue: this.ds_detail.rsdnYn, title: '거주자여부', ref:'rdo_rsdnYn' }, 
              { mValue: this.ds_detail.sexTc, title: '성별', ref:'rdo_sexTc' }, 
              { mValue: this.ds_detail.mnCntcPlcTc, title: '주연락처', ref:'rdo_mnCntcPlcTc' }, 
              //{ mValue: this.ds_detail.mktnCnsntYn, title: '마케팅', ref:'rdo_mktnCnsntYn' },
              { mValue: this.ds_detail.insrJobTc, title: '보험직업구분', ref:'cmb_insrJobTc' }, 
              { mValue: this.ds_detail.insrJobC, title: '직업/직종 보험직업코드', ref:'edt_insrJobC' }, 
              { mValue: this.ds_detail.ioAmtTrPpsC, title: '입출금 거래목적', ref:'cmb_ioAmtTrPpsC' }, 
              { mValue: this.ds_detail.accOpenPpsC, title: '입출금 계좌신규목적', ref:'cmb_accOpenPpsC' }, 
              { mValue: this.ds_detail.lvlhTc, title: '생계형구분', ref:'cmb_lvlhTc' }, 
            ]
            break

          case 'insert_2': 
            validArray = [
                { mValue: this.ds_detail.mngrEmno, title: '관리자', ref:'edt_mngrEmno' }, 
                { mValue: this.ds_detail.invtMnEmno, title: '권유자', ref:'edt_invtMnEmno' }, 
            ]
            break

          case 'update_1': 
            validArray = [
                { mValue: this.cmb_modifyGb, title: '정정구분', ref:'cmb_modifyGb' }, 
            ]
            break

          case 'update_2': 
            validArray = [
                { mValue: this.ds_detail.cnm, title: '고객명', ref:'edt_cnm' }, 
                { mValue: this.ds_detail.natnNatC, title: '국적', ref:'cmb_natnNatC' }, 
                { mValue: this.ds_detail.rsdnNatC, title: '거주국가', ref:'cmb_rsdnNatC' }, 
                { mValue: this.ds_detail.incMnTc, title: '소득자구분', ref:'cmb_incMnTc' }, 
                { mValue: this.ds_detail.rsdnYn, title: '거주자여부', ref:'rdo_rsdnYn' }, 
                { mValue: this.ds_detail.sexTc, title: '성별', ref:'rdo_sexTc' }, 
                { mValue: this.ds_detail.mnCntcPlcTc, title: '주연락처', ref:'rdo_mnCntcPlcTc' }, 
                //{ mValue: this.ds_detail.mktnCnsntYn, title: '마케팅', ref:'rdo_mktnCnsntYn' }, 
                { mValue: this.ds_detail.insrJobTc, title: '보험직업구분', ref:'cmb_insrJobTc' }, 
                { mValue: this.ds_detail.insrJobC, title: '직업/직종 보험직업코드', ref:'edt_insrJobC' }, 
                { mValue: this.ds_detail.ioAmtTrPpsC, title: '입출금 거래목적', ref:'cmb_ioAmtTrPpsC' }, 
                { mValue: this.ds_detail.accOpenPpsC, title: '입출금 계좌신규목적', ref:'cmb_accOpenPpsC' }, 
            ]
            break
        }
        return TSCommUtil.fn_validate( this, validArray )
      },
      
      /**********************************************************************************
      /************************* Nexcro source convert Start ***************************
      **********************************************************************************/
      
      /**********************************************************************************
       * 초기화 : 화면입출력 초기화/활성화
       **********************************************************************************/
      fn_commonAfterOnload (type)
      {
        // if ( type == true ){
        //   this.enabledList.chk_NoTacct = false  // 신규종합계좌개설 항목 비활성화
        // } else	{
        //   this.enabledList.chk_NoTacct = true // 신규종합계좌개설 항목 활성화
        // }
        
        this.enabledList.edt_cnm = type
        this.enabledList.cmb_natnNatC = type
        this.enabledList.cmb_rsdnNatC = type
        this.enabledList.edt_csEnnm = type
        this.enabledList.cmb_incMnTc = type
        this.enabledList.cmb_lvlhTc = type
        this.enabledList.rdo_rsdnYn = type
        this.enabledList.rdo_sexTc = type
        this.enabledList.rdo_mnCntcPlcTc = type
        this.enabledList.cal_btdt = type
        this.enabledList.rdo_dateTc = type
        this.enabledList.rdo_cdinProfrCnsntYn = type
        this.enabledList.rdo_mktnCnsntYn = type
        this.enabledList.edt_agntNm = type
        this.enabledList.btn_popupTpup95100_1 = type
        this.enabledList.edt_homeTelArno = type
        this.enabledList.edt_homeTelPofn = type
        this.enabledList.edt_homeTelSeq = type
        this.enabledList.edt_emailAddr = type
        this.enabledList.edt_domnNm = type
        this.enabledList.edt_faxArno = type
        this.enabledList.edt_faxPofn = type
        this.enabledList.edt_faxSeq = type
        this.enabledList.cmb_mvphBusiMnNo = type
        this.enabledList.edt_mvphPofn = type
        this.enabledList.edt_mvphSeq = type
        this.enabledList.edt_offcNm = type
        this.enabledList.edt_dpnm = type
        this.enabledList.cmb_insrJobTc = type
        this.enabledList.btn_popupTpup95100_2 = type
        this.enabledList.edt_offcTelArno = type
        this.enabledList.edt_offcTelPofn = type
        this.enabledList.edt_offcTelSeq = type
        this.enabledList.btn_popupTpup95230 = type
        this.enabledList.cal_incoDate = type
        this.enabledList.edt_jobps = type
        this.enabledList.cmb_ioAmtTrPpsC = type
        this.enabledList.cmb_accOpenPpsC = type
        this.enabledList.edt_tacPswd1 = type
        this.enabledList.edt_tacPswd2 = type
        this.enabledList.edt_bncrVtAcno = type
        this.enabledList.msk_trstVtAcno = type
        this.enabledList.edt_invtMnEmno = type
        this.enabledList.btn_popupTPUP95110_1 = type
        this.enabledList.edt_mngrEmno = type
        this.enabledList.btn_popupTPUP95110_2 = type
        this.enabledList.edt_fnlDsgnEmno = type
        this.enabledList.btn_popupTPUP95290 = type
        this.enabledList.cmb_ioAmtTrPpsC = type 
        this.enabledList.cmb_accOpenPpsC = type 
        this.enabledList.cmb_insrJobTc = type 
        this.enabledList.edt_insrJobC = type
        this.enabledList.edt_tacPswd1 = type
        this.enabledList.edt_tacPswd2 = type
        this.enabledList.edt_invtMnEmno = type
        this.enabledList.edt_mngrEmno = type
        this.enabledList.edt_fnlDsgnEmno = type
        this.enabledList.rdo_representative = type
        this.enabledList.edt_offcFax = type
      },

      /**********************************************************************************
       * gfn_setInitForm의  callback 함수 : 실질적 form_onload 구현
       **********************************************************************************/
      fn_afterFormOnload ()
      {
        //---------------------------------------------------
        this.fn_enableComponent() // 메인버튼컨트롤
        this.fn_userInfoInit() // 사용자정보초기화 
        
        this.msk_hmadPsno     = ''   // mask우편번호 초기화
        this.msk_offcAddrPsno = ''   // mask우편번호 초기화
        this.edt_etcAddrTotal = ''   // 신탁기타주소 초기화
         
        this.gNewMemberYn = "Y"      // 신규고객여부 초기화
        this.gUpdateIngYn = ""       // 수정진행여부(수정버튼 눌렀을 때 Y/아니면 N)

        this.chk_NoTacct = false                    // 신규종합계좌개설 개설
        this.enabledList.edt_rcno = true            // 실명번호 입력란 활성화
        this.enabledList.cmb_modifyGb = false       // 정정구분 비활성화
        this.visibleList.cmb_modifyGb = false       // 수정체크 비활성화
        this.cmb_modifyGb = ''  
        this.visibleList.edt_tacPswd1 = true
        this.visibleList.edt_tacPswd2 = true
        this.visibleList.edt_tacno = true
        this.visibleList.edt_bncrVtAcno = true
        this.visibleList.cmb_bankCode = true
        this.visibleList.msk_trstVtAcno = true
        this.visibleList.edt_invtMnEmno = true
        this.visibleList.btn_popupTPUP95110_1 = true
        this.visibleList.edt_invtMnNm = true
        this.visibleList.edt_mngrEmno = true
        this.visibleList.btn_popupTPUP95110_2 = true
        this.visibleList.edt_mngrNm = true
        this.visibleList.edt_fnlDsgnEmno = true
        this.visibleList.btn_popupTPUP95290 = true
        this.visibleList.edt_fnlDsgnNm = true

        // this.visibleList.sta_notice1 = true  // rdo_mnCntcPlcTc_onitemclick 에서 처리
        // this.visibleList.sta_notice2 = false  // rdo_mnCntcPlcTc_onitemclick 에서 처리
        // this.visibleList.sta_notice11 = false // rdo_mnCntcPlcTc_onitemclick 에서 처리
        this.rdo_sbmtYn = '' // 징구 라디오 value , ASIS => this.chk_sbmtYn = false , this.chk_sbmtYn1 = false
        this.ds_detail.cal_btdt = ''
        this.ds_detail.sexTc = ''
        this.ds_detail.hid_edt_csIdByRcno = ''
        // this.gErpYn = "N"
        this.gErpName = ""
        this.g_eaiId = "C392_F10600135_S" // selectTFBZ99998
        // ########## ERP_APPLY_START ##################################
          // ERP 판단
          // selectTFBZ99998
        /*서비스 호출시 정보 세팅 */
        
        // as is
        // t_trnstId = "selectTFBZ99998" 
        // var sURL          = "svc::"+t_trnstId+".do" // svc = http://localhost:8080/
        // var sInDatasets   = "tfbz99998VO=ds_9998"  // transaction을 요청할 때 입력값으로 보낼 Dataset 의 ID 들 입니다.
        // var sOutDatasets  = "ds_9998=tfbz99998" 
        // var sArgument     = ""
        // var sCallbackFunc = "fn_callBack9998" // transaction의 결과를 돌려줄 Function의 이름입니다.
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(t_trnstId, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc)

        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600135_S'
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack9998)
        
        // ########## ERP_APPLY_END ##################################
      },

      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      // ########## ERP_APPLY_START ##################################
      fn_callBack9998 ( res )
      {
        
        const resData = Object.assign({}, res.data)
        Object.assign(this.ds_9998, resData)

        this.gErpYn = ""
        this.gErpName = ""

        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return false
        }
        
        const t_eaiId = res.commHeaderVO.eaiId
        
        if ( t_eaiId == "C392_F10600135_S" ) { // selectTFBZ99998
          
          this.gErpName = resData.eaiSystemName
          if ( ! TSCommUtil.gfn_isNull( this.gErpName )) {
            if ( this.gErpName == "SAP-PI" ) {
              this.gErpYn = "Y"
            } else {
              this.gErpYn = "N"
            }
          }
        }

        if ( this.gErpYn == "N"){
          this.visibleList.homeAddrBldnNo = false
          this.visibleList.offcAddrBldnNo = false
        } else {
          this.visibleList.homeAddrBldnNo = true
          this.visibleList.offcAddrBldnNo = true
        }
      },

      /**********************************************************************************
       * 초기화  : WFtitleCRUDbtn 의 callback : 폼로드시 호출, 초기화버튼 클릭시 호출\
       * 초기화는 3가지 타입이 존재.
       * 조회할때 이전 데이터를 위해 초기화
       * 취소버튼 초기화
       * 초기화 버튼 초기화 
       **********************************************************************************/
      fn_init ( btnTtype )
      {
        // 취소버튼과 조회버튼일 경우 해당 search 영역은 초기화 하지 않는다. 
        if ( btnTtype != 'cancel' && btnTtype != 'search' ) {
          this.ds_search = Object.assign({}, TSDataSet.ds_search)
          this.ds_search01 = Object.assign({}, TSDataSet.ds_search01) 
          this.ds_search.rcno = ''
          this.rcnoOne = ''
          this.rcnoTwo = ''
          this.lv_isClear_rcnoTwo = !this.lv_isClear_rcnoTwo ? true : false // 보안키패드 실명번호 두번째 초기화
        }
        this.ds_detail = Object.assign({}, TSDataSet.ds_detail)
        this.ds_detail01 = Object.assign({}, TSDataSet.ds_detail01) // 대리인
        this.ds_deputyObj = Object.assign({}, TSDataSet.ds_deputyObj) // 대리인 팝업 결과값 저장
        this.ds_detailFatca = Object.assign({}, TSDataSet.ds_detailFatca) // array
        this.ds_searchFatca = Object.assign({}, TSDataSet.ds_searchFatca) // array
        this.ds_admission = Object.assign({}, TSDataSet.ds_admission)
        this.ds_cuCust = Object.assign({}, TSDataSet.ds_cuCust)
        this.ds_cdiId = Object.assign({}, TSDataSet.ds_cdiId)
        this.ds_cdiChgNd = Object.assign({}, TSDataSet.ds_cdiChgNd)
        this.ds_cdiIdct = Object.assign({}, TSDataSet.ds_cdiIdct) 
        this.ds_linkAcctDetail = Object.assign({}, TSDataSet.ds_linkAcctDetail)
        this.ds_rdSearch = Object.assign({}, TSDataSet.ds_rdSearch)
        this.ds_listFc = Object.assign({}, TSDataSet.ds_listFc)
        this.ds_9998 = Object.assign({}, TSDataSet.ds_9998)
        this.ds_detailBfc = Object.assign({}, TSDataSet.ds_detailBfc)
        this.enabledList = Object.assign({}, TSDataSet.enabledList)
        this.visibleList = Object.assign({}, TSDataSet.visibleList) 
        this.ds_natnCd = Object.assign({}, TSDataSet.ds_natnCd)
        this.ds_search_1010 = Object.assign({}, {tacno:''}), // 종합계좌번호 콤보화면(95150D 디비전에) search 값
        this.ds_list_1010 = Object.assign({}, TSDataSet.defaultDsList1010), // 종합계좌번호 콤보화면(95150D 디비전에) search 후 담길 리턴 dataSet
        this.ds_detail_1010 = Object.assign({}, TSDataSet.defaultDsDetail1010), // 계좌 생성시 보낼 dataSet
        this.rstFtacaPopObj = {} 

        this.fn_afterFormOnload()
        // 취소버튼 클릭 시, 실명확인번호종류구분코드 / 실명확인번호 입력란 enable
        this.enabledList.cmb_rcnoKindTc = true
        this.enabledList.edt_rcno = true
        
        this.fn_enableComponent() // 메인버튼컨트롤
        this.fn_commonAfterOnload(false) 
        // this.fn_userInfoInit() // 사용자정보초기화 
        
        //데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.
        this.msk_hmadPsno = ''        // mask우편번호 초기화
        this.msk_offcAddrPsno = ''    // mask우편번호 초기화
        this.gNewMemberYn = "Y"       // 신규고객여부 초기화
        this.gUpdateIngYn = ""        // 수정진행여부(수정버튼 눌렀을 때 Y/아니면 N) 초기화  
        this.rdo_sbmtYn = ''          // ASIS => this.chk_sbmtYn = false
        this.ds_cdiList= []           // cdi 조회 결과값
        this.transType = ''           // 확인 버튼 transation 타입, insert OR update
        this.g_edt_etcAddrTotal = ''  // 신탁별도주소
        this.isAgnt = '' // 대리인여부 

        // 주연락처에 따른 필수값 표시 초기화
        this.homeEmphasis = ''        // 자택
        this.jobEmphasis = ''         // 직장
        this.eMailEmphasis = ''       // email
        this.etcAddrEmphasis = ''     // 별도주소

        // 주연락처에 따른 수정시 삭제 체크값 초기화
        this.chk_homeAddr = false     // 자택주소
        this.chk_homeTel = false      // 자택전화번호
        this.chk_homeFax = false      // FAX 번호
        this.chk_homeCel = false      // 휴대폰
        this.chk_offcAddr = false     // 직장주소
        this.chk_offcTel = false      // 직장전화번호
        this.chk_homeEmail = false    // 이메일
        this.chk_etcAddr = false      // 신탁별도주소

        // 주연락처에 따른 수정시 삭제 체크박스 visible 초기화
        this.visibleList.chk_homeAddr = false     // 자택주소
        this.visibleList.chk_homeTel = false      // 자택전화번호
        this.visibleList.chk_homeFax = false      // FAX 번호
        this.visibleList.chk_homeCel = false      // 휴대폰
        this.visibleList.chk_offcAddr = false     // 직장주소
        this.visibleList.chk_offcTel = false      // 직장전화번호
        this.visibleList.chk_homeEmail = false    // 이메일
        this.visibleList.chk_etcAddr = false      // 신탁별도주소

        this.stacno = '' //종합계좌초기화
        this.fn_rmAllErrCss() // error class 전체 삭제

        this.lv_isClear_tacPswd1 = !this.lv_isClear_tacPswd1 ? true : false // 보안키패드 비밀번호 첫번째 초기화
        this.lv_isClear_tacPswd2 = !this.lv_isClear_tacPswd2 ? true : false // 보안키패드 비밀번호 두번째 초기화
      },

      /**********************************************************************************
       * WFtitleCRUDbtn Div 의 버튼 활성,비활성 처리(조회/확인(저장)/취소/초기화)
       **********************************************************************************/
      fn_enableComponent ()
      {
        this.enabledList.btn_searchList = true
        this.enabledList.btn_update = false
        this.enabledList.btn_confirm = false
        this.enabledList.btn_cancel = false
        this.enabledList.btn_init = true
      },

      /***************************************************************************************
          사용자정보 항목  초기화 
      *****************************************************************************************/
      fn_userInfoInit ()
      {
        // TODO : 의도 파악 안됨, 확인 필요
         //this.vLognDp  = application.gds_userInfo.getColumn( 0 , "brcd") // 로그인지점 
      },

      /**********************************************************************************
       * 주연락처 선택: 주연락처 radio button click 시
       **********************************************************************************/
      fn_commonInsertCheck ()
      {
        let validArray = []

        // 주연락처 check
        if ( this.ds_detail.mnCntcPlcTc == "1" ) // 자택(휴대번호)
        {
          validArray = [
              { mValue: this.ds_detail.hmadPsno,  title: '연락처정보 우편번호', ref:'hmadPsno' }, 
              { mValue: this.ds_detail.homePsnoAddr, title: '연락처정보 자택주소', ref:'edt_homePsnoAddr' },
              { mValue: this.ds_detail.mvphBusiMnNo, title: '연락처정보 휴대폰번호', ref:'cmb_mvphBusiMnNo' },
              { mValue: this.ds_detail.mvphPofn, title: '연락처정보 휴대폰번호', ref:'edt_mvphPofn' },
              { mValue: this.ds_detail.mvphSeq, title: '연락처정보 휴대폰번호', ref:'edt_mvphSeq' },
          ]
          return TSCommUtil.fn_validate( this, validArray )
        } 
        else if ( this.ds_detail.mnCntcPlcTc == "2" ) // 직장(휴대번호 필수)
        {
          validArray = [
              { mValue: this.ds_detail.offcAddrPsno,  title: '직업정보 우편번호', ref:'offcAddrPsno' }, 
              { mValue: this.ds_detail.offcPsnoAddr, title: '직업정보 주소', ref:'edt_offcPsnoAddr' },
              { mValue: this.ds_detail.offcRmnAddr, title: '직업정보 상세주소', ref:'edt_offcRmnAddr' },
              { mValue: this.ds_detail.offcTelArno, title: '직업정보 직장전화번호', ref:'edt_offcTelArno' }, // 010
              { mValue: this.ds_detail.offcTelPofn, title: '직업정보 직장전화번호', ref:'edt_offcTelPofn' },
              { mValue: this.ds_detail.offcTelSeq, title: '직업정보 직장전화번호', ref:'edt_offcTelSeq' },
              { mValue: this.ds_detail.offcNm, title: '직업정보 직장명', ref:'edt_offcNm' },
          ]
          
          return TSCommUtil.fn_validate( this, validArray )
        }
        else if ( this.ds_detail.mnCntcPlcTc == "3" ) // eMail(휴대번호 필수) 
        {
          validArray = [
              { mValue: this.ds_detail.emailAddr,  title: '연락처정보 이메일 주소', ref:'edt_emailAddr' }, 
              { mValue: this.ds_detail.domnNm, title: '연락처정보 이메일 도메인', ref:'edt_domnNm' },
              { mValue: this.ds_detail.mvphBusiMnNo, title: '연락처정보 휴대폰번호', ref:'cmb_mvphBusiMnNo' },
              { mValue: this.ds_detail.mvphPofn, title: '연락처정보 휴대폰번호', ref:'edt_mvphPofn' },
              { mValue: this.ds_detail.mvphSeq, title: '연락처정보 휴대폰번호', ref:'edt_mvphSeq' },
          ]
          return TSCommUtil.fn_validate( this, validArray )
        }
        else if ( this.ds_detail.mnCntcPlcTc == "4" ) // 별도주소(휴대번호 필수)
        {
          validArray = [
              { mValue: this.g_edt_etcAddrTotal,  title: '연락처정보 신탁별도주소', ref:'edt_etcAddrTotal' }, 
              { mValue: this.ds_detail.mvphBusiMnNo, title: '연락처정보 휴대폰번호', ref:'cmb_mvphBusiMnNo' },
              { mValue: this.ds_detail.mvphPofn, title: '연락처정보 휴대폰번호', ref:'edt_mvphPofn' },
              { mValue: this.ds_detail.mvphSeq, title: '연락처정보 휴대폰번호', ref:'edt_mvphSeq' },
          ]
          return TSCommUtil.fn_validate( this, validArray )
        } else { 
          return true
        }
       
      },

      /***************************************************************************************
        code        : 호출할 공통코드
        dsName      : 호출된 공통코드를 담을 Dataset
        selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
        
        selecttype은 추후 변경될 수 있음.
        this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
      *****************************************************************************************/
      fn_comCode ()
      {
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
        this.eaiCommObj.params = {'TUKEMK':[
                                    {cId:"510402"},  // cmb_rcnoKindTc 실명종류
                                    {cId:"506002"},  // cmb_incMnTc 소득자구분
                                    {cId:"5063"},    // cmb_lvlhTc 생계형구분 
                                    {cId:"5038"},    // cmb_insrJobTc 보험직업구분 
                                    {cId:"5114"},    // cmb_ioAmtTrPpsC 입출금 거래목적 
                                    {cId:"5115"}     // cmb_accOpenPpsC 계좌신규목적 
                                ]}
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack)
      }, 

      fn_comCodeCallBack ( res ) {
        for ( let index in res.data.tukemk ) {
            let item = res.data.tukemk[index]
            switch (item.cid) 
            {
              case "5104": // 510402
                if ( item.c == '01' || item.c == '04' ) // 주민번호, 외국인번호만 사용
                  this.cmb_rcnoKindTc.push({value: item.c , text: item.cnm})
                break
              case "5060": // 506002
                this.cmb_incMnTc.push({value: item.c , text: item.cnm})
                break
              case "5063":
                this.cmb_lvlhTc.push({value: item.c , text: item.cnm})
                break
              case "5038":
                this.cmb_insrJobTc.push({value: item.c , text: item.cnm})
                break
              case "5114":
                this.cmb_ioAmtTrPpsC.push({value: item.c , text: item.cnm})
                break
              case "5115":
                this.cmb_accOpenPpsC.push({value: item.c , text: item.cnm})
                break
            }
        }

        //Combo에 최상단Row보이도록 설정(선택사항)
        if ( TSCommUtil.gfn_isNull ( this.ds_search.rcno ))
          this.ds_search.rcnoKindTc = '01'
        this.initDom()
      },

      /**********************************************************************************
       * 조회 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_searchList ()
      {
        this.fn_init('search')
        this.cmb_modifyGb = '' // 정정구분 초기화
        this.enabledList.cmb_modifyGb = false   // 정정구분 비활성화
        this.visibleList.cmb_modifyGb = false  // 수정체크 비활성화
        this.cmb_modifyGb = '' // 정정구분 초기화 
        this.enabledList.btn_popupTpup96000 = false // 대리인버튼 비활성화
        
        // 두낫콜 초기화
        this.chk_doNotCallTel = false
        this.doNotCallSms = false

        
        this.formList = [] // 전자서식 폼데이터 리스트 초기화
        this.rstFtacaPopObj = {} // fatca 팝업 완료후 저장할 객체 초기화
        
        // 취소버튼 클릭 시, 실명확인번호종류구분코드 / 실명확인번호 입력란 enable
        this.enabledList.cmb_rcnoKindTc = true
        this.enabledList.edt_rcno = true

        if ( TSCommUtil.gfn_isNull( this.ds_search.rcnoKindTc ) )
        {
          TSCommUtil.fn_validate( this, [{ content: '실명번호종류를 입력하세요.', ref:'cmb_rcnoKindTc' }] )
          return
        }
        if ( TSCommUtil.gfn_trim(this.rcnoOne).length == 0 )
        {
          TSCommUtil.fn_validate( this, [{ content: '필수입력 항목입니다.', ref:'edt_rcnoOne' }] )
          return false
        }
        if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length == 0 )
        {
          TSCommUtil.fn_validate( this, [{ content: '필수입력 항목입니다', ref:'edt_rcnoTwo' }] )
          return false
        }
        if ( TSCommUtil.gfn_trim(this.rcnoOne).length != 6 )
        {
          TSCommUtil.fn_validate( this, [{ content: '실명번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoOne' }] )
          return false
        }
        if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
        {
          TSCommUtil.fn_validate( this, [{ content: '실명번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoTwo' }] )
          return false
        }
        
        // 1.실명번호종류구분코드+실명번호 검증.
        this.fn_comRcnoCheck()
        
        /*서비스 호출시 정보 세팅 */
        // t_trnstId = "selectTFCP10000" 
        // var sOutDatasets  = "ds_detail=tfcp10000Rst ds_detail01=tfcreiRst" 
        // var sInDatasets   = "tfcp10000=ds_search:"  // transaction을 요청할 때 입력값으로 보낼 Dataset 의 ID 들 입니다.
        // var sCallbackFunc = "fn_callBack" // transaction의 결과를 돌려줄 Function의 이름입니다.
        
        this.g_eaiId = "C392_F10600137_S" // selectTFCP10000
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'C392_F10600137_S' //selectTFCP10000
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600137_S'
        this.eaiCommObj.params = this.ds_search
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
      },

      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      async fn_callBack ( res )
      {
        const lv_Vm = this
        const t_eaiId = res.commHeaderVO.eaiId
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return false
        }

        if ( t_eaiId == "C392_F10600137_S" )
        {
          Object.assign(this.ds_detail, resData )
          Object.assign(this.ds_detailBfc , this.ds_detail )  // jjh 0729 추가, 수정이 잘 되지 않아 의심되는 부분이라 추가함
          this.ds_detail.cdinProfrCnsntYn = 'Y'               // 신용동의 여부 계약체결은 고정Y가 요건, 외국인의 경우 이값이 빈값으로 오는 경우가 있음. 
          
          if ( TSCommUtil.gfn_length(resData.tfcrei) > 0 )
            Object.assign ( this.ds_detail01, resData.tfcrei[0]) 

          // 고객이 있을 경우 -> 수정가능하게 처리함
          if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(this.ds_detail.cnm ) ) ) {
              
            // 종합계좌번호조회
            let lv_tacno = await this.fn_searchTacno() // stacno 에 종합계좌 값 셋팅

            // 프로세스의 경우 화면 이동 컨트롤
            if ( this.lv_isProcess ) {
              
              // 종합계좌가 없을경우
              if ( TSCommUtil.gfn_isNull( this.stacno ) ) { 
                this.fn_AlertPopup(0,{ 
                  content: "TASS시스템에서 종합계좌개설 후 진행 가능합니다." ,
                  confirmFunc: function () {
                    lv_Vm.$refs.tsHeader.fn_GoPage()
                  },
                  confirm: true , 
                  single: true , 
                })
                return false 
              } 

              // 통장번호 조회
              let lv_bnk = await this.searchBank( this.stacno ) 
              // console.log(TSCommUtil.gfn_isNull( lv_bnk[6].tacnoBnkbSeq ))
              if ( TSCommUtil.gfn_isNull( lv_bnk ) || TSCommUtil.gfn_isNull( lv_bnk[0].tacnoBnkbSeq ) ) { // 통장 번호가 없을경우
                this.fn_AlertPopup(0,{ 
                  content: "통장미발급 고객입니다. TASS시스템에서 통장발급 후 진행해주십시오." ,
                  confirmFunc: function () {
                    lv_Vm.$refs.tsHeader.fn_GoPage()
                  }, 
                  confirm: true , 
                  single: true , 
                })
                return false 
              } 

              this.fn_AlertPopup(0,{ 
                content: ["이미 계좌를 보유하고 계신 고객입니다.","신규입력 화면으로 이동합니다."] ,
                confirm: true , 
                single: true , 
                confirmFunc: this.fn_GoNextPage
              })
              return false // 신규입력 화면으로 이동하기 때문에 아래 로직을 더이상 진행하지 않고 종료

            }

            this.gNewMemberYn = "N" //조회 전 신규고객여부 setting
            this.fn_commonAfterOnload(false) // 입력항목 비활성화
            // 2017.02.16 : ERP 적용 시 마케팅동의여부 활성화 처리하면 됨.
            //this.rdo_mktnCnsntYn.set_enable(true) 
            this.enabledList.rdo_mktnCnsntYn = false // 마케팅동의여부 비활성화(ERP오픈 후 주석처리)
            //this.fn_commonAfterOnload(true) // 입력항목 all 활성화(readonly)
            // 자택 / 직장 우편번도 setting
            if ( ! TSCommUtil.gfn_isNull ( this.ds_detail.hmadPsno1 ) )
              this.ds_detail.hmadPsno = this.ds_detail.hmadPsno1.toString() + this.ds_detail.hmadPsno2.toString()
            else
              this.ds_detail.hmadPsno = ''
            
            if ( ! TSCommUtil.gfn_isNull ( this.ds_detail.offcAddrPsno01 ) )
              this.ds_detail.offcAddrPsno = this.ds_detail.offcAddrPsno01.toString() + this.ds_detail.offcAddrPsno02.toString()
            else
              this.ds_detail.offcAddrPsno = '' 

            //기타주소가 존재하면 화면에 보여줌.
            if ( ! TSCommUtil.gfn_isNull ( this.ds_detail.etcPsnoAddr ) )
            {
              let etcAddrPsno1 	= this.ds_detail.etcAddrPsno1
              let etcAddrPsno2 	= this.ds_detail.etcAddrPsno2
              let etcPsnoAddr 	= this.ds_detail.etcPsnoAddr
              let etcRmnAddr 		= this.ds_detail.etcRmnAddr
              let etcAddrBldnNo = this.ds_detail.etcAddrBldnNo
              
              if (this.gErpYn == "N"){
                this.g_edt_etcAddrTotal = etcAddrPsno1+"-"+etcAddrPsno2+" "+etcPsnoAddr+" "+etcRmnAddr
              } else {
                this.g_edt_etcAddrTotal = etcAddrPsno1+"-"+etcAddrPsno2+" "+etcPsnoAddr+" "+etcAddrBldnNo+" "+etcRmnAddr
              }
            }
            
            // 두낫콜 조회
            if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(this.ds_detail.doNotCallTelYn) ) && this.ds_detail.doNotCallTelYn == "Y" ) {
              this.ds_detail.chk_doNotCallTel = true
            } else {
              this.ds_detail.chk_doNotCallTel = false
            }
            
            if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(this.ds_detail.doNotCallSmsYn) ) ){
              if (  this.ds_detail.doNotCallSmsYn == "Y" )
                this.ds_detail.doNotCallSms = true
              else 
                this.ds_detail.doNotCallSms = false
            } 
            else 
              this.ds_detail.doNotCallSms = false
            
            // 기존 종합계좌번호가 존재하는 고객은 신규종합계좌개설 입력값 안보이게 처리
            if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(this.ds_detail.noTacct) ) )
            {
              
              if ( this.ds_detail.noTacct == "Y" ) // 종합계좌있다.
              {
                // this.visibleList.noTacct = false // 신규종합계좌개설 영영 전체 보이지 않음
                // this.btn_TFBM32000_TXT = "추가종합계좌개설"
                // this.visibleList.btn_TFBM32000 = true
              }
              else // 종합계좌없다.
              {
                // this.visibleList.noTacct = true // 신규종합계좌개설 영영 전체 보임
                // this.btn_TFBM32000_TXT = "신규종합계좌개설"
                // this.visibleList.btn_TFBM32000 = true
              }
            }
            
            // 분리파기 check
            const spltDstyStatC = TSCommUtil.gfn_trim ( this.ds_detail.spltDstyStatC ) 
            // 고객ID를 리턴받은 경우 -> fatca 처리
            if (spltDstyStatC == 11 || spltDstyStatC == 12 || spltDstyStatC == 13) {
              this.fn_AlertPopup(0,{content: '접근제한 고객입니다. ERP시스템에서 BP ID 재등록하시기 바랍니다.'}) 
              return
            } 
            else {
              this.fn_fatcaSelect();
            }

            // 데이터 존재하면 : 수정 / 초기화 활성화
            this.enabledList.btn_update = true      // 수정 활성화
            this.enabledList.btn_init = true        // 초기화 활성화
            this.enabledList.cmb_modifyGb = true    // 정정구분 활성화
            this.enabledList.btn_searchList = false // 조회 활성화
            this.enabledList.btn_confirm = false    // 확인 비활성화
            this.enabledList.btn_cancel = false     // 취소 비활성화
          }
          // 고객이 없을 경우 -> 신규로 등록할건지 문의.
          else
          { 
            // 고객조회와 등록 분기
            if ( this.isSearchScrnCheck ) { // 조회
              this.fn_AlertPopup(0,{content: '등록된 고객정보가 없습니다.'}) 
            } else { // 등록
              this.fn_AlertPopup(0,{ 
                content: "신규고객입니다. 고객등록을 진행합니다." ,
                confirm: true , 
                single: true , 
                confirmFunc: this.fn_newCustRegCallBack 
              })
            }
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_newCustRegCallBack
       * 설명       : 신규고객입니다. 팝업 콜백 함수
      ******************************************************************************/      
      fn_newCustRegCallBack(){
        this.gNewMemberYn = "Y"; //조회 전 신규고객여부 setting
        this.fn_commonAfterOnload(true); // 입력항목 all 활성화

        // 신규고객 : 확인 / 취소 / 초기화 활성화
        this.enabledList.btn_searchList = false       // 조회비활성화
        this.enabledList.btn_update = false           // 수정비활성화
        this.enabledList.btn_confirm = true           // 확인활성화
        this.enabledList.btn_cancel = true            // 취소활성화
        this.enabledList.btn_init = true              // 초기화활성화
        this.enabledList.cmb_modifyGb = true          // 정정구분 활성화
        this.enabledList.btn_popupTpup96000 = true    // 대리인팝업활성화(이름만조회가능 -> 수정기능은 정정구분 선택 시 추가)
        
        // cdi다건조회 : SN
        this.ds_cdiList = []
        this.fn_cdiSelectId();
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       
       * 버튼 구성 방법
       * 1. 확인(클릭시 콜백 없음) : pPopupObj.confirm == true
       * 2. 확인(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == true
       * 3. 확인,취소(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == false
       * 
       * 버튼 클릭 콜백 함수
       * pPopupObj.confirmFunc : function  // 확인 버튼 클릭 콜백함수
       * pPopupObj.closeFunc : function    // 취소 버튼 클릭 콜백함수
       
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {

        switch (type) {
          case 0 :
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)              
            }
            this.$refs.alertPopup.fn_Open()     
            break
          case 2 :
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) { // 얼럿 팝업 타입을 0으로 맞춤
              TSInfoUtil.initAlertPopObj(0, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopupSec.fn_Open()     
            break
          case 7: // 마지막 얼럿창
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) { // 얼럿 팝업 타입을 0으로 맞춤
              TSInfoUtil.initAlertPopObj(0, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()
            break
          case 9 :
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()            
            break
          default :
            this.$refs.alertPopup.fn_Open()
        }
        
      },

      /**********************************************************************************
       * 공통 실명번호 체크
       **********************************************************************************/
      fn_comRcnoCheck ()
      {
        if ( this.ds_search.rcnoKindTc <= "06" )
        {
          // 주민등록번호
          if ( this.ds_search.rcnoKindTc == "01" ) 
          {
            if ( TSCommUtil.gfn_trim(this.rcnoOne).length != 6 )
            {
              TSCommUtil.fn_validate( this, [{ content: '주민번호의 형식이 올바르지 않습니다', ref:'edt_rcnoOne' }] )
              return false
            }
            if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
            {
              TSCommUtil.fn_validate( this, [{ content: '주민번호의 형식이 올바르지 않습니다', ref:'edt_rcnoTwo' }] )
              return false
            }
          }
          // 외국인등록번호
          else if ( this.ds_search.rcnoKindTc == "04" )
          {
            if ( TSCommUtil.gfn_trim(this.rcnoOne).length != 6 )
            {
              TSCommUtil.fn_validate( this, [{ content: '외국인 등록번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoOne' }] )
              return false
            }
            if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
            {
              TSCommUtil.fn_validate( this, [{ content: '외국인 등록번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoTwo' }] )
              return false
            }
          }
        }
        else
        {
          TSCommUtil.fn_validate( this, [{ content: '실명번호 종류가 올바르지 않습니다.', ref:'cmb_rcnoKindTc' }] )
          return false
        }
      },

      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) 
       *             4: 마케팅 동의여부 / 5: 고객ID선택 / 6: 보험코드 / 7: FC팝업 / 8: cdi
       *             9: FATCA / 10: 대리인등록_P
       ******************************************************************************/
      fn_OpenPopup(type, flag) {
        switch (type) {
          case 0:
            this.pPopup100Obj.btnFlag = flag
            this.$refs.popup100.fn_Open()
            break          
          case 1:
            // this.$refs.popup103.fn_Open()
            break
          case 2:
            // this.$refs.popup104.fn_Open()
            break
          case 3:
            this.pPopup103Obj.btnFlag = flag
            this.$refs.popup103.fn_Open()
            break
          case 4:
            this.pPopup109Obj.ds_detail = this.ds_detail
            this.$refs.popup109.fn_Open()
            break
          case 5:
            this.$refs.popup107.fn_Open()
            break
          case 6:
            this.pPopup101Obj.procType = 'tass' 
            this.$refs.popup101.fn_Open()
            break
          case 7:
            this.pPopup115Obj.invPosYn = this.ds_listFc.invPosYn
            this.$refs.popup115.fn_Open()
            break            
          case 8:
            this.$refs.popupCdi.fn_Open()
            break
          case 9:
            this.$refs.popup104.fn_Open()
            break  
          case 10:
            // this.pPopupDeputyObj.csId = this.ds_search.csId 
            // this.pPopupDeputyObj.prosGb = this.ds_search.csId
            // this.pPopupDeputyObj.modifyGb = this.ds_search.csId
            
            let procGb = ""
            let modifyGb = ""
            this.ds_detail.hid_chk_AgntDelYn = "N"
            if ( this.cmb_modifyGb == "70" ) // 기타 : S
            {
              procGb = "S"
              modifyGb = "70"
            }
            else // 대리인정정 : I / U
            {
              if ( this.cmb_modifyGb == "60" )
              {
                procGb = "U"
                modifyGb = "60"
              }
              else
              {
                procGb = "I"
                modifyGb = "50"
              }
            }

            this.pPopupDeputyObj.csID = this.ds_detail.csId
            this.pPopupDeputyObj.prosGb = procGb
            this.pPopupDeputyObj.modifyGb = modifyGb
            this.$refs.popupDeputy.fn_Open()
            break  
        }
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) / 4: 마케팅 동의여부 / 5: 고객ID선택
       *              7: FC 조회 / 8: cdi 고객조회 / 9: FATCA / 10 : 대리인 등록
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        console.log(pData)
        let t_popupObj = {}

        switch (type) {
          case 0:
            console.log('우편번호 callback...')
            // 자택(HOME), 직장(JOB)
            if( pData.btnFlag == 'HOME' ) {
              this.ds_detail.hmadPsno         = pData.vZipcd_head.toString() + pData.vZipcd_tail.toString()         
              this.ds_detail.hmadPsno1        = pData.vZipcd_head
              this.ds_detail.hmadPsno2        = pData.vZipcd_tail
              this.ds_detail.homePsnoAddr     = pData.vZipcd_ubmyundong                // 우편번호주소
              this.ds_detail.homeAddrPsnoSeq  = 0
              this.ds_detail.homeRmnAddr      = pData.v_stand_addr + pData.v_adtn_addr // 상세주소(표준주소) // 상세주소 + 부가주소 
              this.ds_detail.hmadStdztYn      = pData.v_addr_stdzt_yn                  // 표준화여부
              this.ds_detail.homeAddrBldnNo   = pData.v_bldg_no                        // 상세주소암호화
              this.ds_detail.homeStdztDtlad   = pData.v_stand_addr
              this.ds_detail.homeAdtnAddr     = pData.v_adtn_addr                      // 부가주소

              this.ds_detailBfc.hmadPsno         = this.ds_detail.hmadPsno         
              this.ds_detailBfc.hmadPsno1        = this.ds_detail.hmadPsno1        
              this.ds_detailBfc.hmadPsno2        = this.ds_detail.hmadPsno2        
              this.ds_detailBfc.homePsnoAddr     = this.ds_detail.homePsnoAddr     
              this.ds_detailBfc.homeAddrPsnoSeq  = this.ds_detail.homeAddrPsnoSeq  
              this.ds_detailBfc.homeRmnAddr      = this.ds_detail.homeRmnAddr      
              this.ds_detailBfc.hmadStdztYn      = this.ds_detail.hmadStdztYn      
              this.ds_detailBfc.homeAddrBldnNo   = this.ds_detail.homeAddrBldnNo   
              this.ds_detailBfc.homeStdztDtlad   = this.ds_detail.homeStdztDtlad   
              this.ds_detailBfc.homeAdtnAddr     = this.ds_detail.homeAdtnAddr
              
              this.fn_validErrRemoveRef(this.$refs['hmadPsno']) // css error 검출 삭제
              this.fn_validErrRemoveRef(this.$refs['edt_homePsnoAddr']) // css error 검출 삭제
              this.fn_validErrRemoveRef(this.$refs['etd_homeRmnAddr']) // css error 검출 삭제
            } else if( pData.btnFlag == 'JOB' ) {
              this.ds_detail.offcAddrPsno        = pData.vZipcd_head.toString() + pData.vZipcd_tail.toString()         
              this.ds_detail.offcAddrPsno01      = pData.vZipcd_head
              this.ds_detail.offcAddrPsno02      = pData.vZipcd_tail
              this.ds_detail.offcPsnoAddr        = pData.vZipcd_ubmyundong                 
              this.ds_detail.offcAddrPsnoSeq     = 0
              this.ds_detail.offcRmnAddr         = pData.v_stand_addr + pData.v_adtn_addr               
              this.ds_detail.offcAddrStdztYn     = pData.v_addr_stdzt_yn                   
              this.ds_detail.offcAddrBldnNo      = pData.v_bldg_no                         
              this.ds_detail.offcStdztDtlad      = pData.v_stand_addr
              this.ds_detail.offcAdtnAddr        = pData.v_adtn_addr                      

              this.ds_detailBfc.offcAddrPsno     = this.ds_detail.offcAddrPsno        
              this.ds_detailBfc.offcAddrPsno01   = this.ds_detail.offcAddrPsno01      
              this.ds_detailBfc.offcAddrPsno02   = this.ds_detail.offcAddrPsno02      
              this.ds_detailBfc.offcPsnoAddr     = this.ds_detail.offcPsnoAddr        
              this.ds_detailBfc.offcAddrPsnoSeq  = this.ds_detail.offcAddrPsnoSeq     
              this.ds_detailBfc.offcRmnAddr      = this.ds_detail.offcRmnAddr         
              this.ds_detailBfc.offcAddrStdztYn  = this.ds_detail.offcAddrStdztYn     
              this.ds_detailBfc.offcAddrBldnNo   = this.ds_detail.offcAddrBldnNo      
              this.ds_detailBfc.offcStdztDtlad   = this.ds_detail.offcStdztDtlad      
              this.ds_detailBfc.offcAdtnAddr     = this.ds_detail.offcAdtnAddr   
              
              // css error 검출 삭제
              this.fn_validErrRemoveRef(this.$refs['offcAddrPsno'])     
              this.fn_validErrRemoveRef(this.$refs['edt_offcPsnoAddr']) 
              this.fn_validErrRemoveRef(this.$refs['edt_offcRmnAddr']) 
            } else if( pData.btnFlag == 'ETC' ) { // 신탁 기타주소
            
              // this.ds_detail.offcAddrPsno        = pData.vZipcd_head.toString() + pData.vZipcd_tail.toString()         
              this.ds_detail.etcAddrPsno1       = pData.vZipcd_head                       // 기타주소우편번호1
              this.ds_detail.etcAddrPsno2       = pData.vZipcd_tail                       // 기타주소우편번호2
              this.ds_detail.etcPsnoAddr        = pData.vZipcd_ubmyundong                 // 기타우편주소(all)
              this.ds_detail.etcAddrPsnoSeq     = 0
              this.ds_detail.etcRmnAddr         = pData.v_stand_addr + pData.v_adtn_addr // 상세주소(표준주소) // 상세주소 + 부가주소 
              this.ds_detail.eAddrStdYn         = pData.v_addr_stdzt_yn                   // 표준화여부
              this.ds_detail.etcAddrBldnNo      = pData.v_bldg_no                         // 상세주소암호화
              this.ds_detail.etcStdztDtlad      = pData.v_stand_addr                      // 상세주소 (고객이 직업입력한 상세주소)	
              this.ds_detail.etcAdtnAddr        = pData.v_adtn_addr                      // 부가주소

              this.ds_detailBfc.etcAddrPsno1    = this.ds_detail.etcAddrPsno1  
              this.ds_detailBfc.etcAddrPsno2    = this.ds_detail.etcAddrPsno2  
              this.ds_detailBfc.etcPsnoAddr     = this.ds_detail.etcPsnoAddr   
              this.ds_detailBfc.etcAddrPsnoSeq  = this.ds_detail.etcAddrPsnoSeq
              this.ds_detailBfc.etcRmnAddr      = this.ds_detail.etcRmnAddr    
              this.ds_detailBfc.eAddrStdYn      = this.ds_detail.eAddrStdYn    
              this.ds_detailBfc.etcAddrBldnNo   = this.ds_detail.etcAddrBldnNo 
              this.ds_detailBfc.etcStdztDtlad   = this.ds_detail.etcStdztDtlad 
              this.ds_detailBfc.etcAdtnAddr     = this.ds_detail.etcAdtnAddr   
              
              //팝업에서 기타주소가 입력됐으면 total기타주소 edt 초기화하고 팝업값 setting
              if (!TSCommUtil.gfn_isNull(this.ds_detail.etcPsnoAddr))
              {
                this.g_edt_etcAddrTotal = ''
                var etcAddrPsno1 	= this.ds_detail.etcAddrPsno1
                var etcAddrPsno2 	= this.ds_detail.etcAddrPsno2
                var etcPsnoAddr 	= this.ds_detail.etcPsnoAddr 
                var etcRmnAddr 		= this.ds_detail.etcRmnAddr  // 상세주소 + 부가주소 
                var etcAddrBldnNo = this.ds_detail.etcAddrBldnNo
                
                if (this.gErpYn == "N"){
                  // this.g_edt_etcAddrTotal = etcAddrPsno1+"-"+etcAddrPsno2+" "+etcPsnoAddr+" "+etcAddrBldnNo+" "+etcRmnAddr
                  this.g_edt_etcAddrTotal = etcAddrPsno1+"-"+etcAddrPsno2+" "+etcPsnoAddr+" "+etcRmnAddr
                } else {
                  this.g_edt_etcAddrTotal = etcAddrPsno1+"-"+etcAddrPsno2+" "+etcPsnoAddr+" "+etcAddrBldnNo+" "+etcRmnAddr
                }
              }
            }
            break
          case 1:
            break
          case 2:
            break
          case 3:
            if( pData.btnFlag == 'invt' ) { // 권유자
              this.ds_detail.invtMnEmno = pData.staf_eno
              this.ds_detail.invtMnEmnoNm = pData.bfcer_eply_nm
            } else if( pData.btnFlag == 'mngr' ) { // 관리자
              this.ds_detail.mngrEmno = pData.staf_eno
              this.ds_detail.mngrEmnoNm = pData.bfcer_eply_nm
            } else if( pData.btnFlag == 'fn' ) { // 권유FC
              this.ds_detail.fnlDsgnEmno = pData.staf_eno
              this.ds_detail.fnlDsgnEmnoNm = pData.bfcer_eply_nm
            }
            console.log('직원번호(직원검색) callback...')
            break
          case 4:
            console.log('마케팅 동의여부 callback...')
            // if ( pData.btnClickType == 'cancel' ) // 팝업창 마지막 이벤트가 닫기일 경우
            //   return false
            
            this.ds_detail.trCsYn           = pData.trCsYn          // 거래고객여부
            //this.ds_detail.mktnWcnstTyp     = pData.mktnWcnstTyp          // 마케팅요약동의여부 ASR250300203 / 신탁 마케팅 동의서 개정 사항 반영
            this.ds_detail.mktnCnsntTrm     = pData.mktnCnsntTrm    // 마케팅동의기간
            this.ds_detail.valdEndDate      = pData.valdEndDate.replaceAll("-","")  // 유효종료일자
            this.ds_detail.mktnInvtTelYn    = pData.mktnInvtTelYn   // 마케팅권유전화여부
            this.ds_detail.mktnInvtSsYn     = pData.mktnInvtSsYn    // 마케팅권유SMS여부
            this.ds_detail.mktnInvtEmailYn  = pData.mktnInvtEmailYn // 마케팅권유이메일여부
            this.ds_detail.mktnInvtMailYn   = pData.mktnInvtMailYn  // 마케팅권유우편여부
            this.ds_detail.mktnCnsntDate    = pData.mktnCnsntDate   // 마케팅 동의일자(=sysdate)
            break
          case 5:
            break
          case 7:
            console.log('fc callback...')
            this.ds_detail.fnlDsgnEmno = pData.fcEmno
            this.ds_detail.fnlDsgnEmnoNm = pData.fcNm
            break
          case 8:
            console.log('cdi callback...')
            this.cdiSelectObj = {} // fn_cdiSelect 함수에서 사용할 객체 초기화
            this.cdiSelectObj.cnm = pData.cnm
            this.cdiSelectObj.custId = pData.custId 

            let cnm = pData.cnm
            let custStatCd = pData.custStatCd
            let custId = pData.custId
            let spltDstyStatC = pData.spltDstyStatC //분리파기상태
            
            if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(spltDstyStatC) ) ) {
              if ( spltDstyStatC != "10") {		
                  this.fn_AlertPopup(0,{content: '접근제한 고객입니다. ERP시스템에서 BP ID 재등록하시기 바랍니다.'}) 
                  //application.afrm_BottomFrame.form.sta_callBackMsg.set_text( "접근제한 고객입니다. ERP시스템에서 BP ID 재등록하시기 바랍니다" );
                  this.enabledList.btn_confirm = false  // 확인비활성화
                  this.fn_init();  // 초기화, 입력항목 all 활성화(ERP추가)
                  return false
              }
            }
              
            if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(cnm) ) ) //("고객조회결과있음");
            {
              if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(custStatCd) ) && custStatCd != "10" ) {
                this.fn_AlertPopup(0,{ 
                  content: "현재 U-Portal 고객상태가 비정상입니다. 그래도 업무를 진행하시겠습니까?"
                  , confirm: true
                  , confirmFunc: this.fn_cdiSelect
                })
              } else {
                this.fn_cdiSelect()
              }
            } else { //("고객조회결과없음");
              this.ds_cdiList = []
            }
            break
          case 9:
            console.log('fatca callback...')
            this.rstFtacaPopObj = pData
            if ( pData.isConfirm == true ) { // fatca 팝업 확인 버튼 클릭 이벤트
              this.fatcaCheckCallBackFunc()
            } else { // fatca 팝업 닫기 버튼 클릭 이벤트
              this.fn_endProc()
            }
            break
          case 10: // 대리인 등록
            this.ds_deputyObj.agntCsId = ( pData.returnType ) ? pData.agntCsId : '' // 대리인고객ID
            this.ds_deputyObj.prhsRel = ( pData.returnType ) ? pData.prhsRel : '' // 본인관계
            this.ds_deputyObj.accOpngAthzYn = ( pData.returnType ) ? pData.accOpngAthzYn : '' // 계좌개설위임여부
            this.ds_deputyObj.appnIndcAthzYn = ( pData.returnType ) ? pData.appnIndcAthzYn : ''
            this.ds_deputyObj.bktrStipAthzYn = ( pData.returnType ) ? pData.bktrStipAthzYn : '' // 운용지시위임여부
            this.ds_deputyObj.accFtrnStipAthzYn = ( pData.returnType ) ? pData.accFtrnStipAthzYn : '' // 은행이체약정위임여부
            this.ds_deputyObj.trstContAthzYn = ( pData.returnType ) ? pData.trstContAthzYn : '' // 신탁계약위임여부
            this.ds_deputyObj.trstContChngAthzYn = ( pData.returnType ) ? pData.trstContChngAthzYn : '' // 신탁계약변경위임여부
            this.ds_deputyObj.trstContTmtlAthzYn = ( pData.returnType ) ? pData.trstContTmtlAthzYn : '' // 신탁계약해지위임여부
            this.ds_deputyObj.etcYn = ( pData.returnType ) ? pData.etcYn : '' // 기타여부
            this.ds_deputyObj.etcExpl = ( pData.returnType ) ? pData.etcExpl : '' // 기타설명
            this.ds_deputyObj.agntNm = ( pData.returnType ) ? pData.cnm : '' // 대리인고객명 
            this.ds_deputyObj.chk_AgntDelYn = ( pData.returnType ) ? pData.agntDelYn : '' // 대리인삭제여부
            this.ds_deputyObj.rcno = ( pData.returnType ) ? pData.rcno : '' // 실명번호
            this.ds_deputyObj.rcnoKindTc = ( pData.returnType ) ? pData.rcnoKindTc : '' // 실명번호 종류
            this.ds_deputyObj.mvphBusiMnNo = ( pData.returnType ) ? pData.mvphBusiMnNo : '' // 휴대폰 번호1
            this.ds_deputyObj.mvphPofn = ( pData.returnType ) ? pData.mvphPofn : '' // 휴대폰 번호2
            this.ds_deputyObj.mvphSeq = ( pData.returnType ) ? pData.mvphSeq : '' // 휴대폰 번호3
            this.ds_deputyObj.homeRmnAddr = ( pData.returnType ) ? pData.homeRmnAddr : '' // 주소

            

            this.ds_detail01.lastChngMnUsid = this.ds_deputyObj.lastChngMnUsid
            this.ds_detail01.prhsRel = this.ds_deputyObj.prhsRel
            this.ds_detail01.accOpngAthzYn = this.ds_deputyObj.accOpngAthzYn
            this.ds_detail01.bktrStipAthzYn = this.ds_deputyObj.bktrStipAthzYn
            this.ds_detail01.accFtrnStipAthzYn = this.ds_deputyObj.accFtrnStipAthzYn 
            this.ds_detail01.trstContAthzYn = this.ds_deputyObj.trstContAthzYn
            this.ds_detail01.trstContChngAthzYn = this.ds_deputyObj.trstContChngAthzYn 
            this.ds_detail01.trstContTmtlAthzYn = this.ds_deputyObj.trstContTmtlAthzYn
            this.ds_detail01.etcYn = this.ds_deputyObj.etcYn 
            this.ds_detail01.etcExpl = this.ds_deputyObj.etcExpl 
            this.ds_detail01.agntCsId = this.ds_deputyObj.agntCsId
            this.ds_detail01.agntCnm = this.ds_deputyObj.agntNm
            break
        }
      },
      
      /**********************************************************************************
       * CDI호출 : u-Portal 로 고객조회 interface 호출 ( 구 : fn_cdi_select (SC))
       **********************************************************************************/
      async fn_cdiSelect ()
      {
        this.ds_cdiList = []
        // this.ds_search01.addRow();
        // this.ds_cuCust.addRow();
        // this.ds_cdiId.addRow();
        let cnm  = TSCommUtil.gfn_replace_value( this.cdiSelectObj.cnm )
        let custId  = TSCommUtil.gfn_replace_value( this.cdiSelectObj.custId )
        this.ds_detail.cnm = cnm
      
        //필수체크. 필수인 컴포넌트를 ','으로 구분하여 나열한다.
        //컴포넌트명이 아닌 풀경로인점 유의 
        //CDI검색을 위한 키값(실명번호구분은 로직상 필요함CDI키값은 아님.)
        this.ds_search01 = Object.assign({}, TSDataSet.ds_search01)
        this.ds_search01.rcnoKindTc = TSCommUtil.gfn_replace_value( this.ds_search.rcnoKindTc )	 // 실명확인번호종류구분코드
        this.ds_search01.rcno = TSCommUtil.gfn_replace_value( this.ds_search.rcno )			         // 실명확인번호
        this.ds_search01.cnm = cnm            			          	 // 고객명
        this.ds_search01.csId = custId         				           // 고객Id
        
        //동기화 안하는 항목들
        this.ds_search01.cdinProfrCnsntYn = TSCommUtil.gfn_replace_value( this.ds_detail.cdinProfrCnsntYn )    // 신용정보제공동의여부
        this.ds_search01.mktnCnsntYn =  	TSCommUtil.gfn_replace_value( this.ds_detail.mktnCnsntYn )          // 마케팅동의여부
        this.ds_search01.mktnCnsntTrm =  	TSCommUtil.gfn_replace_value( this.ds_detail.mktnCnsntTrm )     // 마케팅동의기간(hid)
        this.ds_search01.incMnTc = 			TSCommUtil.gfn_replace_value( this.ds_detail.incMnTc )            	  // 소득자구분
        this.ds_search01.lvlhTc = 			TSCommUtil.gfn_replace_value( this.ds_detail.lvlhTc )           		  // 생계형구분
        this.ds_search01.mnCntcPlcTc = 		TSCommUtil.gfn_replace_value( this.ds_detail.mnCntcPlcTc )          // 주연락처구분코드(구 주연락처)
        this.ds_search01.ioAmtTrPpsC = 		TSCommUtil.gfn_replace_value( this.ds_detail.rsdnNatC )             // 거주국가코드
        
        // t_trnstId = "selectTFCP10000A";
        // var sURL          = "svc::"+t_trnstId+".do";	// svc = http://localhost:8080/
        // var sInDatasets   = "tfcp10000=ds_search01:";
        // var sOutDatasets  = "ds_cdiId=tfcp10000RstCdiforPEss ds_detail=tfcp10000Rst" ; // csId,cnm / cdi필수값
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBackCdi";
        /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(t_trnstId, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);

        this.g_eaiId = "C392_F10600138_S" //selectTFCP10000A
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'C392_F10600138_S' // selectTFCP10000A
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600138_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_search01)
        //await TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackCdi)
        await TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackCdi)
      },

      /**********************************************************************************
       * gfn_callService 의 콜백 함수 : transaction 응답처리 ( 구 : fn_call_back_cdi_select (SC))  
       **********************************************************************************/
      fn_callBackCdi ( res )
      {
        //ds_cdiId=tfcp10000RstCdiforPEss ds_detail=tfcp10000Rst"
       
        const resData = Object.assign({}, res.data)
        Object.assign(this.ds_detail, resData)
        if ( TSCommUtil.gfn_length(resData.cdisettingUtilforPInstEss) > 0 )
          Object.assign(this.ds_cdiId, resData.cdisettingUtilforPInstEss[0])
        const t_eaiId = res.commHeaderVO.eaiId

        if ( t_eaiId == "C392_F10600138_S" ) {	// selectTFCP10000A
          if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(this.ds_detail.cnm) ) ){ // CDI 기존 고객 값이 존재

            // CDI조회 후 분리파기상태 체크
            let spltDstyStatC = TSCommUtil.gfn_trim(this.ds_detail.spltDstyStatC)
            if (spltDstyStatC == 11 || spltDstyStatC == 12 || spltDstyStatC == 13) {
              this.fn_AlertPopup(0,{content: '접근제한 고객입니다. ERP시스템에서 BP ID 재등록하시기 바랍니다.'}) 
              this.fn_commonAfterOnload(false);         // 입력항목 all 활성화(ERP추가)
              this.enabledList.btn_confirm = false      // 확인비활성화
            } else {
              // 수정상태이므로 : 확인/ 취소 활성화
              this.enabledList.btn_searchList = false   // 조회비활성화
              this.enabledList.btn_update = false       // 수정비활성화
              this.enabledList.btn_confirm = true       // 확인활성화
              this.enabledList.btn_cancel = true        // 취소활성화
              this.enabledList.btn_init = false         // 초기화비활성화
              
              let cdiCsId = TSCommUtil.gfn_replace_value (this.ds_cdiId.custCustId)
              this.gCsId = this.ds_detail.csId          // 글로벌변수 = 공통 고객번호 
              this.ds_detail.csId = this.gCsId
              
              if ( !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(cdiCsId) ) ) { // CDI 값이 존재
                //this.gNewMemberYn = "N"   ody
                // 수정진행여부 "Y" 이면 수정버튼 비활성화
                Object.assign(this.ds_detailBfc , this.ds_detail ) // this.ds_detailBfc.copyData(this.ds_detail)
                this.enabledList.edt_cnm = false // 수정 시 고객명 비활성화

                // 자택주소
                if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.hmadPsno1) ) )
                  this.ds_detail.hmadPsno = this.ds_detail.hmadPsno1.toString() + this.ds_detail.hmadPsno2.toString()
                else
                  this.ds_detail.hmadPsno = ''

                // 직업주소
                if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.offcAddrPsno01) ) )
                  this.ds_detail.offcAddrPsno = this.ds_detail.offcAddrPsno01.toString() + this.ds_detail.offcAddrPsno01.toString()
                else
                  this.ds_detail.offcAddrPsno = ''

                // 신규 고객의 경우, 마케팅 동의 정보가 없을 경우 마케팅 항목에 'N' 아니오 setting
                if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.mktnCnsntYn) ) )
                {
                  this.ds_detail.mktnCnsntYn = ""
                }
                // 마케팅동의여부가 'Y'임에도 불구하고 유효종료일자가 '99991231'인 데이터 -> 마케팅동의기간(mktTerm)에 '99'년을 세팅					
                // 마케팅동의기간 세팅 (CDI조회) : 유효종료일자(년도) - 마케팅동의일자(년도)
                let mktnCnsntTrm = ""; // 마케팅동의기간
      
                if ( !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.valdEndDate) ) ){
                  if ( TSCommUtil.gfn_trim( this.ds_detail.valdEndDate) == "99991231" ){
                    this.ds_detail.mktnCnsntTrm = '99'
                  } else {
                    mktnCnsntTrm = parseInt(this.ds_detail.valdEndDate.substr(0, 4)) 
                          - parseInt(this.ds_detail.mktnCnsntDate.substr(0, 4));
                    this.ds_detail.mktnCnsntTrm = mktnCnsntTrm
                  }
                } else {
                  this.ds_detail.mktnCnsntTrm = mktnCnsntTrm
                }

                this.fn_fatcaSelect(); // fatca징구여부
              } else { // 삼성생명에 등록되지 않은 고객입니다.신규 등록
                // 데이터 없으면 : 확인 / 취소 / 초기화 활성화(여기를 타고있음)
                this.gNewMemberYn = "Y"; //조회 전 신규고객여부 setting (ERP추가)
                this.fn_commonAfterOnload(true); // 입력항목 all 활성화(ERP추가)
                this.ds_detail01 = Object.assign({}, TSDataSet.ds_detail01)// 대리인초기화
                this.enabledList.btn_searchList = false // 조회비활성화
                this.enabledList.btn_update = false // 수정비활성화
                this.enabledList.btn_confirm = true // 확인활성화
                this.enabledList.btn_cancel = true // 취소활성화
                this.enabledList.btn_init = true // 초기화활성화
                this.enabledList.rdo_mktnCnsntYn = true
                
                if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.mktnCnsntYn) ) )
                {
                  this.ds_detail.mktnCnsntYn = ''
                  this.rdo_mktnCnsntYn_onitemclick()
                }
                this.enabledList.rdo_mktnCnsntYn = true
                this.fn_rcnoCheck(); // 여기서 실명확인번호로 생일/성별 설정.
                if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_search.rcno) ) )
                {
                  this.ds_search.rcnoKindTc = '01' // 주민번호
                }
      
                this.initDom()
                this.g_eaiId = "C392_F10600136_S" // insertTFCP10000
              }
            } //분리파기else_end
          }
          else // CDI 값 없는 신규고객
          {
            // 데이터 없으면 : 확인 / 취소 / 초기화 활성화
            this.ds_detail01 = Object.assign({}, TSDataSet.ds_detail01 ) // 대리인초기화
            this.enabledList.btn_searchList = false // 조회비활성화
            this.enabledList.btn_update = false // 수정비활성화
            this.enabledList.btn_confirm = true // 확인활성화
            this.enabledList.btn_cancel = true // 취소활성화
            this.enabledList.btn_init = true // 초기화활성화
            this.enabledList.rdo_mktnCnsntYn = true
            this.fn_rcnoCheck(); // 여기서 실명확인번호로 생일/성별 설정.
            this.initDom() 

            if ( TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_search.rcno ) ) )
              this.ds_search.rcnoKindTc = '01' // 주민번호
            if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.mktnCnsntYn ) ) )
            {
              this.ds_detail.rdo_mktnCnsntYn = 'N'
              this.rdo_mktnCnsntYn_onitemclick();
            }
            this.g_eaiId = "C392_F10600136_S" // insertTFCP10000
          }
        }

        /**fatca 징구여부 inserface(COMC0142) */
        if ( t_eaiId == "selectTPUP95050B" ) {
          if ( !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detailFatca.csId ) ) ) // return 값이 존재하면
          {
            // 징구여부값을 화면에 setting 한다. : chk_sbmtYn
            if ( !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detailFatca.sbmtYn ) ) )
            {
              // fatca징구여부조회
              this.gSbmtYn = this.ds_detailFatca.sbmtYn
              if ( gSbmtYn == "Y" )
              {
                this.rdo_sbmtYn = 'chk_sbmtYn' // ASIS => this.chk_sbmtYn = true // 징구 , this.chk_sbmtYn1 = false // 미징구
              }else{
                this.rdo_sbmtYn = 'chk_sbmtYn1'
              }
            }
          }
        }
        
      },

      /*=========================================================================
      /* 메소드 명 : fn_cdiSelectId(svcid, cd, err_msg)                            
      /* 내용설명  : CDI 등록고객리스트조회
      /* Return 값 :                                                             
      =========================================================================*/
      fn_cdiSelectId ()
      {
        // this.ds_search.setColumn(0, "rcnoKindTc", this.ds_detail.rcnoKindTc);
        // this.ds_search.setColumn(0, "rcno"		, this.ds_detail.rcno);
        
        // /*서비스 호출시 정보 세팅 */
        // t_trnstId = "selectTFCP10000ListA"; 
        
        // var sURL          = "svc::"+t_trnstId+".do"; // svc = http://localhost:8080/
        // var sInDatasets   = "tfcp10000=ds_search:"; // (고객기본)
        // var sOutDatasets  = "ds_cdiList=tfcp10000ListA";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBackSelectId"; // fn_call_back_select_id
        
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(t_trnstId, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);

        this.g_eaiId = "C392_F10600139_S" //selectTFCP10000ListA
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'C392_F10600139_S' // selectTFCP10000ListA
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600139_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_search)
        // TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackSelectId)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackSelectId)
        
      },


      /**********************************************************************************
       * gfn_callService의 콜백 함수 : fn_callBackSelectId 응답처리
       **********************************************************************************/
      fn_callBackSelectId ( res )
      {
        
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return false
        }

        const t_eaiId = res.commHeaderVO.eaiId
        // Object.assign ( this.ds_cdiList, res.data.cmbfcerCustKnbVO ) 
        if ( t_eaiId == "C392_F10600139_S" )
        {
          // 고객리스트("SN") 데이터가 존재하면 마케팅동의 입력항목 비활성화
          let t_data = resData.tpup95290
          if( TSCommUtil.gfn_length(resData.cmbfcerCustKnbVO) > 0 ) 
          {
            this.ds_cdiList = resData.cmbfcerCustKnbVO
            // rdo_mktnCnsntYn
            this.enabledList.rdo_mktnCnsntYn = false

            /* 신용동의 여부 계약체결
             * 디폴트가 Y로 되어 있어 생략하기로함. 
            //this.rdo_cdinProfrCnsntYn.set_value("Y"); // 계약체결 완료
            */
          }
          // var oArg           = {pID:"TFCP10003", ds_list:this.ds_cdiList
          //                                     , rcnoKindTc:this.ds_detail.rcnoKindTc
          //                                     , rcno:this.ds_detail.rcno }; //팝업화면으로 보낼 파라미터.
          // var sOption        = "autosize=true,title=true";           		  //팝업열때 옵션
          // var sPopupCallBack = "fn_popupAfter";                      		  //팝업화면에서 보낸 파라미터 받는곳
          //this.gfn_openPopup("TFCP10003","fiduciary.cust::TFCP10003.xfdl",oArg,sOption,sPopupCallBack);		
          
          for ( let idx in this.ds_cdiList ) {
            console.log(this.ds_cdiList[idx])
            // gfn_isNull(custStatCd) ? '' : custStatCd=='10' ? '정상':'비정상'
            let item = this.ds_cdiList[idx]
            this.ds_cdiList[idx].dob = TSCommUtil.gfn_dateReplace(1, item.dob)
            this.ds_cdiList[idx].custStatCdClass = TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(item.custStatCd)) ? '' : item.custStatCd=='10' ? 'possible':'impossible'
            this.ds_cdiList[idx].custStatCdText = TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(item.custStatCd)) ? '' : item.custStatCd=='10' ? '정상':'비정상'
            this.ds_cdiList[idx].spltDstyStatCClass = TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(item.spltDstyStatC)) ? '' : item.spltDstyStatC=='10' ? 'possible':'impossible'
            this.ds_cdiList[idx].spltDstyStatCText = TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(item.spltDstyStatC)) ? '' : item.spltDstyStatC=='10' ? '정상':'비정상'
          }
          
          this.pPopupCdiObj.ds_cdiList = this.ds_cdiList
          this.pPopupCdiObj.rcno       = this.ds_search.rcno
          this.pPopupCdiObj.rcnoKindTc = this.ds_search.rcnoKindTc
          this.fn_OpenPopup(8) // 팝업오픈
        }
      },

      /**********************************************************************************
       *  FATCA 리턴타입 setting : FATCA 처리 검증 후
       *  FATCA 팝업의 확인을 눌렀을 경우 호출
       *  등록의 경우 fatca 팝업이 호출되며 무통장 생성후 종료 프로세스로 넘어감
       *  수정 또는 등록임에도 fatca 
       **********************************************************************************/
      async fatcaCheckCallBackFunc ( strValue ) 
      {
        if ( strValue == "2" ) {
          // this.fn_AlertPopup(0,{content: 'FATCA/CRS확인 기발급고객입니다.'}) 
          TSCommUtil.gfn_validate(this, 'FATCA/CRS확인 기발급고객입니다.')
          //application.afrm_BottomFrame.form.sta_callBackMsg.set_text( "FATCA/CRS확인 기발급고객입니다" ); // 화면초기화 후 메세지 후 초기화
          // this.enabledList.btn_popupTpup95050 = false // fatca비활성화
        }
        
        // fatca징구내역조회
        await this.fn_fatcaSelect(); 
        
        /**
         * FATCA 전자서식 로직 수정
         * strValue 파라미터가 null 인 경우에 fatca 팝업 확인버튼 후 
         * 메인페이지 콜백으로 넘어온 경우 FATCA 팝업에서 메인페이지 fn_Popup_CallBack 에서 해당 함수 fatcaCheckCallBackFunc 를 호출한 경우임. 
         * FATCA 팝업에서 확인 버튼을 눌렀을 경우에판 FATCA 전자서식을 호출한다.
         * fn_createBnk999 함수에서 징구여부에 따라 생성시도 선택
         */
        if ( TSCommUtil.gfn_isNull ( strValue ) ) {
          // 종합계좌를 개설했을 경우만 열림.
          if ( this.ds_detail.noTacct == false 
                && this.gNewMemberYn == "Y" 
                && !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.tacno) ) 
              ) 
          {
            this.fn_addFatcaForm(); // Fatca 전자서식 폼 리스트 추가
          }
        }

        // 무통장 생성 및 프로세스 종료
        await this.fn_createBnk999() // 무통장 생성
        this.fn_endProc() // 프로세스 종료
      },

      /**********************************************************************************
       * fatca호출 : u-Portal fatca 징구여부 check (구 : fn_fatca_select)
      **********************************************************************************/
      async fn_fatcaSelect ()
      {
        // 고객ID가 null 이면 처리불가함
        if ( TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.csId ) ) )
        {
          this.fn_AlertPopup(0,{content: '고객ID가 필요합니다. 데이터 조회 후 처리바랍니다.'}) 
          return 
        }
        
        this.ds_searchFatca = Object.assign({}, TSDataSet.ds_searchFatca ) // 조회조건(csId / 법인여부(corpYn == 'N'))
        this.ds_detailFatca = Object.assign({}, TSDataSet.ds_detailFatca )
        
        this.ds_searchFatca.csId = this.ds_detail.csId
        this.ds_searchFatca.corpYn = "N"
        
        // t_trnstId = "selectTPUP95050B";
        // var sURL          = "svc::"+t_trnstId+".do";	// svc = http://localhost:8080/
        // var sInDatasets   = "tpup95050VO=ds_searchFatca:";
        // var sOutDatasets  = "ds_detailFatca=tpup95050Rst";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBackFatca";
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(t_trnstId, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);

        this.g_eaiId = "C392_F10600202_S" //selectTPUP95050B
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'C392_F10600202_S' // selectTPUP95050B
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600202_S'
        this.eaiCommObj.params = this.ds_searchFatca
        // await TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackFatca)
   
        const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return 
        }
        
        const t_eaiId = res.commHeaderVO.eaiId
        this.ds_detailFatca = Object.assign({}, res.data)

        /**fatca 징구여부 inserface(COMC0142) */
        if ( t_eaiId == "C392_F10600202_S" ) {
          // 징구여부값을 화면에 setting 한다. : chk_sbmtYn
          if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detailFatca.sbmtYn ) ) )
          {
            this.rdo_sbmtYn = '' // ASIS => this.chk_sbmtYn = '', this.chk_sbmtYn1 = ''
            // fatca징구여부조회
            this.gSbmtYn = "";
            this.gSbmtYn = this.ds_detailFatca.sbmtYn
            if ( this.gSbmtYn == "Y" ){
              this.rdo_sbmtYn = 'chk_sbmtYn' // ASIS => this.chk_sbmtYn = true , this.chk_sbmtYn1 = false
              // this.enabledList.btn_popupTpup95050 = false  // fatca비활성화
            }else{
              this.rdo_sbmtYn = 'chk_sbmtYn1'
              // this.enabledList.btn_popupTpup95050 = true  // fatca 활성화
            }
          }
        }

      },

      /******************************************************************************
       * Function명 : fn_RecordConfirm
       * 설명       : 녹취 확인
       ******************************************************************************/
      fn_RecordConfirm() {
        console.log('fn_RecordConfirm.....')
        this.$refs.tsHeader.fn_Record()
      },

  

      /**********************************************************************************
       * Function명 : rdo_mnCntcPlcTc_onitemclick
       * 설명       : 주연락처 구분에 따른 필수값, 삭제버튼, 화면Notice 컨트롤
                      this.cmb_modifyGb == "70" 은 수정
      **********************************************************************************/
      rdo_mnCntcPlcTc_onitemclick ()
      {
        // 필수항목값 표시 초기화 = insert / update 동일 
        this.homeEmphasis = ''
        this.jobEmphasis = ''
        this.eMailEmphasis = ''
        this.etcAddrEmphasis = ''

        // 메시지 초기화
        this.visibleList.sta_notice1 = false
        this.visibleList.sta_notice2 = false
        this.visibleList.sta_notice11 = false
        
        // 체크박스 초기화 = update
        this.chk_homeAddr =  false     // 자택주소
        this.chk_homeTel =  false      // 자택전화번호
        this.chk_homeFax =  false      // FAX 번호
        this.chk_homeCel =  false      // 휴대폰
        this.chk_offcAddr =  false     // 직장주소
        this.chk_offcTel =  false      // 직장전화번호
        this.chk_homeEmail =  false    // 이메일
        this.chk_etcAddr =  false      // 신탁별도주소

        // 체크박스 show 초기화 = update
        this.visibleList.chk_homeAddr =  false     // 자택주소
        this.visibleList.chk_homeTel =  false      // 자택전화번호
        this.visibleList.chk_homeFax =  false      // FAX 번호
        this.visibleList.chk_homeCel =  false      // 휴대폰
        this.visibleList.chk_offcAddr =  false     // 직장주소
        this.visibleList.chk_offcTel =  false      // 직장전화번호
        this.visibleList.chk_homeEmail =  false    // 이메일
        this.visibleList.chk_etcAddr =  false      // 신탁별도주소

        if ( this.ds_detail.mnCntcPlcTc == "1" && this.enabledList.rdo_mnCntcPlcTc != false ) // 자택
        {
          this.visibleList.sta_notice1 = true
          this.visibleList.sta_notice11 = false
          this.visibleList.sta_notice2 = false

          if ( this.cmb_modifyGb == "70" ) { 
            // input, button enable, disalbe
            this.enabledList.btn_popupTpup95100_1 = true // 자택주소입력팝업 활성화
            this.enabledList.btn_popupTpup95100_2 = false // 직장주소입력팝업 비활성화
            this.enabledList.edt_homeTelArno = true
            this.enabledList.edt_homeTelPofn = true
            this.enabledList.edt_homeTelSeq = true
            this.enabledList.edt_offcTelArno = false
            this.enabledList.edt_offcTelPofn = false
            this.enabledList.edt_offcTelSeq = false
            // 삭제 버튼 show
            this.visibleList.chk_homeAddr = true
            this.visibleList.chk_homeTel = true
            this.visibleList.chk_homeFax = true
            this.visibleList.chk_homeCel = true
          } else {
            this.enabledList.btn_popupTfcp10001 = true // 별도주소는 항상 입력 가능하게 버튼 활성화
            this.enabledList.btn_popupTpup95100_1 = true // 자택주소입력팝업 활성화
            this.enabledList.btn_popupTpup95100_2 = false // 직장주소입력팝업 비활성화
            this.enabledList.edt_homeTelArno = true
            this.enabledList.edt_homeTelPofn = true
            this.enabledList.edt_homeTelSeq = true
            this.enabledList.edt_offcTelArno = false
            this.enabledList.edt_offcTelPofn = false
            this.enabledList.edt_offcTelSeq = false
          }
          this.homeEmphasis = 'emphasis' // 필수값 셋팅
        }
        else if ( this.ds_detail.mnCntcPlcTc == "2" && this.enabledList.rdo_mnCntcPlcTc != false ) // 직장
        {
          this.enabledList.btn_popupTfcp10001 = true // 별도주소는 항상 입력 가능하게 버튼 활성화
          this.visibleList.sta_notice1 = false
          this.visibleList.sta_notice11 = false
          this.visibleList.sta_notice2 = true
   
          //if ( sSvcID == "updateTFCP10000" ) //체크박스제어
          if ( this.cmb_modifyGb == "70" ){
            // input, button enable, disalbe
            this.enabledList.btn_popupTpup95100_1 = false // 자택주소입력팝업 비활성화
            this.enabledList.btn_popupTpup95100_2 = true // 직장주소입력팝업 활성화
            this.enabledList.edt_homeTelArno = false
            this.enabledList.edt_homeTelPofn = false
            this.enabledList.edt_homeTelSeq = false
            this.enabledList.edt_offcTelArno = true
            this.enabledList.edt_offcTelPofn = true
            this.enabledList.edt_offcTelSeq = true
            // 삭제 버튼 show
            this.visibleList.chk_offcAddr = true
            this.visibleList.chk_offcTel = true
          } else {
            this.enabledList.btn_popupTfcp10001 = true // 별도주소는 항상 입력 가능하게 버튼 활성화
            this.enabledList.btn_popupTpup95100_1 = false // 자택주소입력팝업 비활성화
            this.enabledList.btn_popupTpup95100_2 = true // 직장주소입력팝업 활성화
            this.enabledList.edt_homeTelArno = false
            this.enabledList.edt_homeTelPofn = false
            this.enabledList.edt_homeTelSeq = false
            this.enabledList.edt_offcTelArno = true
            this.enabledList.edt_offcTelPofn = true
            this.enabledList.edt_offcTelSeq = true	
          }
          this.jobEmphasis = 'emphasis' // 필수값 셋팅
        }
        else if ( this.ds_detail.mnCntcPlcTc == "3" && this.enabledList.rdo_mnCntcPlcTc != false ) // eMail
        {
          this.enabledList.btn_popupTfcp10001 = true// 별도주소는 항상 입력 가능하게 버튼 활성화
          this.visibleList.sta_notice11 = true
          //if ( enabledList.sSvcID == "updateTFCP10000" ) //체크박스제어
          if ( this.cmb_modifyGb == "70" ){
            // input, button enable, disalbe
            this.enabledList.btn_popupTpup95100_1 = true // 자택주소입력팝업 활성화
            this.enabledList.btn_popupTpup95100_2 = true // 직장주소입력팝업 활성화
            this.enabledList.edt_homeTelArno = true
            this.enabledList.edt_homeTelPofn = true
            this.enabledList.edt_homeTelSeq = true
            this.enabledList.edt_offcTelArno = true
            this.enabledList.edt_offcTelPofn = true
            this.enabledList.edt_offcTelSeq = true
            // 삭제 버튼 show
            this.visibleList.chk_homeEmail = true
          } else {
            this.enabledList.btn_popupTfcp10001 = true // 별도주소는 항상 입력 가능하게 버튼 활성화
            this.enabledList.btn_popupTpup95100_1 = true // 자택주소입력팝업 활성화
            this.enabledList.btn_popupTpup95100_2 = true // 직장주소입력팝업 활성화
            this.enabledList.edt_homeTelArno = true
            this.enabledList.edt_homeTelPofn = true
            this.enabledList.edt_homeTelSeq = true
            this.enabledList.edt_offcTelArno = true
            this.enabledList.edt_offcTelPofn = true
            this.enabledList.edt_offcTelSeq = true	
          }
          
          this.visibleList.sta_notice1 = false // 자택필수입력메세지
          this.visibleList.sta_notice2 = false // 직장필수입력메세지
          this.eMailEmphasis = 'emphasis' // 필수값 셋팅
        }
        else if ( this.ds_detail.mnCntcPlcTc == "4" && this.enabledList.rdo_mnCntcPlcTc != false ) // 별도주소-> 신탁별도주소 입력 팝업버튼 활성화(btn_popupTfcp10001)
        {
          this.enabledList.btn_popupTfcp10001 = true // 별도주소는 항상 입력 가능하게 버튼 활성화
          this.visibleList.sta_notice11 = false
        
          if ( this.cmb_modifyGb == "70" ){
            // input, button enable, disalbe
            this.enabledList.btn_popupTfcp10001 = true // 별도주소는 항상 입력 가능하게 버튼 활성화
            this.enabledList.btn_popupTpup95100_1 = false // 자택주소입력팝업 비활성화
            this.enabledList.btn_popupTpup95100_2 = false // 직장주소입력팝업 비활성화
            this.enabledList.edt_homeTelArno = false
            this.enabledList.edt_homeTelPofn = false
            this.enabledList.edt_homeTelSeq = false
            this.enabledList.edt_offcTelArno = false
            this.enabledList.edt_offcTelPofn = false
            this.enabledList.edt_offcTelSeq = false	
            // 삭제 버튼 show
            this.visibleList.chk_etcAddr = true
          } else {
            this.enabledList.btn_popupTfcp10001 = true // 별도주소는 항상 입력 가능하게 버튼 활성화
            this.enabledList.btn_popupTpup95100_1 = false // 자택주소입력팝업 비활성화
            this.enabledList.btn_popupTpup95100_2 = false // 직장주소입력팝업 비활성화
            this.enabledList.edt_homeTelArno = false
            this.enabledList.edt_homeTelPofn = false
            this.enabledList.edt_homeTelSeq = false
            this.enabledList.edt_offcTelArno = false
            this.enabledList.edt_offcTelPofn = false
            this.enabledList.edt_offcTelSeq = false	
            
          }
          this.visibleList.sta_notice1 = false // 자택필수입력메세지
          this.visibleList.sta_notice2 = false // 직장필수입력메세지
          this.etcAddrEmphasis = 'emphasis' // 필수값 셋팅
        }
      },

      /**********************************************************************************
       * 실명번호 체크
       * 주민번호 OnKillFocus : 실명번호로 고객의 생년월일과 성별 setting
       **********************************************************************************/
      fn_rcnoCheck ()
      {
        let birth = ""
        let sex = ""
        let aYear = ""
        let aDate = TSCommUtil.gfn_toString(this.ds_search.rcno).substr(0, 6);// 주민번호 앞자리만
        let rcnoSex = Number(this.valid_rcnoTwo.substr(0, 1))  //주민번호가 3이상이면 2000년도 출생
        
        // 실명번호 length check
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_search.rcno ) ))
          return 

        // 주민등록번호
        if ( this.ds_search.rcnoKindTc == "01" ) 
        {
          //주민번호가 3이상이면 2000년도 출생
          if( rcnoSex > 2 )
            aYear = "20"
          else 
            aYear = "19"
          birth = aYear + aDate // 생년월일 설정
          //성별구분
          if ( rcnoSex == 1 || rcnoSex == 3 )
            sex = "1"; // 남자
          else if ( rcnoSex == 2 || rcnoSex == 4 )
            sex = "2"; // 여자
          else 
            sex = ""
        } else {
          birth = ""
          sex =  ""
        }
        
        this.ds_detail.btdt = birth
        this.ds_detail.sexTc = sex
      },

      /**********************************************************************************
       * [마케팅] 라디오버튼  함수 : onclick 구현
       **********************************************************************************/
      rdo_mktnCnsntYn_onitemclick ()
      {
        if ( this.ds_detail.mktnCnsntYn == "Y" )
        {
          // this.sta_notice.set_visible(true);
          // this.sta_mktnInvtTelYn.set_visible(true);
          // this.cmb_mktnInvtTelYn.set_visible(true);
          // this.sta_mktnInvtSsYn.set_visible(true);
          // this.cmb_mktnInvtSsYn.set_visible(true);
          // this.sta_mktnInvtEmailYn.set_visible(true);
          // this.cmb_mktnInvtEmailYn.set_visible(true);
          // this.sta_mktnInvtMailYn.set_visible(true);
          // this.cmb_mktnInvtMailYn.set_visible(true);
          
          this.visibleList.mktnCnsntYn = true
          
          // 처리구분값 추가 : 조회일 때 수정 불가
          // var oArg           = {pID:"TFCP10002"
          //                   , mktnCnsntTrm:this.hid_edt_mktnCnsntTrm.value
          //                   , valdEndDate:this.hid_edt_valdEndDate.value
          //                   , mktnInvtTelYn:this.cmb_mktnInvtTelYn.value
          //                   , mktnInvtSsYn:this.cmb_mktnInvtSsYn.value
          //                   , mktnInvtEmailYn:this.cmb_mktnInvtEmailYn.value
          //                   , mktnInvtMailYn:this.cmb_mktnInvtMailYn.value
          //                   , trCsYn:this.hid_edt_trCsYn.value
          //                   , newMemberYn:gNewMemberYn};  //팝업화면으로 보낼 파라미터.
          // var sOption        = "autosize=true,title=true";           		//팝업열때 옵션
          // var sPopupCallBack = "fn_popupAfter";                      		//팝업화면에서 보낸 파라미터 받는곳
          
          // this.alert("dd" + JSON.stringfy(oArg));
          // document.write(typeof oArg + '<br>');
          
          // this.gfn_openPopup("TFCP10002","fiduciary.cust::TFCP10002.xfdl",oArg,sOption,sPopupCallBack);

          this.fn_OpenPopup(4)
        } else if ( this.ds_detail.mktnCnsntYn == "N" )
        {
          this.visibleList.mktnCnsntYn = 'N'
          this.ds_detail.trCsYn = ''  // 거래고객여부
          //this.ds_detail.mktnWcnstTyp = ''  // 마케팅요약동의여부  / 신탁 마케팅 동의서 개정 사항 반영
          this.ds_detail.mktnCnsntTrm = ''  // 마케팅동의기간
          this.ds_detail.valdEndDate = ''  // 유효종료일자
          this.ds_detail.mktnInvtTelYn = 'N'  // 마케팅권유전화여부
          this.ds_detail.mktnInvtSsYn = 'N'  // 마케팅권유SMS여부
          this.ds_detail.mktnInvtEmailYn = 'N'  // 마케팅권유이메일여부
          this.ds_detail.mktnInvtMailYn = 'N'  // 마케팅권유우편여부
          this.ds_detail.mktnCnsntDate = ''  // 마케팅 동의일자(=sysdate)
        }	
        else {
          this.visibleList.mktnCnsntYn = ''
          this.ds_detail.trCsYn = ''  // 거래고객여부
          //this.ds_detail.mktnWcnstTyp = ''  // 마케팅요약동의여부 ASR250300203 / 신탁 마케팅 동의서 개정 사항 반영
          this.ds_detail.mktnCnsntTrm = ''  // 마케팅동의기간
          this.ds_detail.valdEndDate = ''  // 유효종료일자
          this.ds_detail.mktnInvtTelYn = ''  // 마케팅권유전화여부
          this.ds_detail.mktnInvtSsYn = ''  // 마케팅권유SMS여부
          this.ds_detail.mktnInvtEmailYn = ''  // 마케팅권유이메일여부
          this.ds_detail.mktnInvtMailYn = ''  // 마케팅권유우편여부
          this.ds_detail.mktnCnsntDate = ''  // 마케팅 동의일자(=sysdate)
        }
      },

      // 화면 초기화시 중복 공통되는 dom 초기화 모음
      initDom () {
        this.ds_detail.incMnTc = '111' //소득자구분 
        this.ds_detail.lvlhTc = '0' //생계형구분 0, 일반
        this.ds_detail.natnNatC = 'KR' // KR , 대한민국
        this.ds_detail.rsdnNatC = 'KR' // KR , 대한민국
        this.ds_detail.offcTelArno = '' // 직장 휴대폰번호
        this.ds_detail.mvphBusiMnNo = '010' // 자택 휴대폰번호
        this.ds_detail.rsdnYn = 'Y' // 거주자여부
        this.ds_detail.mnCntcPlcTc = '1' // 주연락처
        this.ds_detail.cdinProfrCnsntYn = 'Y' //계약체결
        this.ds_detail.dateTc = '01' // 양력음력구분
      },

      /**********************************************************************************
       * 수정 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_update ()
      {
        // 수정버튼 누르면 : 확인/ 취소 활성화
        this.enabledList.btn_confirm = true // 확인 활성화
        this.enabledList.btn_cancel = true // 취소 활성화
        this.enabledList.btn_searchList = false // 조회비활성화
        this.enabledList.btn_update = false // 수정비활성화
        this.enabledList.btn_init = false // 초기화비활성화
        
        // 기타 초기화
        this.cmb_modifyGb = '' 
        this.formList = [] // 전자서식 폼데이터 리스트 초기화
        this.enabledList.cmb_rcnoKindTc = false
        this.enabledList.edt_rcno= false
        // this.enabledList.btn_popupTpup95050 = true // fatca 버튼 활성화(개인은 제외대상이 없으므로 disable)
              
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_search.rcno ) ))
        {
           this.fn_AlertPopup(0,{ 
            content: "조회조건을 입력바랍니다."
          })
          this.$refs['edt_rcno'].focus() 
          return
        }
        
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_search.rcnoKindTc ) ))
        {
          this.fn_AlertPopup(0,{ 
            content: "조회조건을 입력바랍니다."
          })
          this.$refs['cmb_rcnoKindTc'].focus() 
          return
        }

        this.cmb_modifyGb = '' 
        this.gUpdateIngYn = "Y"; 
        this.g_eaiId = "C392_F10600140_S " // updateTFCP10000 //값 검증을 위해 임시로 setting

        //수정ok
        this.visibleList.sta_modifyGb = true // 정정구분 제목 보임
        this.visibleList.cmb_modifyGb = true // 정정구분 콤보 보임
        this.enabledList.cmb_modifyGb = true // 수정체크 활성화
      },

      /**********************************************************************************
       * 취소 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_cancel ()
      {
        this.ds_cdiList = [] // CDI 팝업 고객정보 초기화
        // 취소버튼 누르면 : 조회/초기화 활성화
        this.enabledList.btn_searchList = true // 조회활성화
        this.enabledList.btn_update = false // 수정비활성화
        this.enabledList.btn_confirm = false // 확인비활성화
        this.enabledList.btn_cancel = false // 취소비활성화
        this.enabledList.btn_init = true // 초기화활성화

        // 수정no
        this.visibleList.sta_modifyGb = false // 정정구분 비활성화
        this.visibleList.cmb_modifyGb = false // 수정체크 비활성화
        this.enabledList.cmb_modifyGb = true  // 수정체크 비활성화
        this.fn_commonAfterOnload(false); 		  // 입력항목 all 활성화
        this.enabledList.btn_popupTpup96000 = false
        // this.enabledList.btn_popupTpup95050 = false // fatca 버튼 비활성화
        
        // 취소버튼 클릭 시, 실명확인번호종류구분코드 / 실명확인번호 입력란 enable
        this.enabledList.cmb_rcnoKindTc = true
        this.enabledList.edt_rcno = true
        
        //this.fn_searchList(); // 조회처리
        this.gUpdateIngYn = ""; // 수정진행여부(수정버튼 눌렀을 때 Y/아니면 N) 초기화
        this.enabledList.btn_popupTfcp10001 = false // 신탁별도주소팝업 비활성화
        this.enabledList.btn_popupTpup96000 = false // 대리인버튼 비활성화
        this.enabledList.cmb_modifyGb = true
        this.cmb_modifyGb = '' // 정정구분 초기화

        this.fn_init('cancel') // 취소 초기화
      },

      /**********************************************************************************
       * [수정] 을 위한 정정구분값 변경 : onitemchanged 구현
       **********************************************************************************/
      Div01_cmb_modifyGb_onitemchanged ()
      {
        this.gNewMemberYn = "N"; // 신규고객여부 초기화
        if ( this.cmb_modifyGb == "70") //기타
        {
          this.enabledList.rdo_mktnCnsntYn = false
          // 변경 가능한 값들을 enable 처리한다..
          this.fn_commonAfterOnload(true); 		        // 입력항목 all 활성화
          this.enabledList.rdo_mktnCnsntYn = false    // 마케팅동의여부 비활성화
          //this.fn_commonAfterOnload(false); 		    // 입력항목 all 활성화(readonly)
          this.enabledList.btn_popupTpup96000 = false // 대리인팝업활성화(이름만조회가능 -> 수정기능은 정정구분 선택 시 추가)
          this.enabledList.btn_cancel = true          // 취소버튼 활성화
          this.enabledList.btn_confirm = true         // 확인버튼 활성화
          this.cdiSelectObj.cnm = this.ds_detail.cnm
          this.cdiSelectObj.custId = this.ds_detail.csId

          // 기타 수정시 disabled 항목
          this.enabledList.edt_agntNm = false // 대리인 input
          this.enabledList.rdo_representative = false // 대리인여부 radio

          this.fn_cdiSelect(); // 수정 후
        }
        this.enabledList.btn_searchList = false         // 조회버튼 비활성화(수정중에는 조회버튼 비활성화)
        this.enabledList.cmb_modifyGb = false
      },

      /**********************************************************************************
       * 확인 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_confirm ()
      {
        /**
         * 기존에 있던 벨리데이션 체크는 다 구현했지만 필요에 의하면 이부분에서 한번 더 한다.
         */
        this.formList = [] // 전자서식 폼데이터 리스트 초기화
        this.enabledList.btn_searchList = false // 조회버튼비활성화
        // 등록버튼 클릭 시, 실명확인번호종류구분코드 / 실명확인번호 입력란 disable
        this.enabledList.cmb_rcnoKindTc = false
        this.enabledList.edt_rcno= false
        
        if ( this.g_eaiId == "C392_F10600140_S " ){ // updateTFCP10000
          if ( TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.cmb_modifyGb ) ) ){
            TSCommUtil.fn_validate( this, [{ content: '정정구분 선택바랍니다.', ref:'cmb_modifyGb' }] )
            return
          }
        }

        this.fn_setCdiChgNdIdct();//fn_set_cdi_chgNd_idct();   //CDI에 보내는 변수 세팅(노드값, 인디케이터값)

         /**
         * 필수체크. 필수인 컴포넌트를 ','으로 구분하여 나열한다.
         * 컴포넌트명이 아닌 풀경로인점 유의 
         */
        this.ds_detail.newMemberYn = this.gNewMemberYn // 신규고객여부 setting
        this.ds_detail.csInfoCrctTc = this.cmb_modifyGb // 수정구분값 setting 
        // 종합계좌 미개설 여부 check -> 개설안함 -> "Y"
        if ( this.chk_NoTacct == true ){
          // 체크o -> true -> 개설안한다
          this.ds_detail.noTacct = 'Y' // 종합계좌 미개설 여부 -> 개설안한다.
        } else {
          // 체크x -> false -> 개설한다
          this.ds_detail.noTacct = 'N' // 종합계좌 미개설 여부 -> 개설한다.
        }
        //2019.01.23 add: 실명번호앞자리 컬럼 추가
        this.fn_rcnoDtSetting();
        
        // 우선.[수정] 체크박스에 따라 서비스ID 변경함.
        if ( this.cmb_modifyGb == "70") { //수정
          this.fn_transactionUpdate(); 
        } else { // 등록
          this.fn_transactionInsert();
        }
      },

      /**********************************************************************************
      * 등록 처리시 호출 
      **********************************************************************************/
      async fn_transactionInsert ()
      {
        this.transType = 'insert'
        //<등록일 때>
        // 1.실명번호종류구분코드+실명번호 검증.
        this.fn_comRcnoCheck();
        // 2.필수체크. 필수인 컴포넌트를 ','으로 구분하여 나열한다.
        //   컴포넌트명이 아닌 풀경로인점 유의 
        
        let isValidFlag = this.fn_isValidateCompList('insert_1')
        if(!isValidFlag) return
        
        // 주소입력 check -> 주연락처가 eMail 이어도 주소연락처는 1개이상 등록해야함.
        if ( this.ds_detail.mnCntcPlcTc == "3" ){ 
          //edt_homePsnoAddr / edt_offcPsnoAddr
          if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.homePsnoAddr ) ) && TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.offcPsnoAddr ) ) ) {
            TSCommUtil.fn_validate( this, [{ title: '자택 또는 직장 주소는', ref:'edt_homePsnoAddr' }] )
            return
          }
        }

        if ( this.chk_NoTacct == false ) { // 신규종합계좌 미개설 여부
          // 권유자 check /관리자 check
          var bReqFlag3 = this.fn_isValidateCompList('insert_2');
          if(!bReqFlag3) return
          
          if ( TSCommUtil.gfn_trim(this.ds_detail.invtMnEmnoNm) == "" ){
            this.fn_AlertPopup(0,{content: '존재하지 않는 권유자입니다. 신탁 시스템의 사용자 등록 여부 확인 부탁드립니다.'}) 
            this.$refs['edt_invtMnNm'].focus()
            return false 
          }
          
          if ( TSCommUtil.gfn_trim(this.ds_detail.mngrEmnoNm) == "" ){
            this.fn_AlertPopup(0,{content: '존재하지 않는 관리자입니다. 신탁 시스템의 사용자 등록 여부 확인 부탁드립니다.'}) 
            this.$refs['edt_mngrNm'].focus()
            return false
          }
          
          if ( !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.fnlDsgnEmno ) )){
            if ( TSCommUtil.gfn_trim(this.ds_detail.fnlDsgnNm) == "" ){
              this.fn_AlertPopup(0,{content: '존재하지 않는 FC입니다. 신탁 시스템의 사용자 등록 여부 확인 부탁드립니다.'}) 
              this.$refs['edt_fnlDsgnEmno'].focus()
              return false
            }
          }			
        } //ASR241100895 / 개인고객등록화면 권유자/관리자/권유FC 입력 시 오류 팝업내용 수정
        
        // 마케팅동의 check
        if ( this.ds_detail.mktnCnsntYn == "Y" ){
          if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.mktnInvtTelYn ) )   || TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.mktnInvtSsYn ) ) ||
              TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.mktnInvtEmailYn) ) || TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail.mktnInvtMailYn) ) ){
            this.fn_AlertPopup(0,{content: "마케팅동의여부 '예' 선택시, 연락방식을 입력해 주십시오."}) 
            return 
          }
          if (  this.ds_detail.mktnInvtTelYn   != "Y" && this.ds_detail.mktnInvtSsYn   != "Y" &&
                this.ds_detail.mktnInvtEmailYn != "Y" && this.ds_detail.mktnInvtMailYn != "Y" ){
            this.fn_AlertPopup(0,{content: ["마케팅 동의여부 '예' 선택시," , "한가지 이상의 연락방식에 '동의'하여야 합니다."]}) 
            return 
          }
          if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.mktnCnsntTrm ) ) ){
            this.fn_AlertPopup(0,{content: "마케팅동의여부 '예' 선택시, 유효기간을 입력해 주십시오."}) 
            return 
          }
        }
        
        // 3. 상황별 데이터 검증
        if ( ! this.fn_commonInsertCheck() ) 
          return 
        
        // 4. 종합계좌 개설 시 체크할 사항
        // 4.1 신규고객 + 신규종합계좌미개설(chk_NoTacct == "N") 일때 -> 개설가능
        if ( this.gNewMemberYn == "Y" && this.chk_NoTacct == false ) // 신규고객
        {
          if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.valid_tacPswd1) ) )
          {
            // this.fn_AlertPopup(0,{content: "비밀번호를 입력해 주십시오."}) 
            // this.$refs['edt_tacPswd1'].focus()
            TSCommUtil.fn_validate( this, [{ content: '비밀번호를 입력해 주십시오.', ref:'edt_tacPswd1' }] )
            return
          }
          
          // 보안키패드 
          if ( this.isMobile && this.valid_tacPswd1.length !== 4 ) {
            TSCommUtil.fn_validate( this, [{ content: '비밀번호는 4자리 숫자로 입력해 주십시오.', ref:'edt_tacPswd1' }] )
            return
          } else if ( this.valid_tacPswd1.length !== 4 && ! TSCommUtil.gfn_isNum( TSCommUtil.gfn_trim( this.valid_tacPswd1 ) ) ) {
            TSCommUtil.fn_validate( this, [{ content: '비밀번호는 4자리 숫자로 입력해 주십시오.', ref:'edt_tacPswd1' }] )
            return
          }
          
          if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.valid_tacPswd2 ) ) ) {
            // this.fn_AlertPopup(0,{content: "비밀번호확인을 입력해 주십시오."}) 
            // this.$refs['edt_tacPswd2'].focus()
            TSCommUtil.fn_validate( this, [{ content: '비밀번호확인을 입력해 주십시오.', ref:'edt_tacPswd2' }] )
            return
          }
          
          if ( this.isMobile ) {
            this.lv_password_check = await this.fn_pass_check()
            if(!this.lv_password_check) {
              this.ds_detail.tacPswd2 = ''
              this.lv_isClear_tacPswd2 = !this.lv_isClear_tacPswd2 ? true : false // 보안키패드 비밀번호 두번째 초기화
              TSCommUtil.fn_validate( this, [{ content: '비밀번호가 일치하지 않습니다.', ref:'edt_tacPswd2' }] )
              return
            }
          } else {
            if ( this.valid_tacPswd1 != this.valid_tacPswd2 ) {
              this.ds_detail.tacPswd2 = ''
              TSCommUtil.fn_validate( this, [{ content: '비밀번호가 일치하지 않습니다.', ref:'edt_tacPswd2' }] )
              return
            }
          }
          
        }
        
        // 입력값 check
        if (!this.fn_isVaildInput()) {
          return
        }
        
        this.ds_detail.newMemberYn = 	this.gNewMemberYn
        this.ds_detail.noTacct = 		  this.ds_detail.noTacct
        this.ds_detail.rcnoKindTc = 	this.ds_search.rcnoKindTc
        this.ds_detail.rcno = 			  this.ds_search.rcno
        this.ds_detail.tfcrei =       [this.ds_detail01]

        let TFCP10000 = {}
        Object.assign(TFCP10000, _.cloneDeep(this.ds_detail))
        Object.assign(TFCP10000, _.cloneDeep(this.ds_search))
        
        let paramsObj = Object.assign({}, TFCP10000)
        paramsObj.CDISettingUtilforPInstEss = [_.cloneDeep(this.ds_cdiId)]
        paramsObj.CDISettingUtilforPChgNd = [_.cloneDeep(this.ds_cdiChgNd)]
        paramsObj.CDISettingUtilforIdct = [_.cloneDeep(this.ds_cdiIdct)]
        paramsObj.TFCREI = [_.cloneDeep(this.ds_detail01)]
        paramsObj.TFCP10000 = [_.cloneDeep(TFCP10000)]

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600136_S'
        this.eaiCommObj.params = paramsObj

        console.log('=======insert C392_F10600136_S param log')
        console.log(paramsObj)

        // 등록po
        
        const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
        const resData = Object.assign({}, res.data)
        const t_eaiId = res.commHeaderVO.eaiId

        // 응답코드가 정상이 아닌경우 
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return 
        }
        // response 값 세팅
        Object.assign(this.ds_detail, res.data)


        /**********************************************************
         *  신규등록 완료는 2가지의 경우로 분기
         **********************************************************/
        
        /**********************************************************
         * 신규등록완료 경우 : 1번 시작
         * 종합계좌등록 안할 시 가상계좌등록 제외
         **********************************************************/
        if ( this.chk_NoTacct != false ){
          // 등록 완료 후 fatca 등록 팝업 오픈.
          this.enabledList.btn_searchList = true // 조회활성화
          this.fn_endProc() // 등록완료 
          this.enabledList.cmb_modifyGb = true
          return             
        } 
        /**********************************************************
         * 신규등록완료 경우 : 1번 종료
         **********************************************************/

        /**********************************************************
         * 신규등록완료 경우 : 2번 시작
         * 통장 신규 개설일 경우에만 가상계좌, FATCA 프로세스 시작
         **********************************************************/
        if ( await this.linkAcctInsertB() ) { // 가상계좌등록 프로세스 
        } else { // 비정상 작동
          console.log('계좌등록 실패')
        }

        // Fatca 프로세스
        if ( this.fn_fatcaCheck() == false ) { // fatca 등록전 체크
          return false
        } 
        let fatcaRtnObj = await this.btn_popupTpupFatcaDetail_onclick()
        if ( TSCommUtil.gfn_trim(fatcaRtnObj.isSuccsess) == false ) { // false 일경우 PO 호출 에러 또는 값이 없는 겨우
          console.log('fatca체크 response 값 없음')
          return false
        }
          
        /****************************
         * fatca 리스폰스 정상 프로세스
         *****************************/ 
        Object.assign(this.fatcaCntlObj.ds_detail, fatcaRtnObj)
        // 처리코드 리턴
        if ( !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.fatcaCntlObj.ds_detail.procCd ) ) )
        {
          if ( this.fatcaCntlObj.ds_detail.procCd == "1" ) {
            this.fatcaCntlObj.rtnType = false//결과:STOP
            // 개인사업자인 경우 -> 유포탈에서 처리해야 함.
            try{
              await this.fatcaCheckCallBackFunc("1")
              this.fn_endProc()
            } catch(e){
            }
          } else if ( this.fatcaCntlObj.ds_detail.procCd == "2" ) {
            //본인확인서기발급 or 20140630기존고객 or 법인실사제외대상
            this.fatcaCntlObj.rtnType = true//결과:PASS
            try{
              await this.fatcaCheckCallBackFunc("2")
              this.fn_endProc()
            } catch(e){
            }
          } else if ( this.fatcaCntlObj.ds_detail.procCd == "3" ) {
            // * 법인은 구현하지 않는다.
            
          } else if ( this.fatcaCntlObj.ds_detail.procCd == "4" ) {
            //개인팝업띄운다.
            // var oArg           = {pID:"TPUP95050", csId:this.edt_csId.value
            //                                     ,parentFileNm:this.ds_search.getColumn(0, "parentFileNm")};  //팝업화면으로 보낼 파라미터(고객ID).
            // var sOption        = "autosize=true,title=true";     //팝업열때 옵션
            // var sPopupCallBack = "fn_popupAfter";                //팝업화면에서 보낸 파라미터 받는곳
            // this.gfn_openPopup("TPUP95050","popup::TPUP95050.xfdl",oArg,sOption,sPopupCallBack);

            this.pPopup104Obj.type = 'insert'
            this.pPopup104Obj.csId = this.fatcaCntlObj.ds_detail.csId
            this.pPopup104Obj.parentFileNm = this.fatcaCntlObj.ds_detail.parentFileNm
            this.fn_OpenPopup(9)
          }
          
        } else {
          this.fn_endProc()
        }
        this.enabledList.btn_searchList = true // 조회활성화
        this.enabledList.cmb_modifyGb = true
        
        /**********************************************************
         * 신규등록완료 경우 : 2번 종료
         **********************************************************/
      },

      /**********************************************************************************
      * 수정 삭제 처리시 호출 
        @see TOBE에서 바뀐 내용
          정정구분에 70:기타 이외에 없음
          TOBE 태블릿 현 프로젝트 경우에는 정정구분이 기타로 고정되기 때문에 해당 로직은 사용안함
          수정일 경우 대리인 등록변경도 불가 
      **********************************************************************************/
      async fn_transactionUpdate ()
      {
        // 수정
        this.transType = 'update'
        if ( this.cmb_modifyGb == "70") //기타
        {
          if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.cmb_modifyGb ) ) )
          {
            var bReqFlag = this.fn_isValidateCompList('update_1');
            if(!bReqFlag) return
          }		
          if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_search.rcnoKindTc) ) )
          {
            this.fn_AlertPopup(0,{content: "실명번호종류를 입력하십시오."})
            return
          }

          if ( TSCommUtil.gfn_trim(this.rcnoOne).length != 6 )
          {
            TSCommUtil.fn_validate( this, [{ content: '주민번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoOne' }] )
            return false
          }
          if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
          {
            TSCommUtil.fn_validate( this, [{ content: '주민번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoTwo' }] )
            return false
          }
          
          // 2.필수체크. 필수인 컴포넌트를 ','으로 구분하여 나열한다.
          //   컴포넌트명이 아닌 풀경로인점 유의 
          var bReqFlag1 = this.fn_isValidateCompList('update_2')
          if(!bReqFlag1) return
          
          // 3. 상황별 데이터 검증
          var bInsertCheck = this.fn_commonInsertCheck();
          if (!bInsertCheck) return 
          
          // 국적, 거주국 미국으로 변경 시
          // FATCA 필수처리 -> 통장미개설 고객은 pass / 통장개설고객은 고객정보 수정 전 FATCA 처리되어야 함.
          if( this.ds_detail.natnNatC != "KR" || this.ds_detail.rsdnNatC != "KR")
          { 
            if( ! await this.fn_pbkOpenCheck() ) 
            {
              // 징구여부 != "Y"(=false) 이면 무조건 처리해야함.
              if ( this.rdo_sbmtYn == 'chk_sbmtYn' ) 
              {
                this.ds_detail.newMemberYn = this.gNewMemberYn
                this.ds_detail.rcnoKindTc = this.ds_search.rcnoKindTc
                this.ds_detail.rcno = this.ds_search.rcno

                let TFCP10000 = {}
                Object.assign(TFCP10000, this.ds_detail)
                Object.assign(TFCP10000, this.ds_search)

                this.eaiCommObj.lv_vm = this
                this.eaiCommObj.auth = 'S'
                this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600140_S'
                this.eaiCommObj.params = {
                  CDISettingUtilforPInstEss: [this.ds_cdiId],
                  CDISettingUtilforPChgNd: [this.ds_cdiChgNd],
                  CDISettingUtilforIdct: [this.ds_cdiIdct],
                  TFCREI : [this.ds_detail01],
                  TFCP10000 : [TFCP10000], // tfcp10000: serach , detail 둘다 같이 사용
                }
                Object.assign(this.eaiCommObj.params, TFCP10000)
              } 
              else 
              { 
                // true  -> ( 통장개설수 == 0 ) -> 최초고객
                // false -> ( 통장개설수  > 0 ) -> 기존고객
                // fatca등록팝업 호출
                TSCommUtil.gfn_validate(this, 'FATCA/CRS 체크가 정상적으로 종료되지않았습니다.')
                
                // Fatca 프로세스
                if ( this.fn_fatcaCheck() == false ) { // fatca 등록전 체크
                  return false
                } 
                let fatcaRtnObj = await this.btn_popupTpupFatcaDetail_onclick()
                if ( TSCommUtil.gfn_trim(fatcaRtnObj.isSuccsess) == false ) { // 실패
                  return false
                } 
                
                // fatca 리스폰스 정상 프로세스
                Object.assign(this.fatcaCntlObj.ds_detail, fatcaRtnObj)
                // 처리코드 리턴
                if (!TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.fatcaCntlObj.ds_detail.procCd ) ))
                {
                  if( this.fatcaCntlObj.ds_detail.procCd == "1" ) {
                    this.fatcaCntlObj.rtnType = false//결과:STOP
                    // 개인사업자인 경우 -> 유포탈에서 처리해야 함.
                    try{
                      await this.fatcaCheckCallBackFunc("1")
                    } catch(e){
                    }
                  } else if( this.fatcaCntlObj.ds_detail.procCd == "2" ) {
                    //본인확인서기발급 or 20140630기존고객 or 법인실사제외대상
                    this.fatcaCntlObj.rtnType = true//결과:PASS
                    try{
                      await this.fatcaCheckCallBackFunc("2")
                    } catch(e){
                    }
                  } else if( this.fatcaCntlObj.ds_detail.procCd == "3" ) {
                   // 법인은 구현 하지 않음
                  } else if( this.fatcaCntlObj.ds_detail.procCd == "4" ) {
                    //개인팝업띄운다.
                    this.pPopup104Obj.type = 'update'
                    this.pPopup104Obj.csId = this.fatcaCntlObj.ds_detail.csId
                    this.pPopup104Obj.parentFileNm = this.fatcaCntlObj.ds_detail.parentFileNm
                    this.fn_OpenPopup(9)
                  }
                } 
              }
            }
          } 
          else 
          {
            this.g_eaiId = "C392_F10600140_S " // updateTFCP10000
            // 입력값 check
            if ( ! this.fn_isVaildInput() )
              return
            
            this.ds_detail.newMemberYn =  this.gNewMemberYn
            this.ds_detail.rcnoKindTc =   this.ds_search.rcnoKindTc
            this.ds_detail.rcno =         this.ds_search.rcno

            let TFCP10000 = {}
            Object.assign(TFCP10000, this.ds_detail)
            Object.assign(TFCP10000, this.ds_search)

            this.eaiCommObj.lv_vm = this
            this.eaiCommObj.trnstId = this.g_eaiId // C392_F10600140_S :F10600140 or C392_F10600141_S:F10600141
            this.eaiCommObj.auth = 'S'
            this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600140_S'
            this.eaiCommObj.params = {
              CDISettingUtilforPInstEss: [this.ds_cdiId],
              CDISettingUtilforPChgNd: [this.ds_cdiChgNd],
              CDISettingUtilforIdct: [this.ds_cdiIdct],
              TFCREI : [this.ds_detail01],
              TFCP10000 : [TFCP10000], // tfcp10000: serach , detail 둘다 같이 사용
            }
            Object.assign(this.eaiCommObj.params, this.ds_detail)
            Object.assign(this.eaiCommObj.params, this.ds_search)
          }
        }
        
        // 수정PO
        const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
        const resData = Object.assign({}, res.data)
        
        // 응답코드가 정상이 아닌경우 
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return 
        }
        // response 값 세팅
        this.fn_endProc()
        Object.assign(this.ds_detail, res.data)
        this.enabledList.cmb_modifyGb = true   
      },
     
      /*=========================================================================
      /* 메소드 명 : fn_isVaildInput()
      /* 내용설명  : validation 체크
      /* Return 값 :                                                             
      =========================================================================*/
      fn_isVaildInput ()
      {
        if( this.ds_detail.natnNatC != "KR"){
          if(TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.csEnnm ) ) ){
            // this.fn_AlertPopup(0,{content: '영문명을 입력해 주십시오'}) 
            // this.$refs['edt_csEnnm'].focus()
            TSCommUtil.fn_validate( this, [{ content: '영문명을 입력해 주십시오.', ref:'edt_csEnnm' }] )
            return false
          }
        }
        
        if( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detail.sexTc) )){
          // this.fn_AlertPopup(0,{content: '성별을 입력해 주십시오.'}) 
          // this.$refs['rdo_sexTc'].focus()
          TSCommUtil.fn_validate( this, [{ content: '성별을 입력해 주십시오.', ref:'rdo_sexTc' }] ) // 토스트 메시지 및 포커스 08/16
          return false
        }
                
        if( this.ds_search.rcnoKindTc <= "06"){
          if( this.ds_search.rcnoKindTc == "01"){ 
            if( this.ds_detail.natnNatC != "KR"){
              // this.fn_AlertPopup(0,{content: "국적이 올바르지 않습니다."})
              // this.$refs['cmb_natnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '국적이 올바르지 않습니다.', ref:'cmb_natnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if( this.ds_detail.rsdnNatC !=  "KR"){
              // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
              // this.$refs['cmb_rsdnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if(this.ds_detail.incMnTc != "111"){
              // this.fn_AlertPopup(0,{content: "소득자 구분이 올바르지 않습니다."})
              // this.$refs['cmb_incMnTc'].focus()
              TSCommUtil.fn_validate( this, [{ content: '소득자 구분이 올바르지 않습니다.', ref:'cmb_incMnTc' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if(this.ds_detail.rsdnYn != "Y"){
              // this.fn_AlertPopup(0,{content: "거주자여부가 올바르지 않습니다."})
              // this.$refs['rdo_rsdnYn'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주자여부가 올바르지 않습니다.', ref:'rdo_rsdnYn' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
          }
          else if(this.ds_search.rcnoKindTc == "02"){ 
            if( this.ds_detail.natnNatC != "KR"){
              // this.fn_AlertPopup(0,{content: "국적이 올바르지 않습니다."})
              // this.$refs['cmb_natnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '국적이 올바르지 않습니다.', ref:'cmb_natnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if( this.ds_detail.rsdnNatC !=  "KR"){
              // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
              // this.$refs['cmb_rsdnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if(this.ds_detail.incMnTc != "112"){
              // this.fn_AlertPopup(0,{content: "소득자 구분이 올바르지 않습니다."})
              // this.$refs['cmb_incMnTc'].focus()
              TSCommUtil.fn_validate( this, [{ content: '소득자 구분이 올바르지 않습니다.', ref:'cmb_incMnTc' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if(this.ds_detail.rsdnYn != "Y"){
              // this.fn_AlertPopup(0,{content: "거주자여부가 올바르지 않습니다."})
              // this.$refs['rdo_rsdnYn'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주자여부가 올바르지 않습니다.', ref:'rdo_rsdnYn' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }            
          }
          else if(this.ds_search.rcnoKindTc == "03") { //재외국인
            if( this.ds_detail.natnNatC != "KR"){
              // this.fn_AlertPopup(0,{content: "국적이 올바르지 않습니다."})
              // this.$refs['cmb_natnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '국적이 올바르지 않습니다.', ref:'cmb_natnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if( this.ds_detail.rsdnNatC ==  "KR"){
              // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
              // this.$refs['cmb_rsdnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if(this.ds_detail.incMnTc != "112"){
              // this.fn_AlertPopup(0,{content: "소득자 구분이 올바르지 않습니다."})
              // this.$refs['cmb_incMnTc'].focus()
              TSCommUtil.fn_validate( this, [{ content: '소득자 구분이 올바르지 않습니다.', ref:'cmb_incMnTc' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if(this.ds_detail.rsdnYn != "N")
            {
              // this.fn_AlertPopup(0,{content: "거주자여부가 올바르지 않습니다."})
              // this.$refs['rdo_rsdnYn'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주자여부가 올바르지 않습니다.', ref:'rdo_rsdnYn' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }            
          }
          else if(this.ds_search.rcnoKindTc == "04"){ //외국인
            if(this.rcnoOne.trim().length != 6){
              // this.fn_AlertPopup(0,{content: "외국인 등록번호의 형식이 올바르지 않습니다."})
              // this.$refs['edt_rcnoOne'].focus()
              TSCommUtil.fn_validate( this, [{ content: '외국인 등록번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoOne' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if(this.valid_rcnoTwo.trim().length != 7){
              // this.fn_AlertPopup(0,{content: "외국인 등록번호의 형식이 올바르지 않습니다."})
              // this.$refs['edt_rcnoTwo'].focus()
              TSCommUtil.fn_validate( this, [{ content: '외국인 등록번호의 형식이 올바르지 않습니다.', ref:'edt_rcnoTwo' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if( this.ds_detail.natnNatC == "KR"){
              // this.fn_AlertPopup(0,{content: "국적이 올바르지 않습니다."})
              // this.$refs['cmb_natnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '국적이 올바르지 않습니다.', ref:'cmb_natnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if(this.ds_detail.incMnTc != "131"){
              // this.fn_AlertPopup(0,{content: "소득자 구분이 올바르지 않습니다."})
              // this.$refs['cmb_incMnTc'].focus()
              TSCommUtil.fn_validate( this, [{ content: '소득자 구분이 올바르지 않습니다.', ref:'cmb_incMnTc' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if(this.ds_detail.rsdnYn == "Y"){
              if( this.ds_detail.rsdnNatC !=  "KR"){
                // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
                // this.$refs['cmb_rsdnNatC'].focus()
                TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
                return false            
              }
            }else if(this.ds_detail.rsdnYn == "N"){
              if( this.ds_detail.rsdnNatC ==  "KR"){
                // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
                // this.$refs['cmb_rsdnNatC'].focus()
                TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
                return false
              }
            }        
          }
          else if(this.ds_search.rcnoKindTc == "05")
          {
            if( this.ds_detail.rsdnNatC !=  "KR"){
              // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
              // this.$refs['cmb_rsdnNatC'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
              return false            
            }
            if(this.ds_detail.incMnTc != "141"){
              // this.fn_AlertPopup(0,{content: "소득자구분이 올바르지 않습니다."})
              // this.$refs['cmb_incMnTc'].focus()
              TSCommUtil.fn_validate( this, [{ content: '소득자구분이 올바르지 않습니다.', ref:'cmb_incMnTc' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if(this.ds_detail.rsdnYn != "Y"){
              // this.fn_AlertPopup(0,{content: "거주자 구분이 올바르지 않습니다."})
              // this.$refs['rdo_rsdnYn'].focus()
              TSCommUtil.fn_validate( this, [{ content: '거주자 구분이 올바르지 않습니다.', ref:'rdo_rsdnYn' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
          }
          else if(this.ds_search.rcnoKindTc == "06")
          {
            if(this.ds_detail.incMnTc != "121"){
              // this.fn_AlertPopup(0,{content: "소득자구분이 올바르지 않습니다."})
              // this.$refs['cmb_incMnTc'].focus()
              TSCommUtil.fn_validate( this, [{ content: '소득자구분이 올바르지 않습니다.', ref:'cmb_incMnTc' }] ) // 토스트 메시지 및 포커스 08/16
              return false
            }
            if(this.ds_detail.rsdnYn == "Y"){
              if( this.ds_detail.rsdnNatC !=  "KR"){
                // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
                // this.$refs['cmb_rsdnNatC'].focus()
                TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
                return false            
              }
            }
            if(this.ds_detail.rsdnYn == "N"){
              if( this.ds_detail.rsdnNatC ==  "KR"){
                // this.fn_AlertPopup(0,{content: "거주국이 올바르지 않습니다."})
                // this.$refs['cmb_rsdnNatC'].focus()
                TSCommUtil.fn_validate( this, [{ content: '거주국이 올바르지 않습니다.', ref:'cmb_rsdnNatC' }] ) // 토스트 메시지 및 포커스 08/16
                return false
              }
            }        
          }
        }
        else
        {
          // this.fn_AlertPopup(0,{content: "실명번호 종류가 올바르지 않습니다."})
          // this.$refs['cmb_rcnoKindTc'].focus()
          TSCommUtil.fn_validate( this, [{ content: '실명번호 종류가 올바르지 않습니다.', ref:'cmb_rcnoKindTc' }] ) // 토스트 메시지 및 포커스 08/16
          return false
        }

        return true
      },

      /*========================================================================= 
      /* 동기화 함수
      /* 메소드 명 : fn_pbkOpenCheck(svcid, cd, err_msg) : 구 this.pbk_open_check                       
      /* 내용설명  : 개설된 통장을 가지고 있는지 확인함.
      /* Return   : bool                                                          
      =========================================================================*/
      async fn_pbkOpenCheck ()
      {
        let fatcaCsId = this.gCsId;
        let pbkRtn = false 
        
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( fatcaCsId ) ) ){
          this.fn_AlertPopup(0,{content: "고객ID가 존재하지 않습니다."})
          return
        }
        
        //데이터셋 초기화
        this.ds_searchFatca = Object.assign({}, TSDataSet.ds_searchFatca )
        this.ds_detailFatca = Object.assign({}, TSDataSet.ds_detailFatca )
        
        this.ds_searchFatca.csId = fatcaCsId
        this.ds_searchFatca.corpYn = "N"
        
        // this.g_eaiId = "C392_F10600203_S" //selectTPUP95050C // selectPbkOepnCount
        // var sURL          = "svc::"+sSvcID+".do"; // svc = http://localhost:8080/
        // var sInDatasets   = "tpup95050VO=ds_searchFatca:";
        // var sOutDatasets  = "ds_detailFatca=tpup95050Rst";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_pbkOpenCheckCallBack"; 
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);

        /*************************************************
          ds_searchFatca, ds_detailFatca 이런것들 다 배열임
          var nRow = this.ds_searchFatca.addRow();
          this.ds_detailFatca.addRow();
        **************************************************/

        //this.eaiCommObj.trnstId = "C392_F10600203_S" //selectTPUP95050C // selectPbkOepnCount
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600203_S' //selectTPUP95050C // selectPbkOepnCount
        this.eaiCommObj.params = Object.assign({}, this.ds_searchFatca)
          
        // await TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_pbkOpenCheckCallBack)
        const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return false
        }

        // callBack 구현
        const t_eaiId = res.commHeaderVO.eaiId
        if ( t_eaiId == "C392_F10600203_S" ) { // selectTPUP95050C
          if ( parseInt(this.ds_detailFatca.cnt) <= 0 ){
            pbkRtn = true
          }
        }

        return pbkRtn
      },

      /***************************************************************************************
          승인팝업 호출 후 처리 
      *****************************************************************************************/
      fn_aprvPopAfter ()
      {
        if ( !TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.pv_popupResult.successYn ) ) ) { 
          this.fn_transaction();
        }
        else {		
          this.ds_detail.aprvNo = ''
        }
        
      },

      /***************************************************************************************
      * linkAcctInsert callBack2 호출 (만기보험금계좌등록처리)
      *****************************************************************************************/
      async linkAcctInsertB ()
      {	
        let rtn = true
        this.ds_linkAcctDetail = Object.assign({}, TSDataSet.ds_linkAcctDetail)
        this.ds_linkAcctDetail.msUseYn = "Y"
        this.ds_linkAcctDetail.vtAcnoYn = "Y"
        this.ds_linkAcctDetail.tacno = this.ds_detail.tacno // 가상계좌번호
        this.ds_linkAcctDetail.bnkbSeq = "000"
        this.ds_linkAcctDetail.vtAcno = this.ds_detail.trstVtAcno
        
        const tfanbObj = Object.assign({}, this.ds_linkAcctDetail)
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'C392_F10600124_S'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600124_S'
        this.eaiCommObj.params = {
          TFABNB : [tfanbObj],
        }
        Object.assign(this.eaiCommObj.params, tfanbObj)

        try {
          const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)

          // fn_callBack2 부분
          const resData = Object.assign({}, res.data)
          Object.assign(this.ds_linkAcctDetail, resData)

          if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
            // application.afrm_BottomFrame.form.sta_callBackMsg.set_text( "3/4 만기보험금 계좌 등록 중 오류가 발생했습니다." );
            TSCommUtil.gfn_validate(this, '3/4 만기보험금 계좌 등록 중 오류가 발생했습니다.')          
            rtn = false
          }else{
            //application.afrm_BottomFrame.form.sta_callBackMsg.set_text( "4/4 만기보험금 계좌 등록 완료. 연관계좌가 정상적으로 등록 되었습니다." );
            // TSCommUtil.gfn_validate(this, '4/4 만기보험금 계좌 등록 완료. 연관계좌가 정상적으로 등록 되었습니다.')
            rtn = true
          }
          
        } catch (error) {
          rtn = false
        }
        return rtn
      },

      /**********************************************************************************
       * 실명번호앞자리 세팅: 2018.01.23 add
       **********************************************************************************/
      fn_rcnoDtSetting ()
      {
        let vRcnoDt = ''
        let aYear = ''
        let aDate = ''
        let rcnoSex = Number(this.valid_rcnoTwo.substr(0, 1))  //주민번호가 3이상이면 2000년도 출생
        // 실명번호 length check
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_search.rcno ) ) )
          return 
          
        // 주민등록번호
        if ( this.ds_search.rcnoKindTc == "01" ) {
          aYear =  "19";
          aDate = TSCommUtil.gfn_toString(this.ds_search.rcno).substr(0, 6); // 주민번호 앞자리만
          //주민번호가 3이상이면 2000년도 출생
          if ( rcnoSex > 2 ) {
            aYear = "20";
          }
          vRcnoDt = aYear + aDate;
        } else if ( this.ds_search.rcnoKindTc == "04" ) { //외국인등록번호 //20230720 외국인등록번호 세팅 추가
          aYear =  "19";
          aDate = TSCommUtil.gfn_toString(this.ds_search.rcno).substr(0, 6); // 주민번호 앞자리만
          //외국인등록번호 뒷자리가 7이상이면 2000년도 출생
          if ( rcnoSex > 7 ) {
            aYear = "20";
          }
          vRcnoDt = aYear + aDate;
        } else {
          vRcnoDt = "";
        }
        this.ds_detail.rcnoDt = vRcnoDt
      },

/*********************************************************
    * 설명: get user name
    *********************************************************/
    fn_getUserName(usid,userNm){
      let _this = this
      let pParam = {
        "usid": usid,      // 사원번호
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600268_S'
      this.eaiCommObj.params = pParam
      TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
        if (res && res.data) {
          let uLst = _.cloneDeep(res.data.tuuser);
          if (uLst != null && uLst.length > 0){
            if (userNm == "invtMnEmnoNm"){
              _this.ds_detail.invtMnEmnoNm = uLst[0]["userNm"];
            }
            if (userNm == "mngrEmnoNm"){
              _this.ds_detail.mngrEmnoNm = uLst[0]["userNm"];
            }
          }
        }
      },function (error) {return},'UF10600268')
    },
    /***************************************************************************************
      부서코드 직원명 자동조회
    ************************************************************************************* ****/
      edt_onkeyupToGetName(objId){
        if ( objId == "invtMnEmno" ) {
          if ( this.ds_detail.invtMnEmno.length == 5 ) {
            this.emnoPopupYn = "N";
            this.fn_getUserName(this.ds_detail.invtMnEmno,"invtMnEmnoNm");
          } else {
            this.ds_detail.invtMnEmnoNm = "";
          }
        } else if ( objId == "mngrEmno" ) {
          if ( this.ds_detail.mngrEmno.length == 5 ) {
            this.emnoPopupYn = "N";
            this.fn_getUserName(this.ds_detail.mngrEmno,"mngrEmnoNm");
            if ( this.ds_detail.invtMnEmno.length < 5 ) {
              this.ds_detail.invtMnEmno = this.ds_detail.mngrEmno;
              this.fn_getUserName(this.ds_detail.invtMnEmno,"invtMnEmnoNm");
            }
          } else {
            this.ds_detail.mngrEmnoNm = "";
          }
        } else if ( objId == "fnlDsgnEmno" ) {
          let _this = this
          if ( this.ds_detail.fnlDsgnEmno.length == 8 ) {
            //조회용 dataset을 초기화 해준다.
            this.dsList14 = [];
            //this.sSvcIDSub    = "selectTFAC20000D";
            let pParam = {
              "fcEmno": this.ds_detail.fnlDsgnEmno, //권유대행FC
            }
            this.eaiCommObj.lv_vm = this
            this.eaiCommObj.auth = 'S'
            this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600085_S'
            this.eaiCommObj.params = pParam
            TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
              if (res && res.data) {
                if (res.data.tfmfci == null || res.data.tfmfci.length < 1){
                  _this.ds_detail.fnlDsgnEmnoNm = "";
                }else{
                  _this.ds_detail.fnlDsgnEmnoNm = res.data.tfmfci[0]["fcNm"];
                }
              }
            },function (error) {return},'UF10600085')
          } else {
            this.ds_detail.fnlDsgnEmnoNm = "";
          }
        }
      },
      
      fn_callBackSub ( res )
      {
        const resData = res.data
        Object.assign(this.ds_listFc, res.data ) 
    
        if ( this.ds_listFc.fcEmno != '' ){
          if( this.ds_listFc.invPosYn == "N" ){
            this.fn_AlertPopup(0,{content: '해당 직원은 신탁상품 권유자격이 불충분하여 권유자로 입력할 수 없습니다.'})    
            this.ds_detail.fnlDsgnEmno = '' 
            this.ds_detail.fnlDsgnEmnoNm = ''
          }else{
            this.ds_detail.fnlDsgnEmnoNm = this.ds_listFc.fcNm
          }
        } else {
          this.fn_AlertPopup(0,{content: '직원번호를 바르게 입력하시오.'})    
          this.ds_detail.fnlDsgnEmno = '' 
          this.ds_detail.fnlDsgnEmnoNm = ''
        }

      },

      /**********************************************************************************
      * [FATCA징구여부 임시] 함수 : onclick 구현
      **********************************************************************************/
      btn_popupTpupFatcaEx_onclick ()
      {
        // 고객ID를 리턴받은 경우 -> fatca 처리
        this.fn_fatcaSelect();
      },

      /**********************************************************************************
      * [FATCA상세전문 임시] 함수 : onclick 구현 -> 고객정보정정 : 70 기타일 경우 수정처리 전 setting
      **********************************************************************************/
      async btn_popupTpupFatcaDetail_onclick ()
      {
        let fatcaRtnObj = {
          isSuccsess : true
        }
        
        let parentFileNm = ''

        let csId = this.ds_detail.csId
        parentFileNm = parentFileNm
        let corpYn = 'N'

        console.log(csId)
        console.log(parentFileNm)
        console.log(corpYn)

        // gUpdateIngYn == "Y"
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.cmb_modifyGb ) ) ) {
          if ( this.gUpdateIngYn == "Y"  ){
            // 수정OK
            parentFileNm = "CP1600";
          } else {
            // 등록OK
            parentFileNm = "BM3000";	
          }
        } else {
          if ( this.cmb_modifyGb == "50" || this.cmb_modifyGb == "60" || this.cmb_modifyGb == "70") {
            // 수정OK  
            parentFileNm = "CP1600";	
          }
        }
        
        //this.DivFatca._setValue(this.ds_detail.csId,gParentFileNm,gCorpYn );
        // await this.fn_fatca_setValue(this.ds_detail.csId,gParentFileNm,gCorpYn)
        /**
         * fatca TPUP96060.xfdl 파일 함수 모음
         * 기존 넥사크로에서 파일로 include 하던 부분을 메인페이지에 include
         * 메소드명 : fn_fatca + 함수명
         * 변수 : fatcaCntlObj 오브젝트에 모음
         */
          
        /* FATCA 프로세스 [TPUP96090.xfdl fn_transaction()]
          1.본인확인서 징구여부 체크
          2.2014/06/30 계좌보유 여부 체크
          3.법인여부 구분
          4.팝업표출
          5.본인확인서징구이력생성
          6.서식표출
        */
        
        // sInDatasets  = "tpup95050VO=ds_search:"; 
        // sOutDatasets = "ds_detail=tpup95050Rst";
        // 개인만 확인 

        fatcaRtnObj.csId = this.ds_detail.csId
        fatcaRtnObj.parentFileNm = parentFileNm
        fatcaRtnObj.corpYn = 'N'
        fatcaRtnObj.procCd = '' // 리스폰스 받아서 처리

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600243_S' 
        this.eaiCommObj.params = fatcaRtnObj

        // po 호출
        try {
          const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
          const resData = Object.assign({}, res.data)
          fatcaRtnObj.procCd = resData.procCd
          
          if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
            let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
            this.fn_AlertPopup(0,{content: msg}) 
            fatcaRtnObj.isSuccsess = false
            return fatcaRtnObj
          } 
        } catch (error) {
          fatcaRtnObj.isSuccsess = false
        }  
        return fatcaRtnObj 
      },

      /**********************************************************************************
      * fatca 팝업 전 체크
      **********************************************************************************/
      fn_fatcaCheck (){

        if ( TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim( this.ds_detail.csId ) ) ) {
          this.fn_AlertPopup(0,{content: "고객ID를 확인하십시오."})
          return false
        }
        if ( TSCommUtil.gfn_nvl( this.ds_detail.csId , "" ).length != 9)
          return false

        return true
      },

      /**********************************************************************************
      * gfn_callService 의 콜백 함수 : transaction 응답처리 ( 구 : fn_set_cdi_chgNd_idct (CDI에 보내는 변수 세팅(노드값, 인디케이터값)))  
      **********************************************************************************/
      fn_setCdiChgNdIdct ()
      {
        // this.ds_cdiChgNd = Object.assign({}, TSDataSet.ds_cdiChgNd )
        // this.ds_cdiIdct = Object.assign({}, TSDataSet.ds_cdiIdct )
        let chgCnt = 0;
        
        /*----------------------------------------
            tcrmPBobjNm nod 관련
        ------------------------------------------*/
        chgCnt = 0; 
        /*tcrmPBobjNm nod에 해당하는 인디케이터 설정*/   
        if( TSCommUtil.gfn_trim( this.ds_detail.sex) != TSCommUtil.gfn_trim( this.ds_detailBfc.sex) ) {
          this.ds_cdiIdct.idctTcrmPBobjNmVOsxdsCd = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctTcrmPBobjNmVOsxdsCd = 'N'
        }
        
        if( TSCommUtil.gfn_trim( this.ds_detail.btdt ) != TSCommUtil.gfn_trim( this.ds_detailBfc.btdt) ) {
          this.ds_cdiIdct.idctTcrmPBobjNmVOralDob = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctTcrmPBobjNmVOralDob = 'N'
        }
      
        if(TSCommUtil.gfn_trim(this.ds_detail.natnNatC ) != TSCommUtil.gfn_trim(this.ds_detailBfc.natnNatC) ) {
          this.ds_cdiIdct.idctTcrmPBobjNmVOnatyCd = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctTcrmPBobjNmVOnatyCd = 'N'
        }
        
        /*tcrmPBobjNm nod변경 여부 설정*/    
        if(chgCnt > 0) {
          this.ds_cdiChgNd.chgNdVOtcrmPBobjNm = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOtcrmPBobjNm = 'N'
        }
        
        /*----------------------------------------
            tcrmPGvnmBobjNm nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*tcrmPGvnmBobjNm nod에 해당하는 인디케이터 설정*/    
        if(TSCommUtil.gfn_trim(this.ds_detail.csEnnm) != TSCommUtil.gfn_trim(this.ds_detailBfc.csEnnm)) {
          this.ds_cdiIdct.idctTcrmPGvnmBobjNmVOcustNm = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctTcrmPGvnmBobjNmVOcustNm = 'N'
        }
        
        /*tcrmPGvnmBobjNm nod변경 여부 설정*/    
        if(chgCnt > 0) { 
          this.ds_cdiChgNd.chgNdVOtcrmPGvnmBobjNm = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOtcrmPGvnmBobjNm = 'N'
        }
      
        /*----------------------------------------
            custAddrNm1 nod 관련 (주소)
        ------------------------------------------*/
        chgCnt = 0;
              
        /*custAddrNm1 nod에 해당하는 인디케이터 설정*/
        if ( TSCommUtil.gfn_trim(this.ds_detail.hmadPsno1) != TSCommUtil.gfn_trim(this.ds_detailBfc.hmadPsno1)
        || TSCommUtil.gfn_trim(this.ds_detail.hmadPsno2) != TSCommUtil.gfn_trim(this.ds_detailBfc.hmadPsno2) ) {
          this.ds_cdiIdct.idctAddrNmVOpstcdId1 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOpstcdId1 = 'N'
        }
        
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detailBfc.hmadPsnoSeq ) ) ) { 
          this.ds_detailBfc.hmadPsnoSeq = "0"
        }

        if( TSCommUtil.gfn_trim(this.ds_detail.hmadPsnoSeq) != TSCommUtil.gfn_trim(this.ds_detailBfc.hmadPsnoSeq) ) {
          this.ds_cdiIdct.idctAddrNmVOpstcdSeqNo1 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOpstcdSeqNo1 = 'N'
        }
        
        if( TSCommUtil.gfn_trim(this.ds_detail.homeRmnAddr) != TSCommUtil.gfn_trim(this.ds_detailBfc.homeRmnAddr) ) {
          this.ds_cdiIdct.idctAddrNmVOdtlad1 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOdtlad1 = 'N'
        }

        // 새주소 반영
        let vNwOldAddrScCd1_bfc = ''
        let vNwOldAddrScCd1 = ''
        
        if( TSCommUtil.gfn_trim(this.ds_detailBfc.hmadPsnoSeq ) < 600) {
          vNwOldAddrScCd1_bfc = "10"; //구주소
        } else if ( TSCommUtil.gfn_trim(this.ds_detailBfc.hmadPsnoSeq ) >= 600) {
          vNwOldAddrScCd1_bfc = "20"; //신주소
        }

        if( TSCommUtil.gfn_trim(this.ds_detail.hmadPsnoSeq ) < 600) {
          vNwOldAddrScCd1 = "10"; //구주소
        } else if( TSCommUtil.gfn_trim(this.ds_detail.hmadPsnoSeq ) >= 600) {
          vNwOldAddrScCd1 = "20"; //신주소
        }
        
        if( vNwOldAddrScCd1_bfc != vNwOldAddrScCd1 ) {
          this.ds_cdiIdct.idctAddrNmVOnwOldAddrScCd1 = 'Y'
          chgCnt = chgCnt +1;    	
        } else {
          this.ds_cdiIdct.idctAddrNmVOnwOldAddrScCd1 = 'N'			
        }
        
        if( TSCommUtil.gfn_trim(this.ds_detail.hmadStdztYn) != TSCommUtil.gfn_trim( this.ds_detailBfc.hmadStdztYn) ) {
          this.ds_cdiIdct.idctAddrNmVOaddrStdztYn1 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOaddrStdztYn1 = 'N'
        }
        
        if( this.chk_homeAddr ) {
          this.ds_cdiIdct.idctAddrNmVOendYmd1 = 'Y'
          chgCnt = chgCnt +1;	
        } else {
          this.ds_cdiIdct.idctAddrNmVOendYmd1 = 'N'
        }
        
        /*custAddrNm1 nod변경 여부 설정*/     
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOcustAddrNm1 = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOcustAddrNm1 = 'N'
        }
        
        /*----------------------------------------
            custAddrNm2 nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*custAddrNm2 nod에 해당하는 인디케이터 설정*/    
        if( TSCommUtil.gfn_trim(this.ds_detail.offcAddrPsno01) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcAddrPsno01)
        || TSCommUtil.gfn_trim(this.ds_detail.offcAddrPsno02) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcAddrPsno02) ) {
          this.ds_cdiIdct.idctAddrNmVOpstcdId2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOpstcdId2 = 'N'
        }
        
        if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim( this.ds_detailBfc.offcAddrPsnoSeq ) ) ) {
          this.ds_detailBfc.offcAddrPsnoSeq = '0'
        }
        
        if( TSCommUtil.gfn_trim(this.ds_detail.offcAddrPsnoSeq) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcAddrPsnoSeq) ) {
          this.ds_cdiIdct.idctAddrNmVOpstcdSeqNo2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOpstcdSeqNo2 = 'N'
        }
        
        if( TSCommUtil.gfn_trim(this.ds_detail.offcRmnAddr) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcRmnAddr) ) {
          this.ds_cdiIdct.idctAddrNmVOdtlad2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOdtlad2 = 'N'
        }
        
        var vNwOldAddrScCd2_bfc = '' 
        var vNwOldAddrScCd2 = '' 
        
        if( TSCommUtil.gfn_trim( this.ds_detailBfc.offcPsnoSeq ) < 600 ) { // 0
          vNwOldAddrScCd2_bfc = "10"; //구주소
        } else if( TSCommUtil.gfn_trim(this.ds_detailBfc.offcPsnoSeq ) >= 600 ) {
          vNwOldAddrScCd2_bfc = "20"; //신주소
        }
        
        if( TSCommUtil.gfn_trim(this.ds_detail.offcPsnoSeq) < 600 ) { // 501
          vNwOldAddrScCd2 = "10"; //구주소
        } else if( TSCommUtil.gfn_trim(this.ds_detail.offcPsnoSeq) >= 600 ) {
          vNwOldAddrScCd2 = "20"; //신주소
        }
        
        if( vNwOldAddrScCd2_bfc != vNwOldAddrScCd2 ) { // Y
          this.ds_cdiIdct.idctAddrNmVOnwOldAddrScCd2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOnwOldAddrScCd2 = 'N'
        }

        if( TSCommUtil.gfn_trim(this.ds_detail.offcAddrStdztYn) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcAddrStdztYn) ) {
          this.ds_cdiIdct.idctAddrNmVOaddrStdztYn2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctAddrNmVOaddrStdztYn2 = 'N'
        }
            
        //if(chk_del_o_addr.Value == "1") {
        if( this.chk_offcAddr ) {
          this.ds_cdiIdct.idctAddrNmVOendYmd2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
        this.ds_cdiIdct.idctAddrNmVOendYmd2 = 'N'
        }
        
        /*custAddrNm2 nod변경 여부 설정*/    
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOcustAddrNm2 = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOcustAddrNm2 = 'N'
        }
        
        /*----------------------------------------
            custCtadrNm1 nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*custCtadrNm1 nod에 해당하는 인디케이터 설정*/        
        if( TSCommUtil.gfn_trim( this.ds_detail.homeTelArno) != TSCommUtil.gfn_trim( this.ds_detailBfc.homeTelArno )
        ||  TSCommUtil.gfn_trim( this.ds_detail.homeTelPofn) != TSCommUtil.gfn_trim( this.ds_detailBfc.homeTelPofn )
        ||  TSCommUtil.gfn_trim( this.ds_detail.homeTelSeq ) != TSCommUtil.gfn_trim( this.ds_detailBfc.homeTelSeq  ) ) {
          this.ds_cdiIdct.idct_ctadrBobjNm_knb1 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idct_ctadrBobjNm_knb1 = 'N'
        }
        
        //if(chk_del_h_telno.Value == "1") {
        if( this.chk_homeTel ) {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd1 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd1 = 'N'
        }
        
        /*custCtadrNm1 nod변경 여부 설정*/     
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm1 = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm1 = 'N'
        }
        
        /*----------------------------------------
            custCtadrNm2 nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*custCtadrNm2 nod에 해당하는 인디케이터 설정*/        
        if( TSCommUtil.gfn_trim( this.ds_detail.offcTelArno) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcTelArno)
        ||  TSCommUtil.gfn_trim( this.ds_detail.offcTelPofn) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcTelPofn)
        ||  TSCommUtil.gfn_trim( this.ds_detail.offcTelSeq ) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcTelSeq ) ) {
          this.ds_cdiIdct.idctCtadrBobjNmVOknb2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOknb2 = 'N'
        }
        
        //if(chk_del_o_telno.Value == "1") {
        if( this.chk_offcTel ) {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd2 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd2 = 'N'
        }
        
        /*custCtadrNm2 nod변경 여부 설정*/
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm2 = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm2 = 'N'
        }
        
        /*----------------------------------------
            custCtadrNm3 nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*custCtadrNm3 nod에 해당하는 인디케이터 설정*/    
        if( TSCommUtil.gfn_trim( this.ds_detail.emailAddr) != TSCommUtil.gfn_trim( this.ds_detailBfc.emailAddr)
        ||  TSCommUtil.gfn_trim( this.ds_detail.domnNm   ) != TSCommUtil.gfn_trim( this.ds_detailBfc.domnNm)) {
          this.ds_cdiIdct.idctCtadrBobjNmVOknb3 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOknb3 = 'N'
        }
        
        //if(chk_del_email_addr == "1") {
        if( this.chk_homeEmail ) {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd3 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd3 = 'N'
        }
        
        /*custCtadrNm3 nod변경 여부 설정*/    
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm3 = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm3 = 'N'
        }

        /*----------------------------------------
            custCtadrNm4 nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*custCtadrNm4 nod에 해당하는 인디케이터 설정*/        
        if( TSCommUtil.gfn_trim( this.ds_detail.offcFaxArno) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcFaxArno)
        ||  TSCommUtil.gfn_trim( this.ds_detail.offcFaxPofn) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcFaxPofn)
        ||  TSCommUtil.gfn_trim( this.ds_detail.offcFaxSeq ) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcFaxSeq ) ) {
        this.ds_cdiIdct.idctCtadrBobjNmVOknb4 = 'Y'
        chgCnt = chgCnt +1;
        } else {
        this.ds_cdiIdct.idctCtadrBobjNmVOknb4 = 'N'
        }
        
        //if(chk_del_faxno == "1") {
        if( this.chk_homeFax ) {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd4 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd4 = 'N'
        }
        
        /*custCtadrNm4 nod변경 여부 설정*/     
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm4 = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm4 = 'N'
        }
        
        /*----------------------------------------
            custCtadrNm5 nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*custCtadrNm5 nod에 해당하는 인디케이터 설정*/        
        if( TSCommUtil.gfn_trim( this.ds_detail.mvphPofn) != TSCommUtil.gfn_trim( this.ds_detailBfc.mvphPofn) 
        ||  TSCommUtil.gfn_trim( this.ds_detail.mvphSeq ) != TSCommUtil.gfn_trim( this.ds_detailBfc.mvphSeq ) ) {
          this.ds_cdiIdct.idctCtadrBobjNmVOknb5 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOknb5 = 'N'
        }
        
        //if(chk_del_cp_telno == "1") {
        if( this.chk_homeCel ) {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd5 = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctCtadrBobjNmVOendYmd5 = 'N'
        }
        
        /*custCtadrNm5 nod변경 여부 설정*/
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm5 = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOcustCtadrNm5 = 'N'
        }
    
        /*----------------------------------------
        //      pExpnBobjNm nod 관련
        ------------------------------------------*/

        chgCnt = 0;
        /*pExpnBobjNm nod에 해당하는 인디케이터 설정*/       
        if( TSCommUtil.gfn_trim( this.ds_detail.rsdnYn) != TSCommUtil.gfn_trim( this.ds_detailBfc.rsdnYn) ) {
          this.ds_cdiIdct.idctpExpnBobjNmVOdmclScCd = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctpExpnBobjNmVOdmclScCd = 'N'
        }
        
        this.ds_cdiIdct.idctpExpnBobjNmVOpsprtNo = 'N'
        chgCnt = chgCnt +1;

        if( TSCommUtil.gfn_trim( this.ds_detail.dateTc) != TSCommUtil.gfn_trim( this.ds_detailBfc.dateTc) ) {
          this.ds_cdiIdct.idctpExpnBobjNmVOsamScCd = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctpExpnBobjNmVOsamScCd = 'N'
        }
        
        /*pExpnBobjNm nod변경 여부 설정*/          
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOpExpnBobjNm = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOpExpnBobjNm = 'N'
        }
    
      /*----------------------------------------
        //      jobpBobjNm nod 관련
        ------------------------------------------*/
        chgCnt = 0;
        
        /*jobpBobjNm nod에 해당하는 인디케이터 설정*/      
        if( TSCommUtil.gfn_trim( this.ds_detail.offcNm) != TSCommUtil.gfn_trim( this.ds_detailBfc.offcNm) ) {
          this.ds_cdiIdct.idctJobpBobjNmVOjobpNm = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctJobpBobjNmVOjobpNm = 'N'
        }
        
        if( TSCommUtil.gfn_trim( this.ds_detail.dpnm) != TSCommUtil.gfn_trim( this.ds_detailBfc.dpnm) ) {
          this.ds_cdiIdct.idctJobpBobjNmVOdeptNm = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctJobpBobjNmVOdeptNm = 'N'
        }
        
        if( TSCommUtil.gfn_trim( this.ds_detail.insrJobC) != TSCommUtil.gfn_trim( this.ds_detailBfc.insrJobC) ) {
          this.ds_cdiIdct.idctJobpBobjNmVOinsrJobCd = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctJobpBobjNmVOinsrJobCd = 'N'
        }
        if( TSCommUtil.gfn_trim( this.ds_detail.incoDate) != TSCommUtil.gfn_trim( this.ds_detailBfc.incoDate) ) {
          this.ds_cdiIdct.idctJobpBobjNmVOecmpYmd = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctJobpBobjNmVOecmpYmd = 'N'
        }
        
        if( TSCommUtil.gfn_trim( this.ds_detail.insrJobTc) != TSCommUtil.gfn_trim( this.ds_detailBfc.insrJobTc) ) {
          this.ds_cdiIdct.idctJobpBobjNmVOamlJobScCd = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctJobpBobjNmVOamlJobScCd = 'N'
        }
        
        if((this.ds_detail.jobps) != (this.ds_detailBfc.jobps)) {
          this.ds_cdiIdct.idctJobpBobjNmVOjobpsNm = 'Y'
          chgCnt = chgCnt +1;
        } else {
          this.ds_cdiIdct.idctJobpBobjNmVOjobpsNm = 'N'
        }
        
        /*jobpBobjNm nod변경 여부 설정*/   
        if( chgCnt > 0 ) {
          this.ds_cdiChgNd.chgNdVOjobpBobjNm = 'Y'
        } else {
          this.ds_cdiChgNd.chgNdVOjobpBobjNm = 'N'
        }

        /*마케팅동의 여부 설정*/  
        
        if( TSCommUtil.gfn_trim( this.ds_detailBfc.mktnCnsntYn ) != "Y") {	//유포털 마케팅동의여부가 "Y"이면, 변경하지 않는다.

          if ( TSCommUtil.gfn_trim(this.ds_detail.mktnCnsntYn) == "N" ){
            this.ds_cdiChgNd.chgNdVOcustPprefNm1 = 'N'
          } else {
            this.ds_cdiChgNd.chgNdVOcustPprefNm1 = 'Y'
          }
          
        }else {
          this.ds_cdiChgNd.chgNdVOcustPprefNm1 = 'N'
        }
        
      },

      /***********************************************************************************
          비밀번호 클릭 
      **********************************************************************************/
      edt_tacPswd1_onsetfocus ()
      {
        this.ds_detail.tacPswd1 = ''
        this.lv_isClear_tacPswd1 = !this.lv_isClear_tacPswd1 ? true : false // 보안키패드 비밀번호 첫번째 초기화
      },


      edt_tacPswd2_onsetfocus ()
      {
        this.ds_detail.tacPswd2 = ''
        this.lv_isClear_tacPswd2 = !this.lv_isClear_tacPswd2 ? true : false // 보안키패드 비밀번호 두번째 초기화
      },


      /*=========================================================================
      /* 메소드 명 : fn_reportPrint : 구 fn_report_print(obj)
      /* 내용설명  : 브릿지계좌 거래신청서 등 출력
      /* Return 값 :                                                             
      =========================================================================*/
      // ※※※※※※ 보고서는 제외한다 ※※※※※※
      // fn_reportPrint ()
      // {
      //   if (!application.confirm("신탁거래신청서를 출력하시겠습니까?")) {
      //     return
      //   }

      //   /* 거래신청서 출력*/	
      //   this.ds_rdSearch = Object.assign({}, TSDataSet.ds_rdSearch )
      //   // this.ds_rdSearch.addRow();

      //   this.ds_rdSearch.setColumn( 0, "csId"	, this.gCsId );
      //   this.ds_rdSearch.setColumn( 0, "tacno"	, this.edt_tacno.value );
      //   this.ds_rdSearch.setColumn( 0, "bnkbSeq", "000" ); 
      //   this.ds_rdSearch.setColumn( 0, "corpYn"	, "N" ); 
        
      //   var reportMrdNm = "/cronix/fiduciary/contract/TFAC20070.mrd"; // 보고서 파일 경로
      //   var reportUrl    = "reportTFBM30000A.do"; // 보고서 호출 서비스url
      //   var reportParam  = this.ds_rdSearch;
        
      //   //보고서 출력 함수
      //   this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam);
      //   this.Div01.btn_popupTpup95050.set_enable(false);
      //   //this.fn_searchList();
      // },
      
      /******************************************************************************
       * Function명 : fn_getName
       * 설명       : 사원찾기 invoke 호출 함수
       ******************************************************************************/
      fn_getName (searchType, userId)
      {
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600210_S'
        let pParams = {
          usid: userId,
          userNm: '',
          brcd: '', 
        }
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.params = pParams
        let lv_searchType = searchType
        TSServiceUtil.invokeInDirect(this.eaiCommObj, function (response) {
          
          if ( TSCommUtil.gfn_length(response.data.tuuext) > 0 ) {
            console.log('response:>',response)
            console.log('lv_searchType:>',lv_searchType)
            if ( lv_searchType == 'mngr' ) { // 관리자
              this.ds_detail.mngrEmno = response.data.tuuext[0].usid 
              this.ds_detail.mngrEmnoNm = response.data.tuuext[0].userNm 
            } else if ( lv_searchType == 'invt' ) { // 권유자
              this.ds_detail.invtMnEmno = response.data.tuuext[0].usid 
              this.ds_detail.invtMnEmnoNm = response.data.tuuext[0].userNm 
            }
          }
        },function (error) {return})

      },

      /******************************************************************************
       * Function명 : fn_getName_callBack
       * 설명       : 사원찾기 invoke 콜백 함수
       ******************************************************************************/
      fn_getName_invt_callBack(res){
        const resData = Object.assign({}, res.data)
        if ( resData.error_msg != '809900' ) {
          this.ds_detail.invtMnEmno = ''
          this.ds_detail.invtMnEmnoNm = '코드값 없음'
          return 
        }
        if ( resData.sy000300_O_00VO != null && resData.sy000300_O_00VO.length > 0 ) {
          this.ds_detail.invtMnEmno = resData.sy000300_O_00VO[0].staf_eno
          this.ds_detail.invtMnEmnoNm = resData.sy000300_O_00VO[0].bfcer_eply_nm
        }
      },

      /******************************************************************************
       * Function명 : fn_getName_callBack
       * 설명       : 사원찾기 invoke 콜백 함수
       ******************************************************************************/
      fn_getName_mngr_callBack(res){
        const resData = Object.assign({}, res.data)
        if ( resData.error_msg != '809900' ) {
          this.ds_detail.mngrEmno = ''
          this.ds_detail.mngrEmnoNm = '코드값 없음'
          return 
        }
        if ( resData.sy000300_O_00VO != null && resData.sy000300_O_00VO.length > 0 ) {
          this.ds_detail.mngrEmno = resData.sy000300_O_00VO[0].staf_eno
          this.ds_detail.mngrEmnoNm = resData.sy000300_O_00VO[0].bfcer_eply_nm
        }
      },

      
      /******************************************************************************
       * Function명 : fn_createBnk999 
       * 설명       : fatca 팝업 콜백함수에서 호출(정상 체크 완료가 조건), 
       *              무통장 개설 dataSet 초기화및 무통장 개설 실행
      ******************************************************************************/      
      fn_createBnk999() {
        Object.assign(this.ds_search_1010, {tacno: this.ds_detail.tacno, Edt_acno: this.ds_detail.tacno}), // 종합계좌번호 search 값
        Object.assign(this.ds_list_1010, TSDataSet.defaultDsList1010), // 종합계좌번호 콤보화면(95150D 디비전에) search 후 담길 리턴 dataSet
        Object.assign(this.ds_detail_1010, TSDataSet.defaultDsDetail1010), // 계좌 생성시 보낼 dataSet
        this.$refs.search.fn_transaction(this.ds_detail.tacno)
      },

      /******************************************************************************
       * Function명 : acnoCallBackFunc ([1010] 페이지 함수)
       * 설명       : fatca 팝업 콜백함수에서 호출(정상 체크 완료가 조건), 
       *              무통장 개설 실행후 프로세스 종료, 95150D 컴포넌트에서 호출 
      ******************************************************************************/      
      async acnoCallBackFunc (pObj) {
        
        this.ds_detail_1010.tacno = pObj.gTacno
        this.ds_detail_1010.corpYn = pObj.gCorpYn
        this.ds_detail_1010.csId = pObj.gCsId
        this.ds_detail_1010.cnm = pObj.gCnm
        this.ds_detail_1010.gdC = "A1A001"
        this.ds_detail_1010.tassIssncAccYn = pObj.gTassIssncAccYn
        this.ds_detail_1010.passBookBnkbIssncYn = 'Y'
        
        // asis고객일 경우 무조건 [별도비밀번호설정] 으로 처리(통장비번이 들어와야함)
        this.ds_detail_1010.bnkbIssncYn = 'N'// this.DivMain.rdo_bnkbIssncYn.set_value("Y");
        if ( pObj.gTassIssncAccYn == "N" ) // 기존고객
        {
          this.ds_detail_1010.pswdCheckYn = 'N' // 별도 비밀번호 설정
        } else {
          this.ds_detail_1010.pswdCheckYn = 'Y' // 종합계좌 비밀번호 동일
        }
        
        // 개인일 때 [정기잔고증명 신청] 불가
        if ( pObj.gCorpYn == "N" )
        {
          // 개인
          this.ds_detail_1010.fxpdPrfYn = "N" // 정기잔고증명신청
          this.ds_detail_1010.fxpdPrfIssncDd = "" // 발급기준일
          this.ds_detail_1010.fxpdIsueNos = "" // 발급매수
          this.ds_detail_1010.fxpdIssncUsCntn = "" // 발급용도
          this.ds_detail_1010.fxpdEstmBaseMethC = "" // 평가기준
          this.ds_detail_1010.ftrmNhvBalYn = "" // 무잔고 포함여부
        }
        else
        {
          // 법인
          this.ds_detail_1010.ftrmNhvBalYn = 'Y' // 무잔고 포함여부
          this.ds_detail_1010.fxpdPrfYn = 'Y' // 정기잔고증명신청
        }
        await this.fn_fatcaSelect(); //fatca 징구여부 조회

        if ( this.rdo_sbmtYn == 'chk_sbmtYn' ) { // 징구됨, FATCA 완료했을 경우만 계좌생성
          // this.ds_detail.setColumn(0, "passBookBnkbIssncYn"	, this.DivMain.hid_edt_passBookBnkbIssncYn.value);
          // this.ds_detail.setColumn(0, "gdC"					, this.DivMain.hid_edt_gdC.value);
          // this.ds_detail.setColumn(0, "bnkbPswd1"				, this.DivMain.edt_bnkbPswd1.value);
          // this.ds_detail.setColumn(0, "bnkbPswd2"				, this.DivMain.edt_bnkbPswd2.value);
          
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.auth = 'S'
          this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10600122_S' // insertTFBM30000
          this.eaiCommObj.params = this.ds_detail_1010
          this.eaiCommObj.params.TFABNB = [{tacno: this.ds_detail_1010.tacno}]
      
          console.log('--무통장 시작--' )
          const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
          const resData = Object.assign({}, res.data)
          console.log('----C391_F10600122_S resData----')
          console.log(resData)

          // 응답코드가 정상이 아닌경우 
          if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
            // let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
            // this.fn_AlertPopup(0,{content: msg})  
            console.log('--무통장 실패 : ' + resData.errorMsg )
            TSCommUtil.gfn_validate(this, resData.errorMsg)
          } else {
            console.log('--무통장 성공--')
            this.stacno = resData.brigCpstAcno
            console.log(resData)
          }
        } else {
          console.log('--징구되지 않음 무통장 생성 프로세스 시도 안함--')
        }
      },

      
      /**
       * 가상계좌등록할 시에는 
       * 1. 수익증권쪽에 가상계좌등록 proc_gb=A
       * 2. u-frontier 만기보험금 지급계좌목록에 등록 proc_gb=M
       * 3. 출력
       * -중간에 오류나면 출력으로 분기 
       */
      /***************************************************************************************
       * 가상계좌번호 생성  호출 : 구 linkAcctInsert()
      *****************************************************************************************/
      async linkAcctInsert () {

      },

      /***************************************************************************************
       * 통장출력 호출
      *****************************************************************************************/
      async pbkPrint () {
        if (  this.ds_list_1010.bnkbSeq != "999" ) {
          this.ds_linkAcctDetail = Object.assign({}, TSDataSet.ds_linkAcctDetail)
          var tmp = this.ds_list_1010.bnkbIssncYn
        
          this.ds_linkAcctDetail.msUseYn  = "Y" // MS사용여부
          this.ds_linkAcctDetail.vtAcnoYn = "Y" // 가상계좌(존재)유무
          this.ds_linkAcctDetail.cnm      = this.ds_list_1010.cnm
          this.ds_linkAcctDetail.tacno    = this.ds_list_1010.tacno
          this.ds_linkAcctDetail.bnkbSeq  = this.ds_list_1010.bnkbSeq
          this.ds_linkAcctDetail.vtAcno   = this.ds_list_1010.trstVtAcno
          this.ds_linkAcctDetail.passBookBnkbIssncYn = this.ds_list_1010.sbnkbIssncYn // 통장발급여부(가상계좌 처리 시 필요)
          
          // sSvcID = "selectTFBM30000AList" ;
          // var sURL          = "svc::"+sSvcID+".do"; // svc = http://localhost:8080/
          // var sInDatasets   = "tfabnb=ds_linkAcctDetail:";  // transaction을 요청할 때 입력값으로 보낼 Dataset 의 ID 들 입니다.
          // var sOutDatasets  = "ds_pbpr=passbookList" ;
          // var sArgument     = "";
          // var sCallbackFunc = "fn_callBack4"; //fn_call_back4
          // /*transaction을 호출하는 공통함수 */
          // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600126_S' // selectTFBM30000AList
          this.eaiCommObj.params = this.ds_linkAcctDetail

          const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
          const resData = Object.assign({}, res.data)
          if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
            let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
            this.fn_AlertPopup(0,{content: msg})  
            return 
          }
          

          // 통장출력 로직 (fn_callBack4) 삭제 
          // 구고객만 계약서 출력
          // if ( pObj.gTassIssncAccYn == "N" ) { // 신규라 해당 사항 없음
          //   this.fn_reportPrint();
          // } 
          
        } 

        var cnm = this.ds_list_1010.cnm
        var tacno = this.ds_list_1010.tacno
        var bnkbSeq = this.ds_list_1010.bnkbSeq 
        var totalAcno = tacno + " - " + bnkbSeq;
        var text = "정상등록 되었습니다. : " + cnm + " 님의 신규통장번호는 " + totalAcno + " 입니다.";
        alert(text)
        console.log('pbkPrint : ' + text)
      },

      /******************************************************************************
       * Function명 : fn_endProc
       * 설명       : 확인, 수정 종료 팝업과 전자서식, 버튼 활성화 완료 후 프로세스 종료
       ******************************************************************************/
      fn_endProc () {
        let popMsgArr = []

        // 프로세스 종료후 버튼 정리
        if ( this.transType == 'insert' ) {
          // 등록완료후 활성화 버튼 (조회, 확인, 취소, 초기화)
          this.enabledList.btn_confirm = false      // 확인 활성화
          this.enabledList.btn_cancel = true       // 취소 활성화
          this.enabledList.btn_searchList = true   // 조회 활성화
          this.enabledList.btn_init = true         // 초기화 활성화
          this.enabledList.btn_update = false      // 수정 비활성화
        } else {
          // 버튼 정상처리.
          this.enabledList.btn_confirm = false      // 확인 활성화
          this.enabledList.btn_cancel = true       // 취소 활성화
          this.enabledList.btn_searchList = false  // 조회 비활성화
          this.enabledList.btn_init = false        // 초기화 비활성화
          this.enabledList.btn_update = false      // 수정 비활성화
        }

        // 확인, 수정 종료 팝업
        this.fn_checkProcess()          // 종료팝업 프로세스 체크 및 전자서식 실행

      }, 
      
      /******************************************************************************
       * Function명 : fn_rmAllErrCss
       * 설명       : 해당 컴포넌트 error css 삭제
       ******************************************************************************/
      fn_rmAllErrCss () {
        for ( let idx in this.$refs ) {
          if ( this.$refs[idx] != undefined ) {
            this.$refs[idx].$el.classList.remove("error")
          }
        }
      },

      /*********************************************************
      * Function명: fn_OpenMSPTS103P
      * 설명: 사원조회 팝업호출
      *********************************************************/
      fn_OpenMSPTS103P (type) {
        //this.$refs.popup110.fn_Init();
        let lv_vm = this
        this.pPopup103Type = type
        console.log(this.$refs.popup103)
        let properties = {} // 고객등록은 보낼 파라미터값이 없음
        this.popup103 = this.$refs.popup103.fn_Open(properties)
      },

      /*********************************************************
      * Function명: fn_Popup103Back
      * 설명: 고객조회 팝업호출 콜백
      *********************************************************/
      fn_Popup103Back(rtnData){
        console.log(rtnData)
        //권유자
        if(this.pPopup103Type === '00'){
          this.ds_detail.invtMnEmno = rtnData.usid
          this.ds_detail.invtMnEmnoNm = rtnData.userNm
          // this.emnoPopupYn = "Y"
          // if ( this.fn_CheckGdC() ) {
          //   this.fn_TransactionEmno("invtMnEmno")
          // }
        //관리자
        }else if(this.pPopup103Type === '02'){

          this.ds_detail.mngrEmno = rtnData.usid
          this.ds_detail.mngrEmnoNm = rtnData.userNm

          if ( TSCommUtil.gfn_isNull(this.ds_detail.invtMnEmno) ) { // 권유자가 지정 안됐을시 권유자 자동 셋팅
            this.ds_detail.invtMnEmno = rtnData.usid
            this.ds_detail.invtMnEmnoNm = rtnData.userNm
          }

          // this.emnoPopupYn = "Y"
          // if ( this.fn_CheckGdC() ) {
          //   this.fn_TransactionEmno("mngrEmno")
          // }
          
          // if ( this.gfn_length( this.edt_invtMnEmno.value ) < 5 ){
          //   this.invtMnEmno = rtnData.usid
          //   this.invtMnEmnoNm = rtnData.userNm
          // }
        //대리인 , 사용안함
        }else if(this.pPopup103Type === '03'){
          this.ds_detail.agntCsId = rtnData.usid
          this.ds_detail.agntCsIdNm = rtnData.userNm

          // this.emnoPopupYn = "Y"
          // if ( this.fn_CheckGdC() ) {
          //   this.fn_TransactionEmno("saleEmno")
          // }
        }
      },

      /******************************************************************************
       * Function명 : fn_checkProcess
       * 설명       : 종료팝업 프로세스 체크 및 전자서식 실행
       ******************************************************************************/
      fn_checkProcess() {

        let lv_Vm = this;
        let tmpContentTitle = ''        
        
        let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)
        let t_type = 0
        let popMsgArr = []
        
        // 메인 전자서식 폼 리스트 추가
        this.fn_addMainForm()          

        // 확인, 수정 종료 팝업 메시지 세팅
        if ( this.transType == 'insert' ) { // 등록후 fatcapopup 호출후 이곳에서 프로세스 종료한다.
          if ( this.lv_isProcess ) {
            popMsgArr.push('고객등록이 완료되었습니다.')
            popMsgArr.push('신규신청 화면으로 이동합니다.')
          } else {
            popMsgArr.push('고객등록이 완료되었습니다.')
          }
        } else {
          if ( this.lv_isProcess ) {
            popMsgArr.push('수정이 완료되었습니다.')
            popMsgArr.push('신규신청 화면으로 이동합니다.')
          } else {
            popMsgArr.push('수정이 완료되었습니다.')
          }
        }

        if ( this.lv_isProcess ) { 
          if ( !isLast ) {
            t_type = 7 // 프로세스 진행 중 마지막 화면이 아닐 경우
          } else {
            // test_btn_enable = true  // 다음 버튼 활성화 되어야함
            t_type = 9 // 프로세스 진행 중 마지막 화면일 경우
          }
        } else { // 프로세스 진행 중이 아닐 경우
          t_type = 9 
        }
        
        let lv_tacno = TSCommUtil.gfn_isNull(this.ds_detail.tacno) ? this.stacno : this.ds_detail.tacno

        let t_popupObj = {
          confirm: true,
          confirmFunc: lv_Vm.fn_ReportAndMove,
          closeFunc: this.fn_closeReport, //ASR250301055 / 고객등록화면 알림톡발송기능 정상화 
          content: popMsgArr,
          contentTitle: tmpContentTitle,
          confirmData: {
            tacno: lv_tacno,
            bnkbSeq: '',
            accSeq: '',
            csId: this.ds_detail.csId, // 정보동의서 수령방법 (메일, 전화번호 정보항목) 설정관련 추가
            cvrTypC: this.transType == 'insert' ? 'NEW' : 'CHG' , // 신규: NEW , 수정: CHG
          },
        }
        lv_Vm.fn_AlertPopup(t_type, t_popupObj);
      },

      report_test () {

        let lv_Vm = this;
        let tmpContentTitle = ''        
        
        let isLast = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)
        let t_type = 0
        let popMsgArr = []
        
        // 메인 전자서식 폼 리스트 추가
        this.transType = 'insert'          
        this.fn_addMainForm()
        t_type = 9 // 프로세스 진행중이 아닐경우
        this.getStore('tsStore').dispatch('IS_LAST_START')
        let t_popupObj = {
          confirm: true,
          confirmFunc: async function (){
            TSInfoUtil.commReportInfo(lv_Vm, lv_Vm.formList) // 전자서식 호출
          },
          content: ['전자서식 테스트'],
          
        }
        lv_Vm.fn_AlertPopup(t_type, t_popupObj)
      },

      /******************************************************************************
       * Function명 : fn_ReportAndMove
       * 설명       : 마지막 완료 팝업 호출 후 콜백 함수. 전자서식 호출 및 페이지 이동
       ******************************************************************************/
      async fn_ReportAndMove () {
        if ( this.lv_isProcess ) { // 프로세스일 경우 페이지 이동
          TSInfoUtil.commReportInfo(this, this.formList, this.fn_GoNextPage) // 전자서식 호출
        } 
        else {
          TSInfoUtil.commReportInfo(this, this.formList) // 전자서식 호출
        }
      },
      async fn_closeReport () {
        TSInfoUtil.commReportInfo(this, this.formList) // 전자서식 호출 ASR250301055 / 고객등록화면 알림톡발송기능 정상화 
      },

      /******************************************************************************
       * Function명 : fn_addMainForm
       * 설명       : 메인 전자서식 폼 리스트 추가
       * ◆신탁_전자서식(업무별)_최종(230630) 파일의 화면전자서식 탭 참고
       ******************************************************************************/
      fn_addMainForm () {
        console.log('fn_addMainForm....')


        // this.ds_searchParam.csId         = this.ds_acno.csId

        // // 전자서명 동의서   
        // this.ds_searchReport.reportMrdNm = "TS000001"
        // this.ds_searchReport.reportUrl   = "selectTFCP10000"
        // this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
        // this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))

        
        // 공통필수, 전자서명 동의서 
        let TS000001 = {formId: 'TS000001'}
        TS000001.params = {
          reportParam: JSON.stringify( { csId: this.ds_detail.csId } ),
          reportUrl: 'selectTFCP10000',
        }   
        this.formList.push(TS000001)

        if ( this.transType == 'insert' ) { 
          let TS000004 = {formId: 'TS000004'} // TFAC20070.mrd | 신탁거래 신청서
          let TS000005 = {formId: 'TS000005'} // mrd 없음 | TS000005_개인신용정보의 제공조회 및 이용동의서
          // if ( this.ds_detail.mktnWcnstTyp == '2' ){
          //   let TS000005 = {formId: 'TS000005'} // mrd 없음 | TS000005_개인신용정보의 제공조회 및 이용동의서 (요약)
          // this.formList.push(TS000005)
          // } else if (this.ds_detail.mktnWcnstTyp == '1'){
          //   let TS000120 = {formId: 'TS000120'} // mrd 없음 | TS000120_개인신용정보의 제공조회 및 이용동의서 (상세)
          // this.formList.push(TS000120)
          // } // ASR250300203 / 신탁 마케팅 동의서 개정 사항 반영

          // 메인 거래 신청서
          let searchData = {
            csId    : this.ds_detail.csId,
            tacno   : this.ds_detail.tacno,
            bnkbSeq : '000',
            corpYn  : 'N',
          }
          TS000004.params = { 
            reportParam: JSON.stringify(searchData),
            reportUrl: 'reportTFBM30000A',
          }
          TS000004.mappingData = {
            invtMnEmno    : this.ds_detail.invtMnEmno,    // 권유자 사번
            invtMnEmNm    : this.ds_detail.invtMnEmnoNm,  // 권유자 성명
            mngrEmno      : this.ds_detail.mngrEmno,      // 관리자(판매직원) 사번
            mngrEmnoNm    : this.ds_detail.mngrEmnoNm,    // 관리자(판매직원) 성명
            fnlDsgnEmno   : this.ds_detail.fnlDsgnEmno,   // FC 사번
            fnlDsgnEmnoNm : this.ds_detail.fnlDsgnEmnoNm, // FC 성명
          }
          this.formList.push(TS000004)

          // 신탁거래를 위한 대리인위임장
          if ( this.ds_deputyObj.agntCsId != '' ) { // 대리인이 있을경우
            let TS000041 = {formId: 'TS000041'} // 신탁거래를 위한 대리인위임장, 동의서_1  
            TS000041.mappingData = {
              cnm: this.ds_detail.cnm, // 본인에 관한 사항_고객명
              rcno: this.rcnoOne,      // 본인에 관한 사항_주민번호앞자리
              hpTno: this.ds_detail.mvphBusiMnNo.toString() + '-' + this.ds_detail.mvphPofn.toString() + '-' + this.ds_detail.mvphSeq.toString(),
              agntNm: this.ds_deputyObj.agntNm,
              agntRcno: this.ds_deputyObj.rcno.substr(0,6),
              prhsRel: this.ds_deputyObj.prhsRel,
              agntHpTno: this.ds_deputyObj.mvphBusiMnNo.toString() + '-' + this.ds_deputyObj.mvphPofn.toString() + '-' + this.ds_deputyObj.mvphSeq.toString(),
              agntAddr: this.ds_deputyObj.homeRmnAddr,
            }
            this.formList.push(TS000041)
          } 

        } else {
          let TS000054 = {formId: 'TS000054'} 
          TS000054.mappingData = {
            cnm   : this.ds_detail.cnm, 
            tacno : this.stacno,   
          }
          this.formList.push(TS000054)
        }
        
      },

      /******************************************************************************
       * Function명 : fn_addMainForm
       * 설명       : Fatca 전자서식 폼 리스트 추가
       ******************************************************************************/
      fn_addFatcaForm () {
        console.log('fn_addFatcaForm....')
        this.rstFtacaPopObj.fatcaGb
        if ( this.rstFtacaPopObj.fatcaGb == 'P0' ) {
          let TS000002 = {formId: 'TS000002'} // TPUP95050.mrd
          TS000002.params = {
            reportParam: JSON.stringify(this.rstFtacaPopObj.ds_search),
            reportUrl: 'reportTPUP95050',
          }
          this.formList.push(TS000002)
        } else {
          let TS000003 = {formId: 'TS000003'} // TPUP95051.mrd
          TS000003.params = {
            reportParam: JSON.stringify(this.rstFtacaPopObj.ds_search),
            reportUrl: 'reportTPUP95050',
          }
          this.formList.push(TS000003)
        }
      },

      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회 
       ******************************************************************************/
      fn_SearchValid (event, flag) {
        console.log('fn_SearchValid.....')

        let t_rcno
        if ( flag == 'P' ) {
          t_rcno = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,13)
          let t_rcnoOne = t_rcno.substr(0,6)
          let t_rcnoTwo = t_rcno.substr(6,7)

          if ( this.isMobile && this.isMtrans ) {
            this.rcnoOne = t_rcnoOne
            this.$refs['edt_rcnoTwo'].focus()
          } else {
            this.rcnoOne = t_rcnoOne
            this.rcnoTwo = t_rcnoTwo
          }

          // if( TSCommUtil.gfn_length(t_rcno) == 13 ) {
          //   if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
          //     return 
          //   }
          //   // this.fn_searchList()
          // }

        } else if ( flag == 'edt_rcnoOne' ) {
          t_rcno = this.rcnoOne
          if( TSCommUtil.gfn_length(t_rcno) == 6 ) {
            if ( ! this.isMobile )
              this.$refs['edt_rcnoTwo'].focus()
          }
        } else if ( flag == 'edt_rcnoTwo' ) { 
          t_rcno = this.valid_rcnoTwo
          // if( TSCommUtil.gfn_length(t_rcno) == 7 ) {
          //   if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
          //     return 
          //   }
          //   // this.fn_searchList()
          // }
        }
      },

      /******************************************************************************
       * Function명 : fn_GoNextPage
       * 설명       : 고객정보등록 페이지로 이동
       ******************************************************************************/
      async fn_GoNextPage() {
        await this.fn_delayView()

        this.$router.push({
          name: 'MSPTS200M', 
          params: {
            tacno: this.stacno, 
          }
        })
      },
      
      /******************************************************************************
       * Function명 : fn_delAddr
       * 설명       : 삭제버튼에 따른 주소 초기화 모음
       ******************************************************************************/
      fn_delAddr ( type ) {
        // 자택(HOME), 직장(JOB), 신탁기타주소(ETC)
        if( type == 'HOME' ) {
          this.ds_detail.hmadPsno           = ''
          this.ds_detail.hmadPsno1          = ''
          this.ds_detail.hmadPsno2          = ''
          this.ds_detail.homePsnoAddr       = ''
          this.ds_detail.homeAddrPsnoSeq    = ''
          this.ds_detail.homeRmnAddr        = ''
          this.ds_detail.hmadStdztYn        = ''
          this.ds_detail.homeAddrBldnNo     = ''
          this.ds_detail.homeStdztDtlad     = ''
          this.ds_detail.homeAdtnAddr       = ''

          this.ds_detailBfc.hmadPsno        = ''
          this.ds_detailBfc.hmadPsno1       = ''
          this.ds_detailBfc.hmadPsno2       = ''
          this.ds_detailBfc.homePsnoAddr    = ''
          this.ds_detailBfc.homeAddrPsnoSeq = ''
          this.ds_detailBfc.homeRmnAddr     = ''
          this.ds_detailBfc.hmadStdztYn     = ''
          this.ds_detailBfc.homeAddrBldnNo  = ''
          this.ds_detailBfc.homeStdztDtlad  = ''
          this.ds_detailBfc.homeAdtnAddr    = ''
          
        } else if( type == 'JOB' ) {
          this.ds_detail.offcAddrPsno       = ''
          this.ds_detail.offcAddrPsno01     = ''
          this.ds_detail.offcAddrPsno02     = ''
          this.ds_detail.offcPsnoAddr       = ''
          this.ds_detail.offcAddrPsnoSeq    = ''
          this.ds_detail.offcRmnAddr        = ''
          this.ds_detail.offcAddrStdztYn    = ''
          this.ds_detail.offcAddrBldnNo     = ''
          this.ds_detail.offcStdztDtlad     = ''
          this.ds_detail.offcAdtnAddr       = ''

          this.ds_detailBfc.offcAddrPsno    = ''
          this.ds_detailBfc.offcAddrPsno01  = ''
          this.ds_detailBfc.offcAddrPsno02  = ''
          this.ds_detailBfc.offcPsnoAddr    = ''
          this.ds_detailBfc.offcAddrPsnoSeq = ''
          this.ds_detailBfc.offcRmnAddr     = ''
          this.ds_detailBfc.offcAddrStdztYn = ''
          this.ds_detailBfc.offcAddrBldnNo  = ''
          this.ds_detailBfc.offcStdztDtlad  = ''
          this.ds_detailBfc.offcAdtnAddr    = ''
          
        } else if( type == 'ETC' ) { // 신탁 기타주소
          this.g_edt_etcAddrTotal = ''
          this.ds_detail.etcAddrPsno1 = ''
          this.ds_detail.etcAddrPsno2 = ''
          this.ds_detail.etcPsnoAddr = ''
          this.ds_detail.etcRmnAddr = ''
          this.ds_detail.etcAddrBldnNo = ''
        }
      },

      /**********************************************************************************
      * 종합계좌번호조회 : fn_searchTacno
      **********************************************************************************/
      async fn_searchTacno ()
      {	
        let lv_tacno = ''   
        this.g_eaiId = "C392_F10600133_S" 
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'C392_F10600133_S' //selectTFBM32000List
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600133_S'
        this.eaiCommObj.params = this.ds_search

        const res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return ''
        }

        if(!TSCommUtil.gfn_isNull(resData)){
          if(resData.tfaccb.length > 0){
            lv_tacno = resData.tfaccb[0].brigCpstAcno 
            this.stacno = resData.tfaccb[0].brigCpstAcno 
          }
        }
        return lv_tacno
      },   
      
      /**********************************************************************************
       * fn_searchTacno 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      fn_callBackTacno ( res )
      {
        let tacno = ''
        const t_eaiId = res.commHeaderVO.eaiId
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          let msg = TSCommUtil.gfn_trim(resData.errorMsg) == '' ? resData.errorCode : resData.errorMsg  
          this.fn_AlertPopup(0,{content: msg})  
          return ''
        }

        if(!TSCommUtil.gfn_isNull(resData)){
          if(res.data.tfaccb.length > 0){
            tacno = resData.tfaccb[0].brigCpstAcno 
            this.stacno = resData.tfaccb[0].brigCpstAcno 
          }
        }
        return tacno
      },

      /***************************************************************************************
       * Function명 : fn_pass_check
       * 설명       : 비밀번호값 비밀번호확인값 체크
      *****************************************************************************************/
      async fn_pass_check() {
        let rtn = await this.$commonUtil.comparePassword(this, this.ds_detail.tacPswd1, this.ds_detail.tacPswd2)
        if(rtn !== 'Y') {
          return false
        }
        return true
      },

      async fn_delayView(){
        this.lv_wait = true;
        const wait = (timeToDelay)=> new Promise((resolve)=> setTimeout(resolve,timeToDelay));
        await wait(300);
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      // 별도신탁주소
      g_edt_etcAddrTotal : {
        handler(after, before) {
          if ( ! TSCommUtil.gfn_isNull(after)  )
            this.$refs['edt_etcAddrTotal'].$el.classList.remove('error')
        },
        immediate: true,
      },
     
      // 주연락처 
      'ds_detail.mnCntcPlcTc' : {
        handler(after, before) {
          console.log('주연락처')
          this.rdo_mnCntcPlcTc_onitemclick()
        },
        immediate: true,
      },
      
      // 실명번호 앞자리
      rcnoOne: function() {
        this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
      },

      // 실명번호 뒷자리
      rcnoTwo: function() {
        this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
      },

      // 신규고객 여부
      gNewMemberYn : {
        handler: function(val, oldVal) { 
          this.ds_detail.newMemberYn = val // Y or N 
        },
      },

      // 정정구분
      cmb_modifyGb: {
        handler: function(val, oldVal) {
          if ( val !=  '' ) // ''은 사용자 선택이 아닌 내부적 초기화이기 때문에 실행하지 않음.
            this.Div01_cmb_modifyGb_onitemchanged()
            this.rdo_mnCntcPlcTc_onitemclick()
        },
      },

      /**********************************************************************************
       * 신규 종합계좌 미개설  : 종합계좌개설 및 수익증권계좌 미개설처리(입력항목 비활성화)
       **********************************************************************************/
      chk_NoTacct: function(curr, old){
        if ( curr == true ){
          this.enabledList.edt_tacPswd1 = false
          this.enabledList.edt_tacPswd2 = false
          this.enabledList.edt_tacPswd2 = false
          this.enabledList.edt_bncrVtAcno = false
          this.enabledList.cmb_bankCode = false
          this.enabledList.msk_trstVtAcno = false
          this.enabledList.btn_popupTPUP95110_1 = false
          this.enabledList.btn_popupTPUP95110_2 = false
          this.enabledList.btn_popupTPUP95290 = false
          this.enabledList.edt_invtMnEmno = false
          this.enabledList.edt_mngrEmno = false
          this.enabledList.edt_fnlDsgnEmno = false
        }
        else
        {
          this.enabledList.edt_tacPswd1 = true
          this.enabledList.edt_tacPswd2 = true
          this.enabledList.edt_tacPswd2 = true
          this.enabledList.edt_bncrVtAcno = true
          this.enabledList.cmb_bankCode = true
          this.enabledList.msk_trstVtAcno = true
          this.enabledList.btn_popupTPUP95110_1 = true
          this.enabledList.btn_popupTPUP95110_2 = true
          this.enabledList.btn_popupTPUP95290 = true
          this.enabledList.edt_invtMnEmno = true
          this.enabledList.edt_mngrEmno = true
          this.enabledList.edt_fnlDsgnEmno = true
        }
      },

      /**********************************************************************************
       * 프로세스 체크
       **********************************************************************************/
      'lv_isProcess': {
        handler(after, before) {
          console.log('lv_isProcess > ', after)
          this.fn_init()
        },
        deep: true,
        immediate: true,
      },

      'isSearchScrnCheck': {
        handler(after, before) {
          console.log('isSearchScrnCheck > ', after)
          if (after) {
            this.pHeaderObj.title = '개인고객조회'
          } else {
            this.pHeaderObj.title = '개인고객등록'
          }
          this.fn_init()          
        },
        deep: true,
        immediate: true,
      },

      // 자택주소 삭제버튼 이벤트
      chk_homeAddr: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.enabledList.btn_popupTpup95100_1 = false
            this.fn_delAddr('HOME')// 주소 삭제
          } else {
            this.enabledList.btn_popupTpup95100_1 = true
          }
        },
      },

      // 자택전화번호 삭제버튼 이벤트
      chk_homeTel: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.enabledList.edt_homeTelArno = false
            this.enabledList.edt_homeTelPofn = false
            this.enabledList.edt_homeTelSeq = false
            this.ds_detail.homeTelArno = ''
            this.ds_detail.homeTelPofn = ''
            this.ds_detail.homeTelSeq = ''
          } else {
            this.enabledList.edt_homeTelArno = true
            this.enabledList.edt_homeTelPofn = true
            this.enabledList.edt_homeTelSeq = true
          }
        },
      },

      // FAX 번호 삭제버튼 이벤트
      chk_homeFax: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.ds_detail.offcFaxArno = ''
            this.ds_detail.offcFaxPofn = ''
            this.ds_detail.offcFaxSeq = ''
            this.enabledList.edt_offcFax = false
          } else {
            this.enabledList.edt_offcFax = true
          }
        },
      },

      // 휴대폰 삭제버튼 이벤트
      chk_homeCel: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.ds_detail.mvphBusiMnNo = '010'
            this.ds_detail.edt_mvphPofn = ''
            this.ds_detail.edt_mvphSeq = ''
            this.enabledList.cmb_mvphBusiMnNo = false
            this.enabledList.edt_mvphPofn = false
            this.enabledList.edt_mvphSeq = false
          } else {
            this.enabledList.cmb_mvphBusiMnNo = true
            this.enabledList.edt_mvphPofn = true
            this.enabledList.edt_mvphSeq = true
          }
        },
      },

      // 직장주소 삭제버튼 이벤트
      chk_offcAddr: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.enabledList.btn_popupTpup95100_1 = false
            this.fn_delAddr('JOB')// 주소 삭제
          } else {
            this.enabledList.btn_popupTpup95100_1 = true
          }
        },
      },

      // 직장전화번호 삭제버튼 이벤트
      chk_offcTel: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.enabledList.edt_offcTelArno = false
            this.enabledList.edt_offcTelPofn = false
            this.enabledList.edt_offcTelSeq = false
            this.ds_detail.offcTelArno = ''
            this.ds_detail.offcTelPofn = ''
            this.ds_detail.offcTelSeq = ''
          } else {
            this.enabledList.edt_offcTelArno = true
            this.enabledList.edt_offcTelPofn = true
            this.enabledList.edt_offcTelSeq = true
          }
        },
      },

      // 이메일 삭제버튼 이벤트
      chk_homeEmail: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.enabledList.edt_emailAddr = false
            this.enabledList.edt_domnNm = false
            this.ds_detail.emailAddr = ''
            this.ds_detail.domnNm = ''
          } else {
            this.enabledList.edt_emailAddr = true
            this.enabledList.edt_domnNm = true
          }
        },
      },

      // 신탁별도주소 삭제버튼 이벤트
      chk_etcAddr: {
        handler(after, before) {
          if ( after == true ) { // 삭제 체크일 경우
            this.enabledList.btn_popupTfcp10001 = false
            this.fn_delAddr('ETC')// 주소 삭제
          } else {
            this.enabledList.btn_popupTfcp10001 = true
          }
        },
      },

    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
